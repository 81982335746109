import Notification from "../utils/notification";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["notice"]

  connect() {
    this.noticeTarget.hidden = true
    const message = this.noticeTarget.textContent
    if (this.noticeTarget.classList[0] == 'notice' || this.noticeTarget.classList[0] == 'success') {
      Notification.success(message)
    } else if (this.noticeTarget.classList[0] == "info") {
      Notification.open({
        type: 'info',
        message: message
      });
    } else {
      Notification.error(message)
    }
  }
}