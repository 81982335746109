/*!
 * Color mode toggler for Bootstrap's docs (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under the Creative Commons Attribution 3.0 Unported License.
 */

(() => {
  'use strict'

  const storedTheme = localStorage.getItem('theme')

  const getPreferredTheme = () => {
    if (storedTheme) {
      return storedTheme
    }

    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  }

  const setTheme = function (theme) {
    if (theme === 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.documentElement.setAttribute('data-bs-theme', 'dark')
    } else {
      document.documentElement.setAttribute('data-bs-theme', theme)
    }
  }

  const setShinyMapTheme = function (theme) {
    if (theme === 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.querySelector("#map").className = "map-theme-dark"
      setShinyMapStyle("#3D3D3D")
    } else if (theme === 'dark') {
      document.querySelector("#map").className = "map-theme-dark"
      setShinyMapStyle("#3D3D3D")
    } else {
      document.querySelector("#map").className = "map-theme-light"
      setShinyMapStyle("#F5F5F5")
    }
  }

  const setShinyMapStyle = function (color) {
    [window.WorldMapLayer, window.JapanSimpleMapLayer,
    window.JapanDetailedMapLayer, window.JapanEEWMapLayer, window.JapanPrefMapLayer].forEach(layer => {
      const style = new window.Style({
        fill: new Fill({
          color: color,
        }),
        stroke: new Stroke({
          color: "#aaa",
          width: 1,
        }),
      });
      layer.setStyle(style)
    })
  }


  setTheme(getPreferredTheme())
  setShinyMapTheme(getPreferredTheme())

  const showActiveTheme = (theme, focus = false) => {
    const themeSwitcher = document.querySelector('#bd-theme')

    if (!themeSwitcher) {
      return
    }

    const themeSwitcherText = document.querySelector('#bd-theme-text')
    const ThemeIcon = document.querySelector('#active-theme > i')
    const btnToActive = document.querySelector(`[data-bs-theme-value="${theme}"]`)

    document.querySelectorAll('[data-bs-theme-value]').forEach(element => {
      element.classList.remove('active')
      element.setAttribute('aria-pressed', 'false')
    })

    btnToActive.classList.add('active')
    btnToActive.setAttribute('aria-pressed', 'true')
    ThemeIcon.className = ""
    ThemeIcon.classList.add("bi")
    switch (theme) {
      case "light":
        ThemeIcon.classList.add("bi-sun-fill")
        break;
      case "dark":
        ThemeIcon.classList.add("bi-moon-fill")
        break;
      default:
        ThemeIcon.classList.add("bi-circle-half")
        break;
    }
    const themeSwitcherLabel = `${themeSwitcherText.textContent} (${btnToActive.dataset.bsThemeValue})`
    themeSwitcher.setAttribute('aria-label', themeSwitcherLabel)

    if (focus) {
      themeSwitcher.focus()
    }
  }

  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
    if (storedTheme !== 'light' || storedTheme !== 'dark') {
      setTheme(getPreferredTheme())
    }
  })

  window.addEventListener('turbo:load', () => {
    showActiveTheme(getPreferredTheme())

    document.querySelectorAll('[data-bs-theme-value]')
      .forEach(toggle => {
        toggle.addEventListener('click', () => {
          const theme = toggle.getAttribute('data-bs-theme-value')
          window.bsTheme = theme
          localStorage.setItem('theme', theme)
          setTheme(theme)
          setShinyMapTheme(theme)
          showActiveTheme(theme, true)
        })
      })
  })
})()
