import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import 'dayjs/locale/ja'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault("Asia/Tokyo")

export function defaultTzTime(dateTime, format) {
  return dayjs(dateTime).tz().format(format)
}

export function deviceTzTime(dateTime, format) {
  return dayjs(dateTime).tz(dayjs.tz.guess()).format(format)

}
export {dayjs}