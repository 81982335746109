import { Controller } from "@hotwired/stimulus"
import {defaultTzTime, deviceTzTime} from "../utils/dayjs"

export default class extends Controller {
  connect() {
    const datetime = this.element.getAttribute("datetime")
    const format = this.element.getAttribute("data-format")
    if (localStorage.getItem('deviceTimezone') === "true") {
      this.element.innerHTML = deviceTzTime(datetime, format)
    } else {
      this.element.innerHTML = defaultTzTime(datetime, format)
    }
  }
}
