export const cityData = {
  "札幌中央区": {
    "code": "110100",
    "coordinates": [
      141.302077451493,
      43.0384041572337
    ]
  },
  "札幌北区": {
    "code": "110200",
    "coordinates": [
      141.354056247971,
      43.1347503224117
    ]
  },
  "札幌東区": {
    "code": "110300",
    "coordinates": [
      141.40131974021,
      43.1087430953872
    ]
  },
  "札幌白石区": {
    "code": "110400",
    "coordinates": [
      141.425172890005,
      43.0603253195276
    ]
  },
  "札幌豊平区": {
    "code": "110500",
    "coordinates": [
      141.388947814027,
      42.9998131682113
    ]
  },
  "札幌南区": {
    "code": "110600",
    "coordinates": [
      141.181575479985,
      42.9471219985831
    ]
  },
  "札幌西区": {
    "code": "110700",
    "coordinates": [
      141.243072357961,
      43.0639274394172
    ]
  },
  "札幌厚別区": {
    "code": "110800",
    "coordinates": [
      141.473777253169,
      43.043337954344
    ]
  },
  "札幌手稲区": {
    "code": "110900",
    "coordinates": [
      141.224470978469,
      43.115809519746
    ]
  },
  "札幌清田区": {
    "code": "111000",
    "coordinates": [
      141.431388494746,
      42.9620412063908
    ]
  },
  "函館市": {
    "code": "120200",
    "coordinates": [
      140.925962642598,
      41.8375442406375
    ]
  },
  "小樽市": {
    "code": "120300",
    "coordinates": [
      141.026192698697,
      43.1563512374246
    ]
  },
  "旭川市": {
    "code": "120400",
    "coordinates": [
      142.370077646541,
      43.7579245541089
    ]
  },
  "室蘭市": {
    "code": "120500",
    "coordinates": [
      140.988434455214,
      42.3665755241644
    ]
  },
  "釧路市": {
    "code": "120600",
    "coordinates": [
      144.066466792425,
      43.1591080478681
    ]
  },
  "帯広市": {
    "code": "120700",
    "coordinates": [
      143.030598162976,
      42.7573626555188
    ]
  },
  "北見市": {
    "code": "120800",
    "coordinates": [
      143.715187095392,
      43.8516046711449
    ]
  },
  "夕張市": {
    "code": "120900",
    "coordinates": [
      142.11063878828,
      43.0433644063873
    ]
  },
  "岩見沢市": {
    "code": "121000",
    "coordinates": [
      141.797432133031,
      43.180870132831
    ]
  },
  "網走市": {
    "code": "121100",
    "coordinates": [
      144.210500906309,
      43.9856540982646
    ]
  },
  "留萌市": {
    "code": "121200",
    "coordinates": [
      141.755657223362,
      43.8856050434617
    ]
  },
  "苫小牧市": {
    "code": "121300",
    "coordinates": [
      141.579487993938,
      42.682242280286
    ]
  },
  "稚内市": {
    "code": "121400",
    "coordinates": [
      141.828300620651,
      45.3324316315819
    ]
  },
  "美唄市": {
    "code": "121500",
    "coordinates": [
      141.881827522827,
      43.3380517087789
    ]
  },
  "芦別市": {
    "code": "121600",
    "coordinates": [
      142.2015985771,
      43.4254209757933
    ]
  },
  "江別市": {
    "code": "121700",
    "coordinates": [
      141.543901114903,
      43.1072960652917
    ]
  },
  "赤平市": {
    "code": "121800",
    "coordinates": [
      142.062801745884,
      43.5679010074692
    ]
  },
  "紋別市": {
    "code": "121900",
    "coordinates": [
      143.292920553104,
      44.2032345382318
    ]
  },
  "士別市": {
    "code": "122000",
    "coordinates": [
      142.54052883111,
      44.1152275968448
    ]
  },
  "名寄市": {
    "code": "122100",
    "coordinates": [
      142.459307181794,
      44.3395133484202
    ]
  },
  "三笠市": {
    "code": "122200",
    "coordinates": [
      141.992719364262,
      43.2397670564251
    ]
  },
  "根室市": {
    "code": "122300",
    "coordinates": [
      145.585475423304,
      43.3154699204573
    ]
  },
  "千歳市": {
    "code": "122400",
    "coordinates": [
      141.509504671969,
      42.8004827639243
    ]
  },
  "滝川市": {
    "code": "122500",
    "coordinates": [
      141.949868657844,
      43.6083752291819
    ]
  },
  "砂川市": {
    "code": "122600",
    "coordinates": [
      141.932729587327,
      43.5032849438688
    ]
  },
  "歌志内市": {
    "code": "122700",
    "coordinates": [
      142.038773450017,
      43.4936617801398
    ]
  },
  "深川市": {
    "code": "122800",
    "coordinates": [
      142.0992248772,
      43.7933487948471
    ]
  },
  "富良野市": {
    "code": "122900",
    "coordinates": [
      142.47348363953,
      43.2818291411095
    ]
  },
  "登別市": {
    "code": "123000",
    "coordinates": [
      141.075774394504,
      42.46565244799
    ]
  },
  "恵庭市": {
    "code": "123100",
    "coordinates": [
      141.464105855898,
      42.8712422636348
    ]
  },
  "胆振伊達市": {
    "code": "123300",
    "coordinates": [
      141.017532467502,
      42.6097196394369
    ]
  },
  "北広島市": {
    "code": "123400",
    "coordinates": [
      141.514915746517,
      42.9615516344947
    ]
  },
  "石狩市": {
    "code": "123500",
    "coordinates": [
      141.442932669489,
      43.4658953680852
    ]
  },
  "渡島北斗市": {
    "code": "123600",
    "coordinates": [
      140.55423403161,
      41.8641182356455
    ]
  },
  "当別町": {
    "code": "130300",
    "coordinates": [
      141.562727936963,
      43.3635999846905
    ]
  },
  "新篠津村": {
    "code": "130400",
    "coordinates": [
      141.61490162357,
      43.2243642365864
    ]
  },
  "渡島松前町": {
    "code": "133100",
    "coordinates": [
      140.065064509337,
      41.5150149729633
    ]
  },
  "福島町": {
    "code": "133200",
    "coordinates": [
      140.249645192365,
      41.5255649156046
    ]
  },
  "知内町": {
    "code": "133300",
    "coordinates": [
      140.342003715277,
      41.6030993873802
    ]
  },
  "木古内町": {
    "code": "133400",
    "coordinates": [
      140.41541650686,
      41.727651298417
    ]
  },
  "七飯町": {
    "code": "133700",
    "coordinates": [
      140.700264558189,
      41.9487781074115
    ]
  },
  "鹿部町": {
    "code": "134300",
    "coordinates": [
      140.782458778197,
      42.0214069895975
    ]
  },
  "渡島森町": {
    "code": "134500",
    "coordinates": [
      140.562266692892,
      42.0700162240445
    ]
  },
  "八雲町": {
    "code": "134600",
    "coordinates": [
      140.200486175398,
      42.18993891893
    ]
  },
  "長万部町": {
    "code": "134700",
    "coordinates": [
      140.311860385605,
      42.5211110920655
    ]
  },
  "檜山江差町": {
    "code": "136100",
    "coordinates": [
      140.177343635188,
      41.8857478104748
    ]
  },
  "上ノ国町": {
    "code": "136200",
    "coordinates": [
      140.173407463899,
      41.7138659066908
    ]
  },
  "厚沢部町": {
    "code": "136300",
    "coordinates": [
      140.343054049216,
      41.9219456195283
    ]
  },
  "乙部町": {
    "code": "136400",
    "coordinates": [
      140.183223658308,
      42.0221188058876
    ]
  },
  "奥尻町": {
    "code": "136700",
    "coordinates": [
      139.473175204362,
      42.1553384492168
    ]
  },
  "今金町": {
    "code": "137000",
    "coordinates": [
      140.093679653035,
      42.4437118815274
    ]
  },
  "せたな町": {
    "code": "137100",
    "coordinates": [
      139.901860998866,
      42.3831904344138
    ]
  },
  "島牧村": {
    "code": "139100",
    "coordinates": [
      140.070631182458,
      42.6554412308691
    ]
  },
  "寿都町": {
    "code": "139200",
    "coordinates": [
      140.299544246152,
      42.7909143804771
    ]
  },
  "黒松内町": {
    "code": "139300",
    "coordinates": [
      140.363792407392,
      42.6672133575392
    ]
  },
  "蘭越町": {
    "code": "139400",
    "coordinates": [
      140.491244622736,
      42.8003142282923
    ]
  },
  "ニセコ町": {
    "code": "139500",
    "coordinates": [
      140.674174757977,
      42.7797862056863
    ]
  },
  "真狩村": {
    "code": "139600",
    "coordinates": [
      140.798248817261,
      42.7574657199462
    ]
  },
  "留寿都村": {
    "code": "139700",
    "coordinates": [
      140.900005610511,
      42.7182682998172
    ]
  },
  "喜茂別町": {
    "code": "139800",
    "coordinates": [
      141.00522647921,
      42.7982057947316
    ]
  },
  "京極町": {
    "code": "139900",
    "coordinates": [
      140.942780224438,
      42.8876811538519
    ]
  },
  "倶知安町": {
    "code": "140000",
    "coordinates": [
      140.779569825836,
      42.9136681624468
    ]
  },
  "共和町": {
    "code": "140100",
    "coordinates": [
      140.627353683449,
      42.9883411536116
    ]
  },
  "岩内町": {
    "code": "140200",
    "coordinates": [
      140.478553630562,
      42.937532086517
    ]
  },
  "泊村": {
    "code": "140300",
    "coordinates": [
      140.515336587841,
      43.1008838387938
    ]
  },
  "神恵内村": {
    "code": "140400",
    "coordinates": [
      140.432782589621,
      43.1937305984033
    ]
  },
  "積丹町": {
    "code": "140500",
    "coordinates": [
      140.477826809538,
      43.2879686606364
    ]
  },
  "古平町": {
    "code": "140600",
    "coordinates": [
      140.609652663434,
      43.1880018421168
    ]
  },
  "仁木町": {
    "code": "140700",
    "coordinates": [
      140.735040374718,
      43.0908115693715
    ]
  },
  "余市町": {
    "code": "140800",
    "coordinates": [
      140.774069490193,
      43.1764689512677
    ]
  },
  "赤井川村": {
    "code": "140900",
    "coordinates": [
      140.906516648632,
      43.0552254842863
    ]
  },
  "南幌町": {
    "code": "142300",
    "coordinates": [
      141.633417152572,
      43.0596496774884
    ]
  },
  "奈井江町": {
    "code": "142400",
    "coordinates": [
      141.924926111403,
      43.418317703347
    ]
  },
  "上砂川町": {
    "code": "142500",
    "coordinates": [
      142.003771967131,
      43.4484725007119
    ]
  },
  "由仁町": {
    "code": "142700",
    "coordinates": [
      141.862548370366,
      42.941178859309
    ]
  },
  "長沼町": {
    "code": "142800",
    "coordinates": [
      141.694905351531,
      42.9893833396773
    ]
  },
  "栗山町": {
    "code": "142900",
    "coordinates": [
      141.87086681172,
      43.0266110039422
    ]
  },
  "月形町": {
    "code": "143000",
    "coordinates": [
      141.672971769562,
      43.3697500384396
    ]
  },
  "浦臼町": {
    "code": "143100",
    "coordinates": [
      141.7891480267,
      43.4385761893554
    ]
  },
  "新十津川町": {
    "code": "143200",
    "coordinates": [
      141.728129130293,
      43.5904186644627
    ]
  },
  "妹背牛町": {
    "code": "143300",
    "coordinates": [
      141.944696954962,
      43.7075734248929
    ]
  },
  "秩父別町": {
    "code": "143400",
    "coordinates": [
      141.959083552463,
      43.7748163340232
    ]
  },
  "雨竜町": {
    "code": "143600",
    "coordinates": [
      141.750250644667,
      43.694775281966
    ]
  },
  "北竜町": {
    "code": "143700",
    "coordinates": [
      141.790983426028,
      43.7643206729254
    ]
  },
  "沼田町": {
    "code": "143800",
    "coordinates": [
      141.962603510267,
      43.8855538157676
    ]
  },
  "鷹栖町": {
    "code": "145200",
    "coordinates": [
      142.33648843844,
      43.8892904043534
    ]
  },
  "東神楽町": {
    "code": "145300",
    "coordinates": [
      142.490908536181,
      43.6742159372439
    ]
  },
  "当麻町": {
    "code": "145400",
    "coordinates": [
      142.594233038244,
      43.8191911298422
    ]
  },
  "比布町": {
    "code": "145500",
    "coordinates": [
      142.485029819101,
      43.9185522323854
    ]
  },
  "愛別町": {
    "code": "145600",
    "coordinates": [
      142.635524912061,
      43.9430015321779
    ]
  },
  "上川地方上川町": {
    "code": "145700",
    "coordinates": [
      142.927891564619,
      43.7509770634608
    ]
  },
  "東川町": {
    "code": "145800",
    "coordinates": [
      142.684914150216,
      43.6728701848758
    ]
  },
  "美瑛町": {
    "code": "145900",
    "coordinates": [
      142.61188035209,
      43.5580150734858
    ]
  },
  "上富良野町": {
    "code": "146000",
    "coordinates": [
      142.526977669406,
      43.4539568935978
    ]
  },
  "中富良野町": {
    "code": "146100",
    "coordinates": [
      142.429147053644,
      43.4026872644827
    ]
  },
  "南富良野町": {
    "code": "146200",
    "coordinates": [
      142.56113093437,
      43.1669732709893
    ]
  },
  "占冠村": {
    "code": "146300",
    "coordinates": [
      142.475549259966,
      43.0082455693405
    ]
  },
  "和寒町": {
    "code": "146400",
    "coordinates": [
      142.37441306355,
      44.010538741836
    ]
  },
  "剣淵町": {
    "code": "146500",
    "coordinates": [
      142.341502576978,
      44.1035896365315
    ]
  },
  "下川町": {
    "code": "146800",
    "coordinates": [
      142.71665100922,
      44.3135747675629
    ]
  },
  "美深町": {
    "code": "146900",
    "coordinates": [
      142.396116621012,
      44.5403631165513
    ]
  },
  "音威子府村": {
    "code": "147000",
    "coordinates": [
      142.247336666665,
      44.7147805149174
    ]
  },
  "上川中川町": {
    "code": "147100",
    "coordinates": [
      142.093371155031,
      44.6903334488434
    ]
  },
  "幌加内町": {
    "code": "147200",
    "coordinates": [
      142.15442091398,
      44.2308565466283
    ]
  },
  "増毛町": {
    "code": "148100",
    "coordinates": [
      141.535770509821,
      43.7881166386596
    ]
  },
  "小平町": {
    "code": "148200",
    "coordinates": [
      141.8396268424,
      44.0585027925909
    ]
  },
  "苫前町": {
    "code": "148300",
    "coordinates": [
      141.836673608675,
      44.2064302871756
    ]
  },
  "羽幌町": {
    "code": "148400",
    "coordinates": [
      141.883192677068,
      44.3355580432847
    ]
  },
  "初山別村": {
    "code": "148500",
    "coordinates": [
      141.840614946587,
      44.5065276375403
    ]
  },
  "遠別町": {
    "code": "148600",
    "coordinates": [
      141.933470989634,
      44.6202046994209
    ]
  },
  "天塩町": {
    "code": "148700",
    "coordinates": [
      141.86155709944,
      44.8796271892672
    ]
  },
  "猿払村": {
    "code": "151100",
    "coordinates": [
      142.112774486941,
      45.2253755909344
    ]
  },
  "浜頓別町": {
    "code": "151200",
    "coordinates": [
      142.308321928749,
      45.0757849795836
    ]
  },
  "中頓別町": {
    "code": "151300",
    "coordinates": [
      142.277878311303,
      44.9134874152075
    ]
  },
  "宗谷枝幸町": {
    "code": "151400",
    "coordinates": [
      142.54287061247,
      44.7744642978792
    ]
  },
  "豊富町": {
    "code": "151600",
    "coordinates": [
      141.791905287266,
      45.1458163429387
    ]
  },
  "礼文町": {
    "code": "151700",
    "coordinates": [
      141.025668280363,
      45.3751830158829
    ]
  },
  "利尻町": {
    "code": "151800",
    "coordinates": [
      141.196052836089,
      45.1618022075682
    ]
  },
  "利尻富士町": {
    "code": "151900",
    "coordinates": [
      141.254500413462,
      45.1888933911953
    ]
  },
  "幌延町": {
    "code": "152000",
    "coordinates": [
      141.952214668059,
      45.0014335873826
    ]
  },
  "美幌町": {
    "code": "154300",
    "coordinates": [
      144.132772863221,
      43.7546239514073
    ]
  },
  "津別町": {
    "code": "154400",
    "coordinates": [
      144.002881183443,
      43.6075334324412
    ]
  },
  "斜里町": {
    "code": "154500",
    "coordinates": [
      144.921076965537,
      43.977225430724
    ]
  },
  "清里町": {
    "code": "154600",
    "coordinates": [
      144.599755284455,
      43.7330062404078
    ]
  },
  "小清水町": {
    "code": "154700",
    "coordinates": [
      144.456931884289,
      43.8142458066824
    ]
  },
  "訓子府町": {
    "code": "154900",
    "coordinates": [
      143.732216307032,
      43.7009800990933
    ]
  },
  "置戸町": {
    "code": "155000",
    "coordinates": [
      143.455249657859,
      43.6320922270976
    ]
  },
  "佐呂間町": {
    "code": "155200",
    "coordinates": [
      143.784221765708,
      44.0242845519287
    ]
  },
  "遠軽町": {
    "code": "155500",
    "coordinates": [
      143.311144931154,
      43.9072169855919
    ]
  },
  "湧別町": {
    "code": "155900",
    "coordinates": [
      143.607599861646,
      44.1275424777035
    ]
  },
  "滝上町": {
    "code": "156000",
    "coordinates": [
      143.012491576438,
      44.1035034231612
    ]
  },
  "興部町": {
    "code": "156100",
    "coordinates": [
      143.091345644407,
      44.3968436242724
    ]
  },
  "西興部村": {
    "code": "156200",
    "coordinates": [
      142.924193176104,
      44.3205986560858
    ]
  },
  "雄武町": {
    "code": "156300",
    "coordinates": [
      142.832085591527,
      44.5365035877003
    ]
  },
  "大空町": {
    "code": "156400",
    "coordinates": [
      144.247824154266,
      43.8394745480547
    ]
  },
  "豊浦町": {
    "code": "157100",
    "coordinates": [
      140.652826086923,
      42.6443322470777
    ]
  },
  "壮瞥町": {
    "code": "157500",
    "coordinates": [
      140.956694233098,
      42.5744608105552
    ]
  },
  "白老町": {
    "code": "157800",
    "coordinates": [
      141.242648986372,
      42.5876993615254
    ]
  },
  "厚真町": {
    "code": "158100",
    "coordinates": [
      141.95144925833,
      42.7352066886151
    ]
  },
  "洞爺湖町": {
    "code": "158400",
    "coordinates": [
      140.830830730402,
      42.625734104586
    ]
  },
  "安平町": {
    "code": "158500",
    "coordinates": [
      141.837832311224,
      42.8135803918745
    ]
  },
  "むかわ町": {
    "code": "158600",
    "coordinates": [
      142.134539409825,
      42.7583787770147
    ]
  },
  "日高地方日高町": {
    "code": "160100",
    "coordinates": [
      142.440934005877,
      42.7273364286418
    ]
  },
  "平取町": {
    "code": "160200",
    "coordinates": [
      142.361083397182,
      42.7032014593489
    ]
  },
  "新冠町": {
    "code": "160400",
    "coordinates": [
      142.483082159616,
      42.5447627109978
    ]
  },
  "浦河町": {
    "code": "160700",
    "coordinates": [
      142.879665214643,
      42.2899564406496
    ]
  },
  "様似町": {
    "code": "160800",
    "coordinates": [
      143.05677530122,
      42.1579096279156
    ]
  },
  "えりも町": {
    "code": "160900",
    "coordinates": [
      143.221665238091,
      42.0719035013015
    ]
  },
  "新ひだか町": {
    "code": "161000",
    "coordinates": [
      142.640307304274,
      42.4294936709219
    ]
  },
  "音更町": {
    "code": "163100",
    "coordinates": [
      143.205354776124,
      43.0425733372848
    ]
  },
  "士幌町": {
    "code": "163200",
    "coordinates": [
      143.261416712776,
      43.1653958234801
    ]
  },
  "上士幌町": {
    "code": "163300",
    "coordinates": [
      143.193230110405,
      43.385123407129
    ]
  },
  "鹿追町": {
    "code": "163400",
    "coordinates": [
      143.03471941749,
      43.2145031903019
    ]
  },
  "新得町": {
    "code": "163500",
    "coordinates": [
      142.863729321201,
      43.3174779631637
    ]
  },
  "十勝清水町": {
    "code": "163600",
    "coordinates": [
      142.882763005393,
      42.9676538498856
    ]
  },
  "芽室町": {
    "code": "163700",
    "coordinates": [
      142.96039388598,
      42.8577248514977
    ]
  },
  "中札内村": {
    "code": "163800",
    "coordinates": [
      142.971774290461,
      42.6392985232906
    ]
  },
  "更別村": {
    "code": "163900",
    "coordinates": [
      143.203632964227,
      42.6317912962895
    ]
  },
  "十勝大樹町": {
    "code": "164100",
    "coordinates": [
      143.167546229701,
      42.5112977644644
    ]
  },
  "広尾町": {
    "code": "164200",
    "coordinates": [
      143.210069636069,
      42.3277551838616
    ]
  },
  "幕別町": {
    "code": "164300",
    "coordinates": [
      143.317397917001,
      42.7489958167121
    ]
  },
  "十勝池田町": {
    "code": "164400",
    "coordinates": [
      143.463680984093,
      42.989295134252
    ]
  },
  "豊頃町": {
    "code": "164500",
    "coordinates": [
      143.486382754324,
      42.7416510090172
    ]
  },
  "本別町": {
    "code": "164600",
    "coordinates": [
      143.553308540874,
      43.1631235615599
    ]
  },
  "足寄町": {
    "code": "164700",
    "coordinates": [
      143.586726617589,
      43.3747939456443
    ]
  },
  "陸別町": {
    "code": "164800",
    "coordinates": [
      143.687607745323,
      43.5123695117127
    ]
  },
  "浦幌町": {
    "code": "164900",
    "coordinates": [
      143.679257938605,
      42.9154169741089
    ]
  },
  "釧路町": {
    "code": "166100",
    "coordinates": [
      144.538912776803,
      43.0155224580983
    ]
  },
  "厚岸町": {
    "code": "166200",
    "coordinates": [
      144.809531576531,
      43.1250184698585
    ]
  },
  "浜中町": {
    "code": "166300",
    "coordinates": [
      145.088984937738,
      43.1573549210085
    ]
  },
  "標茶町": {
    "code": "166400",
    "coordinates": [
      144.56038541402,
      43.2936542440786
    ]
  },
  "弟子屈町": {
    "code": "166500",
    "coordinates": [
      144.399883448184,
      43.5472480898255
    ]
  },
  "鶴居村": {
    "code": "166700",
    "coordinates": [
      144.295497827882,
      43.250251959372
    ]
  },
  "白糠町": {
    "code": "166800",
    "coordinates": [
      143.96533753957,
      43.1264734766333
    ]
  },
  "別海町": {
    "code": "169100",
    "coordinates": [
      145.017145856032,
      43.3921494419389
    ]
  },
  "中標津町": {
    "code": "169200",
    "coordinates": [
      144.844926997131,
      43.5813539807314
    ]
  },
  "標津町": {
    "code": "169300",
    "coordinates": [
      144.97094843492,
      43.7379532767486
    ]
  },
  "羅臼町": {
    "code": "169400",
    "coordinates": [
      145.143749097637,
      44.0391096447083
    ]
  },
  "青森市": {
    "code": "220100",
    "coordinates": [
      140.754210601341,
      40.7700371002889
    ]
  },
  "弘前市": {
    "code": "220200",
    "coordinates": [
      140.3845954262,
      40.6051213866413
    ]
  },
  "八戸市": {
    "code": "220300",
    "coordinates": [
      141.481233754877,
      40.4739550885852
    ]
  },
  "黒石市": {
    "code": "220400",
    "coordinates": [
      140.704658314426,
      40.6170902193529
    ]
  },
  "五所川原市": {
    "code": "220500",
    "coordinates": [
      140.469971900911,
      40.9165834961388
    ]
  },
  "十和田市": {
    "code": "220600",
    "coordinates": [
      141.056990544619,
      40.5668230285221
    ]
  },
  "三沢市": {
    "code": "220700",
    "coordinates": [
      141.38125793325,
      40.7464418512257
    ]
  },
  "むつ市": {
    "code": "220800",
    "coordinates": [
      141.033262141262,
      41.2909125976487
    ]
  },
  "つがる市": {
    "code": "220900",
    "coordinates": [
      140.349622149906,
      40.8698848862145
    ]
  },
  "平川市": {
    "code": "221000",
    "coordinates": [
      140.700469509437,
      40.5183309603624
    ]
  },
  "平内町": {
    "code": "230100",
    "coordinates": [
      140.956199351809,
      40.9077013375678
    ]
  },
  "今別町": {
    "code": "230300",
    "coordinates": [
      140.516263215907,
      41.1602698018269
    ]
  },
  "蓬田村": {
    "code": "230400",
    "coordinates": [
      140.596494083907,
      40.9950318688102
    ]
  },
  "外ヶ浜町": {
    "code": "230700",
    "coordinates": [
      140.524406442855,
      41.1207900524264
    ]
  },
  "鰺ヶ沢町": {
    "code": "232100",
    "coordinates": [
      140.186749780218,
      40.6514730512314
    ]
  },
  "深浦町": {
    "code": "232300",
    "coordinates": [
      140.018285161069,
      40.6048948721214
    ]
  },
  "西目屋村": {
    "code": "234300",
    "coordinates": [
      140.237031617481,
      40.5108630291227
    ]
  },
  "藤崎町": {
    "code": "236100",
    "coordinates": [
      140.516456234138,
      40.6752178845713
    ]
  },
  "大鰐町": {
    "code": "236200",
    "coordinates": [
      140.551399637902,
      40.4840785361081
    ]
  },
  "田舎館村": {
    "code": "236700",
    "coordinates": [
      140.541650691418,
      40.6420484966045
    ]
  },
  "板柳町": {
    "code": "238100",
    "coordinates": [
      140.481108025696,
      40.7155197788387
    ]
  },
  "鶴田町": {
    "code": "238400",
    "coordinates": [
      140.421393949447,
      40.7563981316815
    ]
  },
  "中泊町": {
    "code": "238700",
    "coordinates": [
      140.416524060268,
      41.0489606724544
    ]
  },
  "野辺地町": {
    "code": "240100",
    "coordinates": [
      141.146363049009,
      40.8845941466396
    ]
  },
  "七戸町": {
    "code": "240200",
    "coordinates": [
      141.07877207291,
      40.7315989190816
    ]
  },
  "六戸町": {
    "code": "240500",
    "coordinates": [
      141.315379221905,
      40.6276012012863
    ]
  },
  "横浜町": {
    "code": "240600",
    "coordinates": [
      141.285012839668,
      41.06701208786
    ]
  },
  "東北町": {
    "code": "240800",
    "coordinates": [
      141.23472547454,
      40.7955212694235
    ]
  },
  "六ヶ所村": {
    "code": "241100",
    "coordinates": [
      141.331047532733,
      40.9568692872518
    ]
  },
  "おいらせ町": {
    "code": "241200",
    "coordinates": [
      141.401953100768,
      40.6262029304282
    ]
  },
  "大間町": {
    "code": "242300",
    "coordinates": [
      140.927569653848,
      41.4762465005826
    ]
  },
  "東通村": {
    "code": "242400",
    "coordinates": [
      141.351954490798,
      41.2783167110936
    ]
  },
  "風間浦村": {
    "code": "242500",
    "coordinates": [
      141.014285697059,
      41.4639711115707
    ]
  },
  "佐井村": {
    "code": "242600",
    "coordinates": [
      140.855158422461,
      41.3527185576536
    ]
  },
  "三戸町": {
    "code": "244100",
    "coordinates": [
      141.175558545042,
      40.3770705575688
    ]
  },
  "五戸町": {
    "code": "244200",
    "coordinates": [
      141.296716217301,
      40.5081604959374
    ]
  },
  "田子町": {
    "code": "244300",
    "coordinates": [
      141.049963311885,
      40.3091013935619
    ]
  },
  "青森南部町": {
    "code": "244500",
    "coordinates": [
      141.347028120404,
      40.4222151115448
    ]
  },
  "階上町": {
    "code": "244600",
    "coordinates": [
      141.584220416987,
      40.4304557965771
    ]
  },
  "新郷村": {
    "code": "245000",
    "coordinates": [
      141.107561254872,
      40.4473077628344
    ]
  },
  "盛岡市": {
    "code": "320100",
    "coordinates": [
      141.269142398859,
      39.7454618016809
    ]
  },
  "宮古市": {
    "code": "320200",
    "coordinates": [
      141.725630960242,
      39.6293403836826
    ]
  },
  "大船渡市": {
    "code": "320300",
    "coordinates": [
      141.75020230527,
      39.1069989341437
    ]
  },
  "花巻市": {
    "code": "320500",
    "coordinates": [
      141.167501514106,
      39.4418759983918
    ]
  },
  "北上市": {
    "code": "320600",
    "coordinates": [
      141.007941099113,
      39.3076603958843
    ]
  },
  "久慈市": {
    "code": "320700",
    "coordinates": [
      141.659173394645,
      40.143650982247
    ]
  },
  "遠野市": {
    "code": "320800",
    "coordinates": [
      141.509216292977,
      39.3647950530889
    ]
  },
  "一関市": {
    "code": "320900",
    "coordinates": [
      141.229748519693,
      38.945079069246
    ]
  },
  "陸前高田市": {
    "code": "321000",
    "coordinates": [
      141.577022386575,
      39.0408277632613
    ]
  },
  "釜石市": {
    "code": "321100",
    "coordinates": [
      141.798544799185,
      39.287484744335
    ]
  },
  "二戸市": {
    "code": "321300",
    "coordinates": [
      141.195752241471,
      40.2379882733697
    ]
  },
  "八幡平市": {
    "code": "321400",
    "coordinates": [
      140.991117732272,
      40.0239063182927
    ]
  },
  "奥州市": {
    "code": "321500",
    "coordinates": [
      141.094779892713,
      39.1296230069261
    ]
  },
  "滝沢市": {
    "code": "321600",
    "coordinates": [
      141.072593978259,
      39.7903664861475
    ]
  },
  "雫石町": {
    "code": "330100",
    "coordinates": [
      140.913294438182,
      39.7040025218606
    ]
  },
  "葛巻町": {
    "code": "330200",
    "coordinates": [
      141.43946908484,
      40.0066636679746
    ]
  },
  "岩手町": {
    "code": "330300",
    "coordinates": [
      141.266014330009,
      39.9533635676075
    ]
  },
  "紫波町": {
    "code": "332100",
    "coordinates": [
      141.158944285286,
      39.5484193942513
    ]
  },
  "矢巾町": {
    "code": "332200",
    "coordinates": [
      141.124210323682,
      39.611477051576
    ]
  },
  "西和賀町": {
    "code": "336600",
    "coordinates": [
      140.790815583124,
      39.3908100578327
    ]
  },
  "金ケ崎町": {
    "code": "338100",
    "coordinates": [
      141.018055695818,
      39.1939996082676
    ]
  },
  "平泉町": {
    "code": "340200",
    "coordinates": [
      141.10400596201,
      38.9889188592292
    ]
  },
  "住田町": {
    "code": "344100",
    "coordinates": [
      141.550441863757,
      39.1801196442444
    ]
  },
  "大槌町": {
    "code": "346100",
    "coordinates": [
      141.814087140098,
      39.4260485788108
    ]
  },
  "山田町": {
    "code": "348200",
    "coordinates": [
      141.907415893484,
      39.4842364937831
    ]
  },
  "岩泉町": {
    "code": "348300",
    "coordinates": [
      141.676544409511,
      39.8486715103076
    ]
  },
  "田野畑村": {
    "code": "348400",
    "coordinates": [
      141.873147131311,
      39.9246513022267
    ]
  },
  "普代村": {
    "code": "348500",
    "coordinates": [
      141.866813006709,
      40.0026008183249
    ]
  },
  "軽米町": {
    "code": "350100",
    "coordinates": [
      141.488939171365,
      40.3035400106212
    ]
  },
  "野田村": {
    "code": "350300",
    "coordinates": [
      141.795148100222,
      40.0687730063082
    ]
  },
  "九戸村": {
    "code": "350600",
    "coordinates": [
      141.433460129812,
      40.1962347444421
    ]
  },
  "岩手洋野町": {
    "code": "350700",
    "coordinates": [
      141.671287090711,
      40.3263283616865
    ]
  },
  "一戸町": {
    "code": "352400",
    "coordinates": [
      141.279161557043,
      40.1428131498013
    ]
  },
  "仙台青葉区": {
    "code": "410100",
    "coordinates": [
      140.702685774233,
      38.3307544519811
    ]
  },
  "仙台宮城野区": {
    "code": "410200",
    "coordinates": [
      140.956292591789,
      38.2739551251792
    ]
  },
  "仙台若林区": {
    "code": "410300",
    "coordinates": [
      140.939557816853,
      38.22530218852
    ]
  },
  "仙台太白区": {
    "code": "410400",
    "coordinates": [
      140.679497522218,
      38.2609830356686
    ]
  },
  "仙台泉区": {
    "code": "410500",
    "coordinates": [
      140.79975762173,
      38.3537869240284
    ]
  },
  "石巻市": {
    "code": "420200",
    "coordinates": [
      141.372146457255,
      38.4790907009566
    ]
  },
  "塩竈市": {
    "code": "420300",
    "coordinates": [
      141.039908943734,
      38.3265156437904
    ]
  },
  "気仙沼市": {
    "code": "420500",
    "coordinates": [
      141.534876782169,
      38.8760280981457
    ]
  },
  "白石市": {
    "code": "420600",
    "coordinates": [
      140.579428759063,
      37.99691378543
    ]
  },
  "名取市": {
    "code": "420700",
    "coordinates": [
      140.866655999535,
      38.1672945470406
    ]
  },
  "角田市": {
    "code": "420800",
    "coordinates": [
      140.779019573505,
      37.9882800788211
    ]
  },
  "多賀城市": {
    "code": "420900",
    "coordinates": [
      140.999096380137,
      38.2953578395801
    ]
  },
  "岩沼市": {
    "code": "421100",
    "coordinates": [
      140.874444419229,
      38.1093029691946
    ]
  },
  "登米市": {
    "code": "421200",
    "coordinates": [
      141.25743956034,
      38.685835296052
    ]
  },
  "栗原市": {
    "code": "421300",
    "coordinates": [
      140.940333177483,
      38.8089542275613
    ]
  },
  "東松島市": {
    "code": "421400",
    "coordinates": [
      141.177165024282,
      38.4108989668652
    ]
  },
  "大崎市": {
    "code": "421500",
    "coordinates": [
      140.846679090905,
      38.6800566232375
    ]
  },
  "富谷市": {
    "code": "421600",
    "coordinates": [
      140.901353239636,
      38.3795974283162
    ]
  },
  "蔵王町": {
    "code": "430100",
    "coordinates": [
      140.591328146785,
      38.1064375026533
    ]
  },
  "七ヶ宿町": {
    "code": "430200",
    "coordinates": [
      140.406061826537,
      38.0175284456733
    ]
  },
  "大河原町": {
    "code": "432100",
    "coordinates": [
      140.715121191769,
      38.0520914927985
    ]
  },
  "村田町": {
    "code": "432200",
    "coordinates": [
      140.730119936503,
      38.1272160910092
    ]
  },
  "柴田町": {
    "code": "432300",
    "coordinates": [
      140.787711000806,
      38.0857505137517
    ]
  },
  "宮城川崎町": {
    "code": "432400",
    "coordinates": [
      140.591388295309,
      38.1986157516527
    ]
  },
  "丸森町": {
    "code": "434100",
    "coordinates": [
      140.7552160191,
      37.8734780426716
    ]
  },
  "亘理町": {
    "code": "436100",
    "coordinates": [
      140.874142704804,
      38.0413748379556
    ]
  },
  "山元町": {
    "code": "436200",
    "coordinates": [
      140.88556393363,
      37.9457290423773
    ]
  },
  "松島町": {
    "code": "440100",
    "coordinates": [
      141.075957605852,
      38.4034275453978
    ]
  },
  "七ヶ浜町": {
    "code": "440400",
    "coordinates": [
      141.059919470748,
      38.3001846851767
    ]
  },
  "利府町": {
    "code": "440600",
    "coordinates": [
      140.986554984558,
      38.3425091728418
    ]
  },
  "大和町": {
    "code": "442100",
    "coordinates": [
      140.818174526997,
      38.4329484889805
    ]
  },
  "大郷町": {
    "code": "442200",
    "coordinates": [
      141.000053629655,
      38.4343092670689
    ]
  },
  "大衡村": {
    "code": "442400",
    "coordinates": [
      140.871820245482,
      38.4798203445505
    ]
  },
  "色麻町": {
    "code": "444400",
    "coordinates": [
      140.779938279404,
      38.5204941423157
    ]
  },
  "宮城加美町": {
    "code": "444500",
    "coordinates": [
      140.696913999569,
      38.5942663944934
    ]
  },
  "涌谷町": {
    "code": "450100",
    "coordinates": [
      141.153633138742,
      38.5592297381337
    ]
  },
  "宮城美里町": {
    "code": "450500",
    "coordinates": [
      141.092962596147,
      38.5201612947226
    ]
  },
  "女川町": {
    "code": "458100",
    "coordinates": [
      141.455641927951,
      38.4420152127261
    ]
  },
  "南三陸町": {
    "code": "460600",
    "coordinates": [
      141.452048359719,
      38.6847452941385
    ]
  },
  "秋田市": {
    "code": "520100",
    "coordinates": [
      140.232058394859,
      39.7104351073717
    ]
  },
  "能代市": {
    "code": "520200",
    "coordinates": [
      140.144850138648,
      40.2028944045511
    ]
  },
  "横手市": {
    "code": "520300",
    "coordinates": [
      140.544308620462,
      39.283691483416
    ]
  },
  "大館市": {
    "code": "520400",
    "coordinates": [
      140.554641853552,
      40.2901209005962
    ]
  },
  "男鹿市": {
    "code": "520600",
    "coordinates": [
      139.836089670785,
      39.9379048479195
    ]
  },
  "湯沢市": {
    "code": "520700",
    "coordinates": [
      140.542663981561,
      39.0365862475537
    ]
  },
  "鹿角市": {
    "code": "520900",
    "coordinates": [
      140.830288549776,
      40.2103595117815
    ]
  },
  "由利本荘市": {
    "code": "521000",
    "coordinates": [
      140.164975386998,
      39.2933680837185
    ]
  },
  "潟上市": {
    "code": "521100",
    "coordinates": [
      140.049091623655,
      39.8720987637974
    ]
  },
  "大仙市": {
    "code": "521200",
    "coordinates": [
      140.441377581975,
      39.5371001061092
    ]
  },
  "北秋田市": {
    "code": "521300",
    "coordinates": [
      140.46464179424,
      40.0551232055647
    ]
  },
  "にかほ市": {
    "code": "521400",
    "coordinates": [
      139.973034396048,
      39.19678368786
    ]
  },
  "仙北市": {
    "code": "521500",
    "coordinates": [
      140.668558253843,
      39.7605182184239
    ]
  },
  "小坂町": {
    "code": "530300",
    "coordinates": [
      140.779699609405,
      40.3992253988483
    ]
  },
  "上小阿仁村": {
    "code": "532700",
    "coordinates": [
      140.318589737059,
      39.9620235238419
    ]
  },
  "藤里町": {
    "code": "534600",
    "coordinates": [
      140.250880224507,
      40.3593593815546
    ]
  },
  "三種町": {
    "code": "534800",
    "coordinates": [
      140.106776754121,
      40.0706818275543
    ]
  },
  "八峰町": {
    "code": "534900",
    "coordinates": [
      140.068402280718,
      40.3625571035434
    ]
  },
  "五城目町": {
    "code": "536100",
    "coordinates": [
      140.203842657382,
      39.9267417439019
    ]
  },
  "八郎潟町": {
    "code": "536300",
    "coordinates": [
      140.080714562041,
      39.9591439157805
    ]
  },
  "井川町": {
    "code": "536600",
    "coordinates": [
      140.120314982774,
      39.8954133808398
    ]
  },
  "大潟村": {
    "code": "536800",
    "coordinates": [
      140.002114773596,
      40.0015303426432
    ]
  },
  "秋田美郷町": {
    "code": "543400",
    "coordinates": [
      140.595915435336,
      39.4284318794277
    ]
  },
  "羽後町": {
    "code": "546300",
    "coordinates": [
      140.350011760476,
      39.1839853686706
    ]
  },
  "東成瀬村": {
    "code": "546400",
    "coordinates": [
      140.727812607734,
      39.1021002952896
    ]
  },
  "山形市": {
    "code": "620100",
    "coordinates": [
      140.36893875774,
      38.2461031470896
    ]
  },
  "米沢市": {
    "code": "620200",
    "coordinates": [
      140.116506480972,
      37.8472767771786
    ]
  },
  "鶴岡市": {
    "code": "620300",
    "coordinates": [
      139.808381534873,
      38.5850704827012
    ]
  },
  "酒田市": {
    "code": "620400",
    "coordinates": [
      139.98426280466,
      38.9249071284226
    ]
  },
  "新庄市": {
    "code": "620500",
    "coordinates": [
      140.339881319568,
      38.7881923707575
    ]
  },
  "寒河江市": {
    "code": "620600",
    "coordinates": [
      140.231686935722,
      38.434469072582
    ]
  },
  "上山市": {
    "code": "620700",
    "coordinates": [
      140.305505776267,
      38.1257875627503
    ]
  },
  "村山市": {
    "code": "620800",
    "coordinates": [
      140.335175057442,
      38.5167198350918
    ]
  },
  "長井市": {
    "code": "620900",
    "coordinates": [
      139.981837920689,
      38.1374083444394
    ]
  },
  "天童市": {
    "code": "621000",
    "coordinates": [
      140.396512187363,
      38.3581961651551
    ]
  },
  "東根市": {
    "code": "621100",
    "coordinates": [
      140.466688913623,
      38.4255949228794
    ]
  },
  "尾花沢市": {
    "code": "621200",
    "coordinates": [
      140.476801498577,
      38.59362622339
    ]
  },
  "南陽市": {
    "code": "621300",
    "coordinates": [
      140.156080157251,
      38.1031968322092
    ]
  },
  "山辺町": {
    "code": "630100",
    "coordinates": [
      140.214933188422,
      38.2802259682512
    ]
  },
  "中山町": {
    "code": "630200",
    "coordinates": [
      140.259726017007,
      38.3309787790375
    ]
  },
  "河北町": {
    "code": "632100",
    "coordinates": [
      140.30099749997,
      38.4301790383036
    ]
  },
  "西川町": {
    "code": "632200",
    "coordinates": [
      140.023519284905,
      38.4249306625251
    ]
  },
  "山形朝日町": {
    "code": "632300",
    "coordinates": [
      140.06536218217,
      38.2804748324676
    ]
  },
  "大江町": {
    "code": "632400",
    "coordinates": [
      140.085514081598,
      38.3547613007497
    ]
  },
  "大石田町": {
    "code": "634100",
    "coordinates": [
      140.336070615993,
      38.5976689313535
    ]
  },
  "山形金山町": {
    "code": "636100",
    "coordinates": [
      140.371674727911,
      38.9028772717371
    ]
  },
  "最上町": {
    "code": "636200",
    "coordinates": [
      140.526874525826,
      38.7682342064863
    ]
  },
  "舟形町": {
    "code": "636300",
    "coordinates": [
      140.325680586828,
      38.679680017389
    ]
  },
  "真室川町": {
    "code": "636400",
    "coordinates": [
      140.243636161264,
      38.9560706007615
    ]
  },
  "大蔵村": {
    "code": "636500",
    "coordinates": [
      140.171912485398,
      38.5940035139646
    ]
  },
  "鮭川村": {
    "code": "636600",
    "coordinates": [
      140.196854884102,
      38.8260684423346
    ]
  },
  "戸沢村": {
    "code": "636700",
    "coordinates": [
      140.121990845461,
      38.7192189131224
    ]
  },
  "高畠町": {
    "code": "638100",
    "coordinates": [
      140.215879040448,
      37.9904219037968
    ]
  },
  "山形川西町": {
    "code": "638200",
    "coordinates": [
      140.023569975883,
      37.9717401939538
    ]
  },
  "山形小国町": {
    "code": "640100",
    "coordinates": [
      139.774850613951,
      38.040785164248
    ]
  },
  "白鷹町": {
    "code": "640200",
    "coordinates": [
      140.082597803819,
      38.2005523867844
    ]
  },
  "飯豊町": {
    "code": "640300",
    "coordinates": [
      139.897109816442,
      37.9383257566365
    ]
  },
  "三川町": {
    "code": "642600",
    "coordinates": [
      139.85018467507,
      38.7952218900068
    ]
  },
  "庄内町": {
    "code": "642800",
    "coordinates": [
      140.000989452733,
      38.7055414958411
    ]
  },
  "遊佐町": {
    "code": "646100",
    "coordinates": [
      139.951659674992,
      39.0583985167668
    ]
  },
  "福島市": {
    "code": "720100",
    "coordinates": [
      140.388485431642,
      37.786432406904
    ]
  },
  "会津若松市": {
    "code": "720200",
    "coordinates": [
      139.96813679413,
      37.4507124075462
    ]
  },
  "郡山市": {
    "code": "720300",
    "coordinates": [
      140.295063121754,
      37.4127724624461
    ]
  },
  "いわき市": {
    "code": "720400",
    "coordinates": [
      140.786067320101,
      37.0776689057693
    ]
  },
  "白河市": {
    "code": "720500",
    "coordinates": [
      140.249624526988,
      37.122259633584
    ]
  },
  "須賀川市": {
    "code": "720700",
    "coordinates": [
      140.316904743228,
      37.2934302172135
    ]
  },
  "喜多方市": {
    "code": "720800",
    "coordinates": [
      139.843866075459,
      37.7030251087772
    ]
  },
  "相馬市": {
    "code": "720900",
    "coordinates": [
      140.873203971329,
      37.7819350762648
    ]
  },
  "二本松市": {
    "code": "721000",
    "coordinates": [
      140.495422258562,
      37.5840240272978
    ]
  },
  "田村市": {
    "code": "721100",
    "coordinates": [
      140.67151790986,
      37.4235557499575
    ]
  },
  "南相馬市": {
    "code": "721200",
    "coordinates": [
      140.92850807241,
      37.631085999932
    ]
  },
  "福島伊達市": {
    "code": "721300",
    "coordinates": [
      140.614321080464,
      37.7995227609299
    ]
  },
  "本宮市": {
    "code": "721400",
    "coordinates": [
      140.418824430927,
      37.5048018669517
    ]
  },
  "桑折町": {
    "code": "730100",
    "coordinates": [
      140.505858900225,
      37.8588608836237
    ]
  },
  "国見町": {
    "code": "730300",
    "coordinates": [
      140.551131539422,
      37.8887592892155
    ]
  },
  "川俣町": {
    "code": "730800",
    "coordinates": [
      140.628329849632,
      37.6437713974326
    ]
  },
  "大玉村": {
    "code": "732200",
    "coordinates": [
      140.334443250648,
      37.5585878891102
    ]
  },
  "鏡石町": {
    "code": "734200",
    "coordinates": [
      140.3517019367,
      37.2445709449425
    ]
  },
  "天栄村": {
    "code": "734400",
    "coordinates": [
      140.087778190235,
      37.2613740056512
    ]
  },
  "下郷町": {
    "code": "736200",
    "coordinates": [
      139.87961105637,
      37.2565654513387
    ]
  },
  "檜枝岐村": {
    "code": "736400",
    "coordinates": [
      139.332456036419,
      37.032395325836
    ]
  },
  "只見町": {
    "code": "736700",
    "coordinates": [
      139.347814512303,
      37.291451218683
    ]
  },
  "南会津町": {
    "code": "736800",
    "coordinates": [
      139.60622581452,
      37.1367610137639
    ]
  },
  "北塩原村": {
    "code": "740200",
    "coordinates": [
      140.048478200803,
      37.6897973111095
    ]
  },
  "西会津町": {
    "code": "740500",
    "coordinates": [
      139.647972927919,
      37.6355584372788
    ]
  },
  "磐梯町": {
    "code": "740700",
    "coordinates": [
      140.00577436326,
      37.5783489511738
    ]
  },
  "猪苗代町": {
    "code": "740800",
    "coordinates": [
      140.155987376613,
      37.5945144897705
    ]
  },
  "会津坂下町": {
    "code": "742100",
    "coordinates": [
      139.800640343599,
      37.568852456514
    ]
  },
  "湯川村": {
    "code": "742200",
    "coordinates": [
      139.88226384512,
      37.5672886294735
    ]
  },
  "柳津町": {
    "code": "742300",
    "coordinates": [
      139.708811497379,
      37.4566722904738
    ]
  },
  "三島町": {
    "code": "744400",
    "coordinates": [
      139.63805423864,
      37.4418826531267
    ]
  },
  "福島金山町": {
    "code": "744500",
    "coordinates": [
      139.486922338685,
      37.4335702010481
    ]
  },
  "福島昭和村": {
    "code": "744600",
    "coordinates": [
      139.636597214402,
      37.3090893012477
    ]
  },
  "会津美里町": {
    "code": "744700",
    "coordinates": [
      139.813159793242,
      37.4086220144763
    ]
  },
  "西郷村": {
    "code": "746100",
    "coordinates": [
      140.093556465557,
      37.1659224330949
    ]
  },
  "泉崎村": {
    "code": "746400",
    "coordinates": [
      140.295096570397,
      37.1586041419701
    ]
  },
  "中島村": {
    "code": "746500",
    "coordinates": [
      140.365530126569,
      37.1484165754861
    ]
  },
  "矢吹町": {
    "code": "746600",
    "coordinates": [
      140.34701678656,
      37.1939094018024
    ]
  },
  "棚倉町": {
    "code": "748100",
    "coordinates": [
      140.343028601338,
      37.002508534115
    ]
  },
  "矢祭町": {
    "code": "748200",
    "coordinates": [
      140.428885131973,
      36.8630268157558
    ]
  },
  "塙町": {
    "code": "748300",
    "coordinates": [
      140.458571483412,
      36.9264461031641
    ]
  },
  "鮫川村": {
    "code": "748400",
    "coordinates": [
      140.510691896936,
      37.0123897175177
    ]
  },
  "石川町": {
    "code": "750100",
    "coordinates": [
      140.461327260338,
      37.1455458331081
    ]
  },
  "玉川村": {
    "code": "750200",
    "coordinates": [
      140.44997392512,
      37.2166535458316
    ]
  },
  "平田村": {
    "code": "750300",
    "coordinates": [
      140.557310746082,
      37.2110767463153
    ]
  },
  "浅川町": {
    "code": "750400",
    "coordinates": [
      140.425863673001,
      37.0811643116871
    ]
  },
  "古殿町": {
    "code": "750500",
    "coordinates": [
      140.583584998417,
      37.089998906612
    ]
  },
  "三春町": {
    "code": "752100",
    "coordinates": [
      140.496427186471,
      37.4409255093006
    ]
  },
  "小野町": {
    "code": "752200",
    "coordinates": [
      140.612559449094,
      37.2862813776082
    ]
  },
  "福島広野町": {
    "code": "754100",
    "coordinates": [
      140.952525670176,
      37.2198332015227
    ]
  },
  "楢葉町": {
    "code": "754200",
    "coordinates": [
      140.948938223999,
      37.2817373553254
    ]
  },
  "富岡町": {
    "code": "754300",
    "coordinates": [
      140.974332179811,
      37.3492278987437
    ]
  },
  "川内村": {
    "code": "754400",
    "coordinates": [
      140.810323443752,
      37.3322810439787
    ]
  },
  "大熊町": {
    "code": "754500",
    "coordinates": [
      140.94852240269,
      37.4011466597627
    ]
  },
  "双葉町": {
    "code": "754600",
    "coordinates": [
      140.975823766759,
      37.4435743508724
    ]
  },
  "浪江町": {
    "code": "754700",
    "coordinates": [
      140.864312560422,
      37.5169346980586
    ]
  },
  "葛尾村": {
    "code": "754800",
    "coordinates": [
      140.776991584811,
      37.5090419370547
    ]
  },
  "新地町": {
    "code": "756100",
    "coordinates": [
      140.900415891775,
      37.8669767236309
    ]
  },
  "飯舘村": {
    "code": "756400",
    "coordinates": [
      140.748328734665,
      37.6781618216551
    ]
  },
  "水戸市": {
    "code": "820100",
    "coordinates": [
      140.4355819773,
      36.3705376487886
    ]
  },
  "日立市": {
    "code": "820200",
    "coordinates": [
      140.618747875708,
      36.624925472118
    ]
  },
  "土浦市": {
    "code": "820300",
    "coordinates": [
      140.194774058511,
      36.0982632717523
    ]
  },
  "茨城古河市": {
    "code": "820400",
    "coordinates": [
      139.775335788702,
      36.1797553601171
    ]
  },
  "石岡市": {
    "code": "820500",
    "coordinates": [
      140.217754089002,
      36.2278400080177
    ]
  },
  "結城市": {
    "code": "820700",
    "coordinates": [
      139.864025097898,
      36.2612715705709
    ]
  },
  "龍ケ崎市": {
    "code": "820800",
    "coordinates": [
      140.185260256526,
      35.9224964075925
    ]
  },
  "下妻市": {
    "code": "821000",
    "coordinates": [
      139.966413990113,
      36.1874377725565
    ]
  },
  "常総市": {
    "code": "821100",
    "coordinates": [
      139.963960181734,
      36.0640933369943
    ]
  },
  "常陸太田市": {
    "code": "821200",
    "coordinates": [
      140.505348789196,
      36.6483666205125
    ]
  },
  "高萩市": {
    "code": "821400",
    "coordinates": [
      140.62092794623,
      36.7691886175991
    ]
  },
  "北茨城市": {
    "code": "821500",
    "coordinates": [
      140.687393615317,
      36.8398682136447
    ]
  },
  "笠間市": {
    "code": "821600",
    "coordinates": [
      140.269342361523,
      36.3565128325277
    ]
  },
  "取手市": {
    "code": "821700",
    "coordinates": [
      140.080080410871,
      35.9128662840718
    ]
  },
  "牛久市": {
    "code": "821900",
    "coordinates": [
      140.185768878949,
      35.9717512756403
    ]
  },
  "つくば市": {
    "code": "822000",
    "coordinates": [
      140.081734513002,
      36.1053225756543
    ]
  },
  "ひたちなか市": {
    "code": "822100",
    "coordinates": [
      140.557883293066,
      36.3959691529167
    ]
  },
  "茨城鹿嶋市": {
    "code": "822200",
    "coordinates": [
      140.621794268162,
      35.9999321447046
    ]
  },
  "潮来市": {
    "code": "822300",
    "coordinates": [
      140.562161166252,
      35.9615780716922
    ]
  },
  "守谷市": {
    "code": "822400",
    "coordinates": [
      139.975418496097,
      35.9479725937404
    ]
  },
  "常陸大宮市": {
    "code": "822500",
    "coordinates": [
      140.339890774301,
      36.6137097499141
    ]
  },
  "那珂市": {
    "code": "822600",
    "coordinates": [
      140.475967621539,
      36.4683772289101
    ]
  },
  "筑西市": {
    "code": "822700",
    "coordinates": [
      139.986913983542,
      36.2893894918665
    ]
  },
  "坂東市": {
    "code": "822800",
    "coordinates": [
      139.887858891764,
      36.0638407156821
    ]
  },
  "稲敷市": {
    "code": "822900",
    "coordinates": [
      140.371997474653,
      35.9486279981171
    ]
  },
  "かすみがうら市": {
    "code": "823000",
    "coordinates": [
      140.299318551562,
      36.10985629821
    ]
  },
  "桜川市": {
    "code": "823100",
    "coordinates": [
      140.107908293863,
      36.3274663178609
    ]
  },
  "神栖市": {
    "code": "823200",
    "coordinates": [
      140.711697873846,
      35.8512599354283
    ]
  },
  "行方市": {
    "code": "823300",
    "coordinates": [
      140.468215717848,
      36.0660754098726
    ]
  },
  "鉾田市": {
    "code": "823400",
    "coordinates": [
      140.519898876418,
      36.1734197434572
    ]
  },
  "つくばみらい市": {
    "code": "823500",
    "coordinates": [
      140.038110897743,
      35.982015950196
    ]
  },
  "小美玉市": {
    "code": "823600",
    "coordinates": [
      140.359794904529,
      36.203955662979
    ]
  },
  "茨城町": {
    "code": "830200",
    "coordinates": [
      140.434089391565,
      36.2804697946114
    ]
  },
  "大洗町": {
    "code": "830900",
    "coordinates": [
      140.554448642388,
      36.2973541719583
    ]
  },
  "城里町": {
    "code": "831000",
    "coordinates": [
      140.309440715285,
      36.4797479542061
    ]
  },
  "東海村": {
    "code": "834100",
    "coordinates": [
      140.580623934816,
      36.4607205266655
    ]
  },
  "大子町": {
    "code": "836400",
    "coordinates": [
      140.345606953007,
      36.7979997952046
    ]
  },
  "美浦村": {
    "code": "844200",
    "coordinates": [
      140.332174713948,
      36.0183889437573
    ]
  },
  "阿見町": {
    "code": "844300",
    "coordinates": [
      140.225459251884,
      36.0141249985653
    ]
  },
  "河内町": {
    "code": "844700",
    "coordinates": [
      140.279789769061,
      35.8827344310103
    ]
  },
  "八千代町": {
    "code": "852100",
    "coordinates": [
      139.89371489306,
      36.1783943190616
    ]
  },
  "五霞町": {
    "code": "854200",
    "coordinates": [
      139.748581099651,
      36.108029172098
    ]
  },
  "境町": {
    "code": "854600",
    "coordinates": [
      139.810891561972,
      36.1101538102045
    ]
  },
  "利根町": {
    "code": "856400",
    "coordinates": [
      140.167959152604,
      35.8661327812035
    ]
  },
  "宇都宮市": {
    "code": "920100",
    "coordinates": [
      139.886565427021,
      36.5968832518923
    ]
  },
  "足利市": {
    "code": "920200",
    "coordinates": [
      139.452997210054,
      36.3593170062958
    ]
  },
  "栃木市": {
    "code": "920300",
    "coordinates": [
      139.689499646755,
      36.3729225749765
    ]
  },
  "佐野市": {
    "code": "920400",
    "coordinates": [
      139.535772424918,
      36.4240678266723
    ]
  },
  "鹿沼市": {
    "code": "920500",
    "coordinates": [
      139.641479303728,
      36.5755060566515
    ]
  },
  "日光市": {
    "code": "920600",
    "coordinates": [
      139.581170641559,
      36.8188443376043
    ]
  },
  "小山市": {
    "code": "920800",
    "coordinates": [
      139.801939879095,
      36.308003346206
    ]
  },
  "真岡市": {
    "code": "920900",
    "coordinates": [
      139.996837254059,
      36.4248868883755
    ]
  },
  "大田原市": {
    "code": "921000",
    "coordinates": [
      140.119144386488,
      36.861294772139
    ]
  },
  "矢板市": {
    "code": "921100",
    "coordinates": [
      139.898085443043,
      36.8274691520414
    ]
  },
  "那須塩原市": {
    "code": "921300",
    "coordinates": [
      139.910514405149,
      36.9908502679243
    ]
  },
  "栃木さくら市": {
    "code": "921400",
    "coordinates": [
      140.002887041748,
      36.7236253042787
    ]
  },
  "那須烏山市": {
    "code": "921500",
    "coordinates": [
      140.134040720273,
      36.6627050273067
    ]
  },
  "下野市": {
    "code": "921600",
    "coordinates": [
      139.861307665146,
      36.4019638558058
    ]
  },
  "上三川町": {
    "code": "930100",
    "coordinates": [
      139.910601656845,
      36.445505060934
    ]
  },
  "益子町": {
    "code": "934200",
    "coordinates": [
      140.10989800219,
      36.4603366865807
    ]
  },
  "茂木町": {
    "code": "934300",
    "coordinates": [
      140.18993585525,
      36.5216587121236
    ]
  },
  "市貝町": {
    "code": "934400",
    "coordinates": [
      140.104299257497,
      36.5590158849655
    ]
  },
  "芳賀町": {
    "code": "934500",
    "coordinates": [
      140.040568228672,
      36.5519897598604
    ]
  },
  "壬生町": {
    "code": "936100",
    "coordinates": [
      139.804958307306,
      36.4575351634318
    ]
  },
  "野木町": {
    "code": "936400",
    "coordinates": [
      139.737852036407,
      36.228886874689
    ]
  },
  "塩谷町": {
    "code": "938400",
    "coordinates": [
      139.815177629892,
      36.7964720195685
    ]
  },
  "高根沢町": {
    "code": "938600",
    "coordinates": [
      140.016788596987,
      36.6301058108206
    ]
  },
  "那須町": {
    "code": "940700",
    "coordinates": [
      140.09252903783,
      37.0428918782756
    ]
  },
  "栃木那珂川町": {
    "code": "941100",
    "coordinates": [
      140.183453986823,
      36.7541723895067
    ]
  },
  "前橋市": {
    "code": "1020100",
    "coordinates": [
      139.127512821209,
      36.432131718214
    ]
  },
  "高崎市": {
    "code": "1020200",
    "coordinates": [
      138.900861723012,
      36.3763695527754
    ]
  },
  "桐生市": {
    "code": "1020300",
    "coordinates": [
      139.30605332062,
      36.4837480136796
    ]
  },
  "伊勢崎市": {
    "code": "1020400",
    "coordinates": [
      139.21822013556,
      36.3212608918861
    ]
  },
  "太田市": {
    "code": "1020500",
    "coordinates": [
      139.339240330067,
      36.3060132213048
    ]
  },
  "沼田市": {
    "code": "1020600",
    "coordinates": [
      139.183516233328,
      36.6737189124322
    ]
  },
  "館林市": {
    "code": "1020700",
    "coordinates": [
      139.53531651063,
      36.245977424935
    ]
  },
  "渋川市": {
    "code": "1020800",
    "coordinates": [
      139.014508948311,
      36.5291342471235
    ]
  },
  "藤岡市": {
    "code": "1020900",
    "coordinates": [
      138.999173241974,
      36.1921310587069
    ]
  },
  "富岡市": {
    "code": "1021000",
    "coordinates": [
      138.84617872429,
      36.2585188864927
    ]
  },
  "安中市": {
    "code": "1021100",
    "coordinates": [
      138.776458127503,
      36.3407928843948
    ]
  },
  "みどり市": {
    "code": "1021200",
    "coordinates": [
      139.347268320565,
      36.5304421752345
    ]
  },
  "榛東村": {
    "code": "1034400",
    "coordinates": [
      138.956652166217,
      36.4431575256956
    ]
  },
  "吉岡町": {
    "code": "1034500",
    "coordinates": [
      138.998153589587,
      36.449981655908
    ]
  },
  "群馬上野村": {
    "code": "1036600",
    "coordinates": [
      138.720134528072,
      36.0652805343323
    ]
  },
  "神流町": {
    "code": "1036700",
    "coordinates": [
      138.865657748659,
      36.1116812349366
    ]
  },
  "下仁田町": {
    "code": "1038200",
    "coordinates": [
      138.722808121592,
      36.2271438282464
    ]
  },
  "群馬南牧村": {
    "code": "1038300",
    "coordinates": [
      138.685665583718,
      36.1617401077187
    ]
  },
  "甘楽町": {
    "code": "1038400",
    "coordinates": [
      138.90327258832,
      36.209911047989
    ]
  },
  "中之条町": {
    "code": "1042100",
    "coordinates": [
      138.721927981613,
      36.6545256006757
    ]
  },
  "長野原町": {
    "code": "1042400",
    "coordinates": [
      138.625994395092,
      36.5050655309891
    ]
  },
  "嬬恋村": {
    "code": "1042500",
    "coordinates": [
      138.493491603216,
      36.5149569243728
    ]
  },
  "草津町": {
    "code": "1042600",
    "coordinates": [
      138.571369600727,
      36.6194537051896
    ]
  },
  "群馬高山村": {
    "code": "1042800",
    "coordinates": [
      138.941668446856,
      36.6219059509954
    ]
  },
  "東吾妻町": {
    "code": "1042900",
    "coordinates": [
      138.785194928518,
      36.5264191697547
    ]
  },
  "片品村": {
    "code": "1044300",
    "coordinates": [
      139.260751056057,
      36.8415785830733
    ]
  },
  "川場村": {
    "code": "1044400",
    "coordinates": [
      139.129337807649,
      36.7310312659432
    ]
  },
  "群馬昭和村": {
    "code": "1044800",
    "coordinates": [
      139.100808660261,
      36.6126255146948
    ]
  },
  "みなかみ町": {
    "code": "1044900",
    "coordinates": [
      139.004906679755,
      36.8322929991557
    ]
  },
  "玉村町": {
    "code": "1046400",
    "coordinates": [
      139.126295072076,
      36.3021736756698
    ]
  },
  "板倉町": {
    "code": "1052100",
    "coordinates": [
      139.620174361349,
      36.2288289189576
    ]
  },
  "群馬明和町": {
    "code": "1052200",
    "coordinates": [
      139.534189555767,
      36.2069180874796
    ]
  },
  "千代田町": {
    "code": "1052300",
    "coordinates": [
      139.450141253521,
      36.2130009369339
    ]
  },
  "大泉町": {
    "code": "1052400",
    "coordinates": [
      139.407023851678,
      36.2497233871045
    ]
  },
  "邑楽町": {
    "code": "1052500",
    "coordinates": [
      139.461286862468,
      36.2551021585572
    ]
  },
  "さいたま西区": {
    "code": "1110100",
    "coordinates": [
      139.575004968541,
      35.9093105538868
    ]
  },
  "さいたま北区": {
    "code": "1110200",
    "coordinates": [
      139.614853137509,
      35.9392442063349
    ]
  },
  "さいたま大宮区": {
    "code": "1110300",
    "coordinates": [
      139.624119514769,
      35.9066519515678
    ]
  },
  "さいたま見沼区": {
    "code": "1110400",
    "coordinates": [
      139.666282061304,
      35.9270449889109
    ]
  },
  "さいたま中央区": {
    "code": "1110500",
    "coordinates": [
      139.624674270641,
      35.8813323593169
    ]
  },
  "さいたま桜区": {
    "code": "1110600",
    "coordinates": [
      139.607305818643,
      35.8601332171878
    ]
  },
  "さいたま浦和区": {
    "code": "1110700",
    "coordinates": [
      139.65323587611,
      35.8732399366567
    ]
  },
  "さいたま南区": {
    "code": "1110800",
    "coordinates": [
      139.66038024152,
      35.8461401367544
    ]
  },
  "さいたま緑区": {
    "code": "1110900",
    "coordinates": [
      139.701256872393,
      35.8844831609803
    ]
  },
  "さいたま岩槻区": {
    "code": "1111000",
    "coordinates": [
      139.713215708505,
      35.9444220680665
    ]
  },
  "川越市": {
    "code": "1120100",
    "coordinates": [
      139.479234923418,
      35.9114975361583
    ]
  },
  "熊谷市": {
    "code": "1120200",
    "coordinates": [
      139.37255013775,
      36.1602851531293
    ]
  },
  "川口市": {
    "code": "1120300",
    "coordinates": [
      139.733052972984,
      35.8331419618841
    ]
  },
  "行田市": {
    "code": "1120600",
    "coordinates": [
      139.469910842911,
      36.1480178708165
    ]
  },
  "秩父市": {
    "code": "1120700",
    "coordinates": [
      138.933458324237,
      35.9596639290704
    ]
  },
  "所沢市": {
    "code": "1120800",
    "coordinates": [
      139.457874568421,
      35.7994622102322
    ]
  },
  "飯能市": {
    "code": "1120900",
    "coordinates": [
      139.220094457339,
      35.8881804852604
    ]
  },
  "加須市": {
    "code": "1121000",
    "coordinates": [
      139.620023412067,
      36.1366048440456
    ]
  },
  "本庄市": {
    "code": "1121100",
    "coordinates": [
      139.146257255268,
      36.1992567150887
    ]
  },
  "東松山市": {
    "code": "1121200",
    "coordinates": [
      139.391371673668,
      36.0318375583416
    ]
  },
  "春日部市": {
    "code": "1121400",
    "coordinates": [
      139.775729268806,
      35.9827020434683
    ]
  },
  "狭山市": {
    "code": "1121500",
    "coordinates": [
      139.420778912368,
      35.8588259481564
    ]
  },
  "羽生市": {
    "code": "1121600",
    "coordinates": [
      139.553375895565,
      36.1725100805109
    ]
  },
  "鴻巣市": {
    "code": "1121700",
    "coordinates": [
      139.503477273812,
      36.0788608763931
    ]
  },
  "深谷市": {
    "code": "1121800",
    "coordinates": [
      139.265305526421,
      36.180381428952
    ]
  },
  "上尾市": {
    "code": "1121900",
    "coordinates": [
      139.581582160761,
      35.970434194132
    ]
  },
  "草加市": {
    "code": "1122100",
    "coordinates": [
      139.802154861688,
      35.8386889080725
    ]
  },
  "越谷市": {
    "code": "1122200",
    "coordinates": [
      139.790219944164,
      35.9010848978995
    ]
  },
  "蕨市": {
    "code": "1122300",
    "coordinates": [
      139.686111947159,
      35.8244485644784
    ]
  },
  "戸田市": {
    "code": "1122400",
    "coordinates": [
      139.656977801961,
      35.8147710484983
    ]
  },
  "入間市": {
    "code": "1122500",
    "coordinates": [
      139.367559017502,
      35.8180103604886
    ]
  },
  "朝霞市": {
    "code": "1122700",
    "coordinates": [
      139.597313181927,
      35.8091869906653
    ]
  },
  "志木市": {
    "code": "1122800",
    "coordinates": [
      139.583459093415,
      35.836990200603
    ]
  },
  "和光市": {
    "code": "1122900",
    "coordinates": [
      139.619810691651,
      35.7893474894003
    ]
  },
  "新座市": {
    "code": "1123000",
    "coordinates": [
      139.55961674243,
      35.789427845598
    ]
  },
  "桶川市": {
    "code": "1123100",
    "coordinates": [
      139.549985274119,
      36.0030309477592
    ]
  },
  "久喜市": {
    "code": "1123200",
    "coordinates": [
      139.64950492532,
      36.0770807984126
    ]
  },
  "北本市": {
    "code": "1123300",
    "coordinates": [
      139.530424418878,
      36.0261519996258
    ]
  },
  "八潮市": {
    "code": "1123400",
    "coordinates": [
      139.839410797839,
      35.8193309886406
    ]
  },
  "富士見市": {
    "code": "1123500",
    "coordinates": [
      139.554470338205,
      35.8544762913645
    ]
  },
  "三郷市": {
    "code": "1123700",
    "coordinates": [
      139.871842382173,
      35.8322152981637
    ]
  },
  "蓮田市": {
    "code": "1123800",
    "coordinates": [
      139.649805235701,
      36.0014704774831
    ]
  },
  "坂戸市": {
    "code": "1123900",
    "coordinates": [
      139.400237550958,
      35.9635571405357
    ]
  },
  "幸手市": {
    "code": "1124000",
    "coordinates": [
      139.740209369461,
      36.0708108599591
    ]
  },
  "鶴ヶ島市": {
    "code": "1124100",
    "coordinates": [
      139.394552306051,
      35.9357115962299
    ]
  },
  "日高市": {
    "code": "1124200",
    "coordinates": [
      139.339278284307,
      35.8964852175943
    ]
  },
  "吉川市": {
    "code": "1124300",
    "coordinates": [
      139.860888733736,
      35.8951279236667
    ]
  },
  "ふじみ野市": {
    "code": "1124500",
    "coordinates": [
      139.515227519036,
      35.8650548596399
    ]
  },
  "白岡市": {
    "code": "1124600",
    "coordinates": [
      139.667583027975,
      36.0241508153619
    ]
  },
  "伊奈町": {
    "code": "1130100",
    "coordinates": [
      139.620027039594,
      35.9978464481038
    ]
  },
  "埼玉三芳町": {
    "code": "1132400",
    "coordinates": [
      139.515828532785,
      35.8329081426248
    ]
  },
  "毛呂山町": {
    "code": "1132600",
    "coordinates": [
      139.30733402249,
      35.9350123088031
    ]
  },
  "越生町": {
    "code": "1132700",
    "coordinates": [
      139.266056193348,
      35.9606065026502
    ]
  },
  "滑川町": {
    "code": "1134100",
    "coordinates": [
      139.355951304949,
      36.0703899217117
    ]
  },
  "嵐山町": {
    "code": "1134200",
    "coordinates": [
      139.314884907925,
      36.0564304164402
    ]
  },
  "小川町": {
    "code": "1134300",
    "coordinates": [
      139.248802323727,
      36.054746538263
    ]
  },
  "川島町": {
    "code": "1134600",
    "coordinates": [
      139.484050273155,
      35.9839961190934
    ]
  },
  "吉見町": {
    "code": "1134700",
    "coordinates": [
      139.455285587814,
      36.0443197266546
    ]
  },
  "鳩山町": {
    "code": "1134800",
    "coordinates": [
      139.333847614966,
      35.9877617197338
    ]
  },
  "ときがわ町": {
    "code": "1134900",
    "coordinates": [
      139.241348202212,
      35.9989379869468
    ]
  },
  "横瀬町": {
    "code": "1136100",
    "coordinates": [
      139.129051745238,
      35.9688536757466
    ]
  },
  "皆野町": {
    "code": "1136200",
    "coordinates": [
      139.090881697847,
      36.0784444512791
    ]
  },
  "長瀞町": {
    "code": "1136300",
    "coordinates": [
      139.116844552076,
      36.1175191906133
    ]
  },
  "小鹿野町": {
    "code": "1136500",
    "coordinates": [
      138.929225975245,
      36.0215498322994
    ]
  },
  "東秩父村": {
    "code": "1136900",
    "coordinates": [
      139.182429280977,
      36.046245778614
    ]
  },
  "埼玉美里町": {
    "code": "1138100",
    "coordinates": [
      139.173622459768,
      36.1717409640458
    ]
  },
  "埼玉神川町": {
    "code": "1138300",
    "coordinates": [
      139.057684980013,
      36.1608778188356
    ]
  },
  "上里町": {
    "code": "1138500",
    "coordinates": [
      139.135060029186,
      36.2506193662912
    ]
  },
  "寄居町": {
    "code": "1140800",
    "coordinates": [
      139.202804539607,
      36.1113236557739
    ]
  },
  "宮代町": {
    "code": "1144200",
    "coordinates": [
      139.716617231586,
      36.0226534817028
    ]
  },
  "杉戸町": {
    "code": "1146400",
    "coordinates": [
      139.754700591509,
      36.0341053539096
    ]
  },
  "松伏町": {
    "code": "1146500",
    "coordinates": [
      139.825542664674,
      35.9364131931304
    ]
  },
  "千葉中央区": {
    "code": "1210100",
    "coordinates": [
      140.129400628164,
      35.5843504117515
    ]
  },
  "千葉花見川区": {
    "code": "1210200",
    "coordinates": [
      140.091405652307,
      35.6763585663367
    ]
  },
  "千葉稲毛区": {
    "code": "1210300",
    "coordinates": [
      140.113111848043,
      35.6486029599293
    ]
  },
  "千葉若葉区": {
    "code": "1210400",
    "coordinates": [
      140.205065495207,
      35.6104013267621
    ]
  },
  "千葉緑区": {
    "code": "1210500",
    "coordinates": [
      140.229466178832,
      35.5445793109736
    ]
  },
  "千葉美浜区": {
    "code": "1210600",
    "coordinates": [
      140.061597801861,
      35.6335518141555
    ]
  },
  "銚子市": {
    "code": "1220200",
    "coordinates": [
      140.775512332885,
      35.7430378919702
    ]
  },
  "市川市": {
    "code": "1220300",
    "coordinates": [
      139.932935095437,
      35.7186497654154
    ]
  },
  "船橋市": {
    "code": "1220400",
    "coordinates": [
      140.018868798748,
      35.7264428848685
    ]
  },
  "館山市": {
    "code": "1220500",
    "coordinates": [
      139.861622328511,
      34.9715655695901
    ]
  },
  "木更津市": {
    "code": "1220600",
    "coordinates": [
      139.987449715208,
      35.3698191948684
    ]
  },
  "松戸市": {
    "code": "1220700",
    "coordinates": [
      139.92906969409,
      35.7957433597401
    ]
  },
  "野田市": {
    "code": "1220800",
    "coordinates": [
      139.862144401443,
      35.9851657972204
    ]
  },
  "茂原市": {
    "code": "1221000",
    "coordinates": [
      140.301622840926,
      35.44981369313
    ]
  },
  "成田市": {
    "code": "1221100",
    "coordinates": [
      140.357558100258,
      35.8154763159835
    ]
  },
  "千葉佐倉市": {
    "code": "1221200",
    "coordinates": [
      140.21325506364,
      35.7061905854597
    ]
  },
  "東金市": {
    "code": "1221300",
    "coordinates": [
      140.367059542906,
      35.5651769819763
    ]
  },
  "旭市": {
    "code": "1221500",
    "coordinates": [
      140.655481784432,
      35.7344393782694
    ]
  },
  "習志野市": {
    "code": "1221600",
    "coordinates": [
      140.034174521423,
      35.6809171568021
    ]
  },
  "柏市": {
    "code": "1221700",
    "coordinates": [
      139.993853126373,
      35.8601759792331
    ]
  },
  "勝浦市": {
    "code": "1221800",
    "coordinates": [
      140.267573583579,
      35.1751239734005
    ]
  },
  "市原市": {
    "code": "1221900",
    "coordinates": [
      140.137240509746,
      35.4248457499104
    ]
  },
  "流山市": {
    "code": "1222000",
    "coordinates": [
      139.914090401644,
      35.8733731761798
    ]
  },
  "八千代市": {
    "code": "1222100",
    "coordinates": [
      140.10501268863,
      35.7392120178971
    ]
  },
  "我孫子市": {
    "code": "1222200",
    "coordinates": [
      140.065091627162,
      35.8694708253727
    ]
  },
  "鴨川市": {
    "code": "1222300",
    "coordinates": [
      140.077677359715,
      35.129824802736
    ]
  },
  "鎌ケ谷市": {
    "code": "1222400",
    "coordinates": [
      139.998820749649,
      35.7728985888916
    ]
  },
  "君津市": {
    "code": "1222500",
    "coordinates": [
      140.024051325363,
      35.2630378743693
    ]
  },
  "富津市": {
    "code": "1222600",
    "coordinates": [
      139.900537297796,
      35.2259674381448
    ]
  },
  "浦安市": {
    "code": "1222700",
    "coordinates": [
      139.901906404781,
      35.642501342904
    ]
  },
  "四街道市": {
    "code": "1222800",
    "coordinates": [
      140.17910909174,
      35.6716789589137
    ]
  },
  "袖ケ浦市": {
    "code": "1222900",
    "coordinates": [
      140.017699960893,
      35.4164197318623
    ]
  },
  "八街市": {
    "code": "1223000",
    "coordinates": [
      140.296854416422,
      35.6424157653048
    ]
  },
  "印西市": {
    "code": "1223100",
    "coordinates": [
      140.180334912034,
      35.7979613902424
    ]
  },
  "白井市": {
    "code": "1223200",
    "coordinates": [
      140.060038587979,
      35.8016453432488
    ]
  },
  "富里市": {
    "code": "1223300",
    "coordinates": [
      140.340795940156,
      35.7202405385078
    ]
  },
  "南房総市": {
    "code": "1223400",
    "coordinates": [
      139.92845803641,
      35.0365794032473
    ]
  },
  "匝瑳市": {
    "code": "1223500",
    "coordinates": [
      140.553656412364,
      35.6998383289911
    ]
  },
  "香取市": {
    "code": "1223600",
    "coordinates": [
      140.539935394045,
      35.8534762733924
    ]
  },
  "山武市": {
    "code": "1223700",
    "coordinates": [
      140.420784564735,
      35.6239778933733
    ]
  },
  "いすみ市": {
    "code": "1223800",
    "coordinates": [
      140.343343707923,
      35.2777725602695
    ]
  },
  "大網白里市": {
    "code": "1223900",
    "coordinates": [
      140.342937788004,
      35.5127956627383
    ]
  },
  "酒々井町": {
    "code": "1232200",
    "coordinates": [
      140.278033590072,
      35.7292832376354
    ]
  },
  "栄町": {
    "code": "1232900",
    "coordinates": [
      140.242660770923,
      35.8440390452173
    ]
  },
  "神崎町": {
    "code": "1234200",
    "coordinates": [
      140.408404809789,
      35.8862415565997
    ]
  },
  "多古町": {
    "code": "1234700",
    "coordinates": [
      140.471106138652,
      35.7570706714024
    ]
  },
  "東庄町": {
    "code": "1234900",
    "coordinates": [
      140.673166810108,
      35.8136526735769
    ]
  },
  "九十九里町": {
    "code": "1240300",
    "coordinates": [
      140.433632923845,
      35.5333505440614
    ]
  },
  "芝山町": {
    "code": "1240900",
    "coordinates": [
      140.410197811467,
      35.7215466061295
    ]
  },
  "横芝光町": {
    "code": "1241000",
    "coordinates": [
      140.494739996122,
      35.6608270722679
    ]
  },
  "一宮町": {
    "code": "1242100",
    "coordinates": [
      140.370856498402,
      35.3615192252449
    ]
  },
  "睦沢町": {
    "code": "1242200",
    "coordinates": [
      140.302245036734,
      35.3528047677858
    ]
  },
  "長生村": {
    "code": "1242300",
    "coordinates": [
      140.357936491787,
      35.4070841397708
    ]
  },
  "白子町": {
    "code": "1242400",
    "coordinates": [
      140.379616356644,
      35.4499919338845
    ]
  },
  "長柄町": {
    "code": "1242600",
    "coordinates": [
      140.213381947249,
      35.445897999654
    ]
  },
  "長南町": {
    "code": "1242700",
    "coordinates": [
      140.243004836665,
      35.3751974546919
    ]
  },
  "大多喜町": {
    "code": "1244100",
    "coordinates": [
      140.213846131314,
      35.2428872445125
    ]
  },
  "御宿町": {
    "code": "1244300",
    "coordinates": [
      140.340712811496,
      35.1964117257265
    ]
  },
  "鋸南町": {
    "code": "1246300",
    "coordinates": [
      139.871911092647,
      35.132417252729
    ]
  },
  "東京千代田区": {
    "code": "1310100",
    "coordinates": [
      139.75468090087,
      35.6876392788568
    ]
  },
  "東京中央区": {
    "code": "1310200",
    "coordinates": [
      139.777038843073,
      35.6699350274536
    ]
  },
  "東京港区": {
    "code": "1310300",
    "coordinates": [
      139.739745714087,
      35.6514915415365
    ]
  },
  "東京新宿区": {
    "code": "1310400",
    "coordinates": [
      139.70897161722,
      35.7010688234628
    ]
  },
  "東京文京区": {
    "code": "1310500",
    "coordinates": [
      139.747281459894,
      35.7174565955148
    ]
  },
  "東京台東区": {
    "code": "1310600",
    "coordinates": [
      139.785983768894,
      35.7154826954733
    ]
  },
  "東京墨田区": {
    "code": "1310700",
    "coordinates": [
      139.815471771315,
      35.712210183057
    ]
  },
  "東京江東区": {
    "code": "1310800",
    "coordinates": [
      139.814285083433,
      35.659398139362
    ]
  },
  "東京品川区": {
    "code": "1310900",
    "coordinates": [
      139.733546716976,
      35.6097042048665
    ]
  },
  "東京目黒区": {
    "code": "1311000",
    "coordinates": [
      139.688230178896,
      35.6299735193727
    ]
  },
  "東京大田区": {
    "code": "1311100",
    "coordinates": [
      139.734628837653,
      35.5664834015806
    ]
  },
  "東京世田谷区": {
    "code": "1311200",
    "coordinates": [
      139.635070261666,
      35.6397106419675
    ]
  },
  "東京渋谷区": {
    "code": "1311300",
    "coordinates": [
      139.696273592214,
      35.66788396563
    ]
  },
  "東京中野区": {
    "code": "1311400",
    "coordinates": [
      139.662472476908,
      35.7109184860206
    ]
  },
  "東京杉並区": {
    "code": "1311500",
    "coordinates": [
      139.625420769983,
      35.6968113825389
    ]
  },
  "東京豊島区": {
    "code": "1311600",
    "coordinates": [
      139.711630294362,
      35.7315291622553
    ]
  },
  "東京北区": {
    "code": "1311700",
    "coordinates": [
      139.728917423578,
      35.7658986877648
    ]
  },
  "東京荒川区": {
    "code": "1311800",
    "coordinates": [
      139.781286777436,
      35.7399318475965
    ]
  },
  "東京板橋区": {
    "code": "1311900",
    "coordinates": [
      139.67646110659,
      35.7725755893317
    ]
  },
  "東京練馬区": {
    "code": "1312000",
    "coordinates": [
      139.617667962841,
      35.7478491024964
    ]
  },
  "東京足立区": {
    "code": "1312100",
    "coordinates": [
      139.795078511129,
      35.7788081380226
    ]
  },
  "東京葛飾区": {
    "code": "1312200",
    "coordinates": [
      139.85566688911,
      35.7532486105695
    ]
  },
  "東京江戸川区": {
    "code": "1312300",
    "coordinates": [
      139.875666110096,
      35.6924206376333
    ]
  },
  "八王子市": {
    "code": "1320100",
    "coordinates": [
      139.287647963721,
      35.6598391349798
    ]
  },
  "立川市": {
    "code": "1320200",
    "coordinates": [
      139.40452242204,
      35.7144765633296
    ]
  },
  "武蔵野市": {
    "code": "1320300",
    "coordinates": [
      139.561617509894,
      35.7081388485621
    ]
  },
  "三鷹市": {
    "code": "1320400",
    "coordinates": [
      139.560383390073,
      35.6846673898315
    ]
  },
  "青梅市": {
    "code": "1320500",
    "coordinates": [
      139.24196877379,
      35.8035882628657
    ]
  },
  "東京府中市": {
    "code": "1320600",
    "coordinates": [
      139.483154511823,
      35.6704986106064
    ]
  },
  "昭島市": {
    "code": "1320700",
    "coordinates": [
      139.364170031031,
      35.707826854924
    ]
  },
  "調布市": {
    "code": "1320800",
    "coordinates": [
      139.552182118842,
      35.6576853156909
    ]
  },
  "町田市": {
    "code": "1320900",
    "coordinates": [
      139.429972516332,
      35.5762688945685
    ]
  },
  "小金井市": {
    "code": "1321000",
    "coordinates": [
      139.511035815632,
      35.7010884418568
    ]
  },
  "小平市": {
    "code": "1321100",
    "coordinates": [
      139.481718122153,
      35.7273869543006
    ]
  },
  "日野市": {
    "code": "1321200",
    "coordinates": [
      139.398643242427,
      35.6633337072891
    ]
  },
  "東村山市": {
    "code": "1321300",
    "coordinates": [
      139.472433462872,
      35.7592321511942
    ]
  },
  "国分寺市": {
    "code": "1321400",
    "coordinates": [
      139.461242591299,
      35.705523105311
    ]
  },
  "国立市": {
    "code": "1321500",
    "coordinates": [
      139.438768312474,
      35.6863417475434
    ]
  },
  "福生市": {
    "code": "1321800",
    "coordinates": [
      139.336367820525,
      35.7358160734892
    ]
  },
  "狛江市": {
    "code": "1321900",
    "coordinates": [
      139.577221847258,
      35.6348723098134
    ]
  },
  "東大和市": {
    "code": "1322000",
    "coordinates": [
      139.426594742766,
      35.7503128017977
    ]
  },
  "清瀬市": {
    "code": "1322100",
    "coordinates": [
      139.522211103858,
      35.7814445251626
    ]
  },
  "東久留米市": {
    "code": "1322200",
    "coordinates": [
      139.519859153722,
      35.7538768190084
    ]
  },
  "武蔵村山市": {
    "code": "1322300",
    "coordinates": [
      139.383897951202,
      35.7503707172655
    ]
  },
  "多摩市": {
    "code": "1322400",
    "coordinates": [
      139.43975298867,
      35.6305400765888
    ]
  },
  "稲城市": {
    "code": "1322500",
    "coordinates": [
      139.492903321375,
      35.6329093672136
    ]
  },
  "羽村市": {
    "code": "1322700",
    "coordinates": [
      139.31540625684,
      35.7640217728473
    ]
  },
  "あきる野市": {
    "code": "1322800",
    "coordinates": [
      139.225238268192,
      35.7328550187435
    ]
  },
  "西東京市": {
    "code": "1322900",
    "coordinates": [
      139.546230680003,
      35.734858191023
    ]
  },
  "瑞穂町": {
    "code": "1330300",
    "coordinates": [
      139.349270898852,
      35.7742599530027
    ]
  },
  "日の出町": {
    "code": "1330500",
    "coordinates": [
      139.226373107048,
      35.7587193007001
    ]
  },
  "檜原村": {
    "code": "1330700",
    "coordinates": [
      139.104489781874,
      35.725007612671
    ]
  },
  "奥多摩町": {
    "code": "1330800",
    "coordinates": [
      139.055466657764,
      35.8225728563092
    ]
  },
  "伊豆大島町": {
    "code": "1336100",
    "coordinates": [
      139.398860680118,
      34.737504801755
    ]
  },
  "東京利島村": {
    "code": "1336200",
    "coordinates": [
      139.279258675522,
      34.5226397917672
    ]
  },
  "新島村": {
    "code": "1336300",
    "coordinates": [
      139.259379898653,
      34.3712720686983
    ]
  },
  "神津島村": {
    "code": "1336400",
    "coordinates": [
      139.14756016141,
      34.2141240116488
    ]
  },
  "三宅村": {
    "code": "1338100",
    "coordinates": [
      139.521111977334,
      34.0848936361086
    ]
  },
  "御蔵島村": {
    "code": "1338200",
    "coordinates": [
      139.603048257413,
      33.8746669545781
    ]
  },
  "八丈町": {
    "code": "1340100",
    "coordinates": [
      139.793260113859,
      33.1072082325413
    ]
  },
  "青ヶ島村": {
    "code": "1340200",
    "coordinates": [
      139.766454702728,
      32.4573505570155
    ]
  },
  "小笠原村": {
    "code": "1342100",
    "coordinates": [
      142.005446132563,
      26.4684789419563
    ]
  },
  "横浜鶴見区": {
    "code": "1410100",
    "coordinates": [
      139.67808343148,
      35.5001070224152
    ]
  },
  "横浜神奈川区": {
    "code": "1410200",
    "coordinates": [
      139.618752282755,
      35.4856440194028
    ]
  },
  "横浜西区": {
    "code": "1410300",
    "coordinates": [
      139.618588513172,
      35.4567706527035
    ]
  },
  "横浜中区": {
    "code": "1410400",
    "coordinates": [
      139.657346844295,
      35.4293621694563
    ]
  },
  "横浜南区": {
    "code": "1410500",
    "coordinates": [
      139.601910853552,
      35.4284801008908
    ]
  },
  "横浜保土ケ谷区": {
    "code": "1410600",
    "coordinates": [
      139.578251581614,
      35.4611381188558
    ]
  },
  "横浜磯子区": {
    "code": "1410700",
    "coordinates": [
      139.61407017446,
      35.3923829808145
    ]
  },
  "横浜金沢区": {
    "code": "1410800",
    "coordinates": [
      139.623235313514,
      35.3486613743958
    ]
  },
  "横浜港北区": {
    "code": "1410900",
    "coordinates": [
      139.622869896649,
      35.528041276578
    ]
  },
  "横浜戸塚区": {
    "code": "1411000",
    "coordinates": [
      139.531229646544,
      35.4032794408045
    ]
  },
  "横浜港南区": {
    "code": "1411100",
    "coordinates": [
      139.578260525382,
      35.3941769818282
    ]
  },
  "横浜旭区": {
    "code": "1411200",
    "coordinates": [
      139.527468719933,
      35.4765643259736
    ]
  },
  "横浜緑区": {
    "code": "1411300",
    "coordinates": [
      139.526229068129,
      35.5155021447915
    ]
  },
  "横浜瀬谷区": {
    "code": "1411400",
    "coordinates": [
      139.488004155864,
      35.4688547617203
    ]
  },
  "横浜栄区": {
    "code": "1411500",
    "coordinates": [
      139.556817335321,
      35.3619478331885
    ]
  },
  "横浜泉区": {
    "code": "1411600",
    "coordinates": [
      139.499284677839,
      35.4207021455924
    ]
  },
  "横浜青葉区": {
    "code": "1411700",
    "coordinates": [
      139.524023460755,
      35.5589530562327
    ]
  },
  "横浜都筑区": {
    "code": "1411800",
    "coordinates": [
      139.57764660611,
      35.5401744442027
    ]
  },
  "川崎川崎区": {
    "code": "1413100",
    "coordinates": [
      139.739394641529,
      35.514030949813
    ]
  },
  "川崎幸区": {
    "code": "1413200",
    "coordinates": [
      139.680026985874,
      35.5451673047988
    ]
  },
  "川崎中原区": {
    "code": "1413300",
    "coordinates": [
      139.652906365273,
      35.5746787729811
    ]
  },
  "川崎高津区": {
    "code": "1413400",
    "coordinates": [
      139.615285496517,
      35.5922241822697
    ]
  },
  "川崎多摩区": {
    "code": "1413500",
    "coordinates": [
      139.550596746768,
      35.61851237383
    ]
  },
  "川崎宮前区": {
    "code": "1413600",
    "coordinates": [
      139.578397994542,
      35.5861776618586
    ]
  },
  "川崎麻生区": {
    "code": "1413700",
    "coordinates": [
      139.498709704491,
      35.5990972791007
    ]
  },
  "相模原緑区": {
    "code": "1415100",
    "coordinates": [
      139.195872082357,
      35.5701489928259
    ]
  },
  "相模原中央区": {
    "code": "1415200",
    "coordinates": [
      139.366687796647,
      35.5625128423389
    ]
  },
  "相模原南区": {
    "code": "1415300",
    "coordinates": [
      139.406681314763,
      35.5267674342239
    ]
  },
  "横須賀市": {
    "code": "1420100",
    "coordinates": [
      139.660349121916,
      35.2498434169831
    ]
  },
  "平塚市": {
    "code": "1420300",
    "coordinates": [
      139.320122292506,
      35.3509919522746
    ]
  },
  "鎌倉市": {
    "code": "1420400",
    "coordinates": [
      139.537972806972,
      35.3300034259596
    ]
  },
  "藤沢市": {
    "code": "1420500",
    "coordinates": [
      139.458980394994,
      35.3681463258724
    ]
  },
  "小田原市": {
    "code": "1420600",
    "coordinates": [
      139.144143466505,
      35.2629508577492
    ]
  },
  "茅ヶ崎市": {
    "code": "1420700",
    "coordinates": [
      139.409571444876,
      35.3442929349976
    ]
  },
  "逗子市": {
    "code": "1420800",
    "coordinates": [
      139.591247262824,
      35.2990073456312
    ]
  },
  "三浦市": {
    "code": "1421000",
    "coordinates": [
      139.640912666956,
      35.1674569447704
    ]
  },
  "秦野市": {
    "code": "1421100",
    "coordinates": [
      139.203034856484,
      35.400152683799
    ]
  },
  "厚木市": {
    "code": "1421200",
    "coordinates": [
      139.327061866654,
      35.4651364409069
    ]
  },
  "大和市": {
    "code": "1421300",
    "coordinates": [
      139.456513365685,
      35.473787440919
    ]
  },
  "伊勢原市": {
    "code": "1421400",
    "coordinates": [
      139.29202442104,
      35.4101149612172
    ]
  },
  "海老名市": {
    "code": "1421500",
    "coordinates": [
      139.393059884139,
      35.4376208602895
    ]
  },
  "座間市": {
    "code": "1421600",
    "coordinates": [
      139.409237434956,
      35.487526053812
    ]
  },
  "南足柄市": {
    "code": "1421700",
    "coordinates": [
      139.070150575982,
      35.3099161599533
    ]
  },
  "綾瀬市": {
    "code": "1421800",
    "coordinates": [
      139.430883226169,
      35.439959487803
    ]
  },
  "葉山町": {
    "code": "1430100",
    "coordinates": [
      139.600588030512,
      35.2712560156872
    ]
  },
  "寒川町": {
    "code": "1432100",
    "coordinates": [
      139.385584906894,
      35.3755813844812
    ]
  },
  "大磯町": {
    "code": "1434100",
    "coordinates": [
      139.288651965647,
      35.3167056778766
    ]
  },
  "二宮町": {
    "code": "1434200",
    "coordinates": [
      139.249547184389,
      35.3078823870787
    ]
  },
  "中井町": {
    "code": "1436100",
    "coordinates": [
      139.215173227803,
      35.3360837247785
    ]
  },
  "神奈川大井町": {
    "code": "1436200",
    "coordinates": [
      139.16535249196,
      35.3350248256861
    ]
  },
  "松田町": {
    "code": "1436300",
    "coordinates": [
      139.130822192783,
      35.3921697006304
    ]
  },
  "山北町": {
    "code": "1436400",
    "coordinates": [
      139.045393931269,
      35.4247822858352
    ]
  },
  "開成町": {
    "code": "1436600",
    "coordinates": [
      139.124907908659,
      35.3350162075324
    ]
  },
  "箱根町": {
    "code": "1438200",
    "coordinates": [
      139.031410322426,
      35.2322659956221
    ]
  },
  "真鶴町": {
    "code": "1438300",
    "coordinates": [
      139.133420345176,
      35.1655675979329
    ]
  },
  "湯河原町": {
    "code": "1438400",
    "coordinates": [
      139.079012769572,
      35.1662360245608
    ]
  },
  "愛川町": {
    "code": "1440100",
    "coordinates": [
      139.29905757268,
      35.5288352078574
    ]
  },
  "清川村": {
    "code": "1440200",
    "coordinates": [
      139.228963731045,
      35.4870692793458
    ]
  },
  "新潟北区": {
    "code": "1510100",
    "coordinates": [
      139.198806868517,
      37.923170087242
    ]
  },
  "新潟東区": {
    "code": "1510200",
    "coordinates": [
      139.106051448107,
      37.9279364805651
    ]
  },
  "新潟中央区": {
    "code": "1510300",
    "coordinates": [
      139.048453033799,
      37.9040315394522
    ]
  },
  "新潟江南区": {
    "code": "1510400",
    "coordinates": [
      139.105890862543,
      37.863097629413
    ]
  },
  "新潟秋葉区": {
    "code": "1510500",
    "coordinates": [
      139.111448973247,
      37.7859893603118
    ]
  },
  "新潟南区": {
    "code": "1510600",
    "coordinates": [
      139.022593368313,
      37.7684032675076
    ]
  },
  "新潟西区": {
    "code": "1510700",
    "coordinates": [
      138.951629468841,
      37.8481025681968
    ]
  },
  "新潟西蒲区": {
    "code": "1510800",
    "coordinates": [
      138.896287938032,
      37.7598504197898
    ]
  },
  "長岡市": {
    "code": "1520200",
    "coordinates": [
      138.856964661456,
      37.43437868498
    ]
  },
  "三条市": {
    "code": "1520400",
    "coordinates": [
      139.089818962645,
      37.5406857322198
    ]
  },
  "柏崎市": {
    "code": "1520500",
    "coordinates": [
      138.599269968613,
      37.3236651536383
    ]
  },
  "新発田市": {
    "code": "1520600",
    "coordinates": [
      139.42784849935,
      37.9073295696735
    ]
  },
  "小千谷市": {
    "code": "1520800",
    "coordinates": [
      138.800540074396,
      37.2913007671628
    ]
  },
  "加茂市": {
    "code": "1520900",
    "coordinates": [
      139.110674134302,
      37.6220972781157
    ]
  },
  "十日町市": {
    "code": "1521000",
    "coordinates": [
      138.702012843222,
      37.0908000625539
    ]
  },
  "見附市": {
    "code": "1521100",
    "coordinates": [
      138.931894742648,
      37.5277737632995
    ]
  },
  "村上市": {
    "code": "1521200",
    "coordinates": [
      139.617321409599,
      38.3102337162635
    ]
  },
  "燕市": {
    "code": "1521300",
    "coordinates": [
      138.886186943723,
      37.6654153763082
    ]
  },
  "糸魚川市": {
    "code": "1521600",
    "coordinates": [
      137.894015735474,
      36.9662643277104
    ]
  },
  "妙高市": {
    "code": "1521700",
    "coordinates": [
      138.187650254614,
      36.9322796817926
    ]
  },
  "五泉市": {
    "code": "1521800",
    "coordinates": [
      139.226335850948,
      37.6564361063908
    ]
  },
  "上越市": {
    "code": "1522200",
    "coordinates": [
      138.337446808397,
      37.1201958998574
    ]
  },
  "阿賀野市": {
    "code": "1522300",
    "coordinates": [
      139.259131886562,
      37.8142034505987
    ]
  },
  "佐渡市": {
    "code": "1522400",
    "coordinates": [
      138.389834987216,
      38.0443952706501
    ]
  },
  "魚沼市": {
    "code": "1522500",
    "coordinates": [
      139.097942277655,
      37.2208809736947
    ]
  },
  "南魚沼市": {
    "code": "1522600",
    "coordinates": [
      138.937341112689,
      37.0558721922435
    ]
  },
  "胎内市": {
    "code": "1522700",
    "coordinates": [
      139.480522654976,
      38.005876720385
    ]
  },
  "聖籠町": {
    "code": "1530700",
    "coordinates": [
      139.266224343635,
      37.983456599362
    ]
  },
  "弥彦村": {
    "code": "1534200",
    "coordinates": [
      138.837510274718,
      37.6907507280925
    ]
  },
  "田上町": {
    "code": "1536100",
    "coordinates": [
      139.070331780928,
      37.7011465938658
    ]
  },
  "阿賀町": {
    "code": "1538500",
    "coordinates": [
      139.46410138774,
      37.665046212632
    ]
  },
  "出雲崎町": {
    "code": "1540500",
    "coordinates": [
      138.714721603198,
      37.5265542768031
    ]
  },
  "湯沢町": {
    "code": "1546100",
    "coordinates": [
      138.800658863521,
      36.8557388474654
    ]
  },
  "津南町": {
    "code": "1548200",
    "coordinates": [
      138.641715612125,
      36.9709615437378
    ]
  },
  "刈羽村": {
    "code": "1550400",
    "coordinates": [
      138.640453799865,
      37.4234229385199
    ]
  },
  "関川村": {
    "code": "1558100",
    "coordinates": [
      139.601146454928,
      38.0642706838284
    ]
  },
  "粟島浦村": {
    "code": "1558600",
    "coordinates": [
      139.240331862145,
      38.4626076205301
    ]
  },
  "富山市": {
    "code": "1620100",
    "coordinates": [
      137.299360081631,
      36.5465260304914
    ]
  },
  "高岡市": {
    "code": "1620200",
    "coordinates": [
      136.966479946685,
      36.739993511768
    ]
  },
  "魚津市": {
    "code": "1620400",
    "coordinates": [
      137.491440345438,
      36.7598118636429
    ]
  },
  "氷見市": {
    "code": "1620500",
    "coordinates": [
      136.942837623059,
      36.8649478754854
    ]
  },
  "滑川市": {
    "code": "1620600",
    "coordinates": [
      137.382204563387,
      36.7518215849884
    ]
  },
  "黒部市": {
    "code": "1620700",
    "coordinates": [
      137.616247237192,
      36.7688031361103
    ]
  },
  "砺波市": {
    "code": "1620800",
    "coordinates": [
      136.990689631148,
      36.6186889775988
    ]
  },
  "小矢部市": {
    "code": "1620900",
    "coordinates": [
      136.854825999436,
      36.6623347279545
    ]
  },
  "南砺市": {
    "code": "1621000",
    "coordinates": [
      136.91957342038,
      36.4532378846001
    ]
  },
  "射水市": {
    "code": "1621100",
    "coordinates": [
      137.088223618876,
      36.7237977019353
    ]
  },
  "舟橋村": {
    "code": "1632100",
    "coordinates": [
      137.306565494114,
      36.7008755608325
    ]
  },
  "上市町": {
    "code": "1632200",
    "coordinates": [
      137.476506015211,
      36.6605980243095
    ]
  },
  "立山町": {
    "code": "1632300",
    "coordinates": [
      137.51191068928,
      36.6016103527236
    ]
  },
  "入善町": {
    "code": "1634200",
    "coordinates": [
      137.512309433072,
      36.9119498804273
    ]
  },
  "富山朝日町": {
    "code": "1634300",
    "coordinates": [
      137.650393097585,
      36.8922463682935
    ]
  },
  "金沢市": {
    "code": "1720100",
    "coordinates": [
      136.708187863377,
      36.5193243760075
    ]
  },
  "七尾市": {
    "code": "1720200",
    "coordinates": [
      136.928615102667,
      37.0784409544337
    ]
  },
  "小松市": {
    "code": "1720300",
    "coordinates": [
      136.498403889485,
      36.3110675657341
    ]
  },
  "輪島市": {
    "code": "1720400",
    "coordinates": [
      136.887565264009,
      37.3375524135161
    ]
  },
  "珠洲市": {
    "code": "1720500",
    "coordinates": [
      137.225857565285,
      37.4553346478706
    ]
  },
  "加賀市": {
    "code": "1720600",
    "coordinates": [
      136.379616637696,
      36.2550309986281
    ]
  },
  "羽咋市": {
    "code": "1720700",
    "coordinates": [
      136.816246578006,
      36.9113668520004
    ]
  },
  "かほく市": {
    "code": "1720900",
    "coordinates": [
      136.732721641108,
      36.7436581358429
    ]
  },
  "白山市": {
    "code": "1721000",
    "coordinates": [
      136.669653315478,
      36.2755881915283
    ]
  },
  "能美市": {
    "code": "1721100",
    "coordinates": [
      136.544552647945,
      36.4359350609582
    ]
  },
  "野々市市": {
    "code": "1721200",
    "coordinates": [
      136.605604857988,
      36.5252029233456
    ]
  },
  "川北町": {
    "code": "1732400",
    "coordinates": [
      136.536601316271,
      36.4692204267958
    ]
  },
  "津幡町": {
    "code": "1736100",
    "coordinates": [
      136.768839651052,
      36.6928552627957
    ]
  },
  "内灘町": {
    "code": "1736500",
    "coordinates": [
      136.657469177564,
      36.664166050486
    ]
  },
  "志賀町": {
    "code": "1738400",
    "coordinates": [
      136.771498659395,
      37.0891899330096
    ]
  },
  "宝達志水町": {
    "code": "1738600",
    "coordinates": [
      136.803702970851,
      36.8260189599134
    ]
  },
  "中能登町": {
    "code": "1740700",
    "coordinates": [
      136.897549346452,
      36.9710461964252
    ]
  },
  "穴水町": {
    "code": "1746100",
    "coordinates": [
      136.936159824825,
      37.2353045003231
    ]
  },
  "能登町": {
    "code": "1746300",
    "coordinates": [
      137.095272925098,
      37.3300741346517
    ]
  },
  "福井市": {
    "code": "1820100",
    "coordinates": [
      136.218202140917,
      36.0424424739191
    ]
  },
  "敦賀市": {
    "code": "1820200",
    "coordinates": [
      136.080659508604,
      35.6373329106647
    ]
  },
  "小浜市": {
    "code": "1820400",
    "coordinates": [
      135.748123962826,
      35.4688289246454
    ]
  },
  "大野市": {
    "code": "1820500",
    "coordinates": [
      136.616955593255,
      35.9083784996439
    ]
  },
  "勝山市": {
    "code": "1820600",
    "coordinates": [
      136.521767808287,
      36.0844026882891
    ]
  },
  "鯖江市": {
    "code": "1820700",
    "coordinates": [
      136.218340235623,
      35.959322939046
    ]
  },
  "あわら市": {
    "code": "1820800",
    "coordinates": [
      136.251531967129,
      36.2277761286373
    ]
  },
  "越前市": {
    "code": "1820900",
    "coordinates": [
      136.177220770386,
      35.8894677610897
    ]
  },
  "福井坂井市": {
    "code": "1821000",
    "coordinates": [
      136.255432714834,
      36.1640642408363
    ]
  },
  "永平寺町": {
    "code": "1832200",
    "coordinates": [
      136.354952992329,
      36.0779320488053
    ]
  },
  "福井池田町": {
    "code": "1838200",
    "coordinates": [
      136.368104657903,
      35.8684541343403
    ]
  },
  "南越前町": {
    "code": "1840400",
    "coordinates": [
      136.21329884945,
      35.7720918395756
    ]
  },
  "越前町": {
    "code": "1842300",
    "coordinates": [
      136.056369666675,
      35.958983953428
    ]
  },
  "福井美浜町": {
    "code": "1844200",
    "coordinates": [
      135.974426094891,
      35.5969127590344
    ]
  },
  "高浜町": {
    "code": "1848100",
    "coordinates": [
      135.515460256579,
      35.4921541639607
    ]
  },
  "福井おおい町": {
    "code": "1848300",
    "coordinates": [
      135.6328331689,
      35.4147528549627
    ]
  },
  "福井若狭町": {
    "code": "1850100",
    "coordinates": [
      135.880586257406,
      35.5118083998426
    ]
  },
  "甲府市": {
    "code": "1920100",
    "coordinates": [
      138.587705410228,
      35.6972319007952
    ]
  },
  "富士吉田市": {
    "code": "1920200",
    "coordinates": [
      138.793671162,
      35.4391569911318
    ]
  },
  "都留市": {
    "code": "1920400",
    "coordinates": [
      138.914885551385,
      35.5366086627543
    ]
  },
  "山梨市": {
    "code": "1920500",
    "coordinates": [
      138.69741862058,
      35.796915980462
    ]
  },
  "大月市": {
    "code": "1920600",
    "coordinates": [
      138.91991754235,
      35.6428556096998
    ]
  },
  "韮崎市": {
    "code": "1920700",
    "coordinates": [
      138.411994181594,
      35.7177228027137
    ]
  },
  "南アルプス市": {
    "code": "1920800",
    "coordinates": [
      138.354118672882,
      35.6506589773157
    ]
  },
  "山梨北杜市": {
    "code": "1920900",
    "coordinates": [
      138.393746734175,
      35.8371771185809
    ]
  },
  "甲斐市": {
    "code": "1921000",
    "coordinates": [
      138.518442240671,
      35.7170017098834
    ]
  },
  "笛吹市": {
    "code": "1921100",
    "coordinates": [
      138.680662149801,
      35.5995549077046
    ]
  },
  "上野原市": {
    "code": "1921200",
    "coordinates": [
      139.063778314444,
      35.6342390705583
    ]
  },
  "甲州市": {
    "code": "1921300",
    "coordinates": [
      138.791771973881,
      35.7264990503867
    ]
  },
  "中央市": {
    "code": "1921400",
    "coordinates": [
      138.541089817245,
      35.5841890790118
    ]
  },
  "市川三郷町": {
    "code": "1934600",
    "coordinates": [
      138.513220220601,
      35.5324522793135
    ]
  },
  "早川町": {
    "code": "1936400",
    "coordinates": [
      138.309388070703,
      35.4673541069562
    ]
  },
  "身延町": {
    "code": "1936500",
    "coordinates": [
      138.467820999885,
      35.415176177071
    ]
  },
  "山梨南部町": {
    "code": "1936600",
    "coordinates": [
      138.461748909208,
      35.2593178690898
    ]
  },
  "富士川町": {
    "code": "1936800",
    "coordinates": [
      138.402718660513,
      35.5433358435042
    ]
  },
  "昭和町": {
    "code": "1938400",
    "coordinates": [
      138.533397924857,
      35.6279254479803
    ]
  },
  "道志村": {
    "code": "1942200",
    "coordinates": [
      139.01301820364,
      35.5059365709389
    ]
  },
  "西桂町": {
    "code": "1942300",
    "coordinates": [
      138.835711544379,
      35.5309312289231
    ]
  },
  "忍野村": {
    "code": "1942400",
    "coordinates": [
      138.856033911689,
      35.459625186893
    ]
  },
  "山中湖村": {
    "code": "1942500",
    "coordinates": [
      138.877456600888,
      35.423024941844
    ]
  },
  "鳴沢村": {
    "code": "1942900",
    "coordinates": [
      138.698252249547,
      35.4278106022273
    ]
  },
  "富士河口湖町": {
    "code": "1943000",
    "coordinates": [
      138.687925149975,
      35.4870310031149
    ]
  },
  "小菅村": {
    "code": "1944200",
    "coordinates": [
      138.94277065674,
      35.747740535771
    ]
  },
  "丹波山村": {
    "code": "1944300",
    "coordinates": [
      138.905854410281,
      35.8014722092475
    ]
  },
  "長野市": {
    "code": "2020100",
    "coordinates": [
      138.101842730177,
      36.644026807898
    ]
  },
  "松本市": {
    "code": "2020200",
    "coordinates": [
      137.814126622779,
      36.2015490248281
    ]
  },
  "上田市": {
    "code": "2020300",
    "coordinates": [
      138.241851353296,
      36.3835247024268
    ]
  },
  "岡谷市": {
    "code": "2020400",
    "coordinates": [
      138.048042320019,
      36.0791774630167
    ]
  },
  "飯田市": {
    "code": "2020500",
    "coordinates": [
      137.913516131716,
      35.4397914970307
    ]
  },
  "諏訪市": {
    "code": "2020600",
    "coordinates": [
      138.11355839934,
      36.0289989792508
    ]
  },
  "須坂市": {
    "code": "2020700",
    "coordinates": [
      138.348809912586,
      36.6099698545259
    ]
  },
  "小諸市": {
    "code": "2020800",
    "coordinates": [
      138.43502267364,
      36.3390536103043
    ]
  },
  "伊那市": {
    "code": "2020900",
    "coordinates": [
      138.07400689419,
      35.7907915288426
    ]
  },
  "駒ヶ根市": {
    "code": "2021000",
    "coordinates": [
      137.947824042305,
      35.7277065562869
    ]
  },
  "中野市": {
    "code": "2021100",
    "coordinates": [
      138.347162011823,
      36.7694021363535
    ]
  },
  "大町市": {
    "code": "2021200",
    "coordinates": [
      137.781513373185,
      36.5056348418095
    ]
  },
  "飯山市": {
    "code": "2021300",
    "coordinates": [
      138.383440078362,
      36.9125002633392
    ]
  },
  "茅野市": {
    "code": "2021400",
    "coordinates": [
      138.246670058711,
      36.0252266119351
    ]
  },
  "塩尻市": {
    "code": "2021500",
    "coordinates": [
      137.900319532294,
      36.0381152541107
    ]
  },
  "佐久市": {
    "code": "2021700",
    "coordinates": [
      138.445285321219,
      36.2157739096247
    ]
  },
  "千曲市": {
    "code": "2021800",
    "coordinates": [
      138.123507126555,
      36.5062408817589
    ]
  },
  "東御市": {
    "code": "2021900",
    "coordinates": [
      138.368652194392,
      36.3738719235143
    ]
  },
  "安曇野市": {
    "code": "2022000",
    "coordinates": [
      137.840096033313,
      36.3279512056453
    ]
  },
  "小海町": {
    "code": "2030300",
    "coordinates": [
      138.459250819854,
      36.0686650100805
    ]
  },
  "長野川上村": {
    "code": "2030400",
    "coordinates": [
      138.618536351929,
      35.941712055991
    ]
  },
  "長野南牧村": {
    "code": "2030500",
    "coordinates": [
      138.458286784619,
      35.9841074403134
    ]
  },
  "南相木村": {
    "code": "2030600",
    "coordinates": [
      138.585923068007,
      36.0185445151316
    ]
  },
  "北相木村": {
    "code": "2030700",
    "coordinates": [
      138.590106676839,
      36.0623346226093
    ]
  },
  "佐久穂町": {
    "code": "2030900",
    "coordinates": [
      138.47450348132,
      36.1223518358829
    ]
  },
  "軽井沢町": {
    "code": "2032100",
    "coordinates": [
      138.589407907648,
      36.3568706337116
    ]
  },
  "御代田町": {
    "code": "2032300",
    "coordinates": [
      138.513134940722,
      36.3340768537962
    ]
  },
  "立科町": {
    "code": "2032400",
    "coordinates": [
      138.295050771689,
      36.2113214711283
    ]
  },
  "青木村": {
    "code": "2034900",
    "coordinates": [
      138.10514336905,
      36.3643904752237
    ]
  },
  "長和町": {
    "code": "2035000",
    "coordinates": [
      138.216112130681,
      36.1881827361665
    ]
  },
  "下諏訪町": {
    "code": "2036100",
    "coordinates": [
      138.112537604816,
      36.1105671407113
    ]
  },
  "富士見町": {
    "code": "2036200",
    "coordinates": [
      138.2499275343,
      35.9007166021568
    ]
  },
  "原村": {
    "code": "2036300",
    "coordinates": [
      138.256876882389,
      35.9638614628929
    ]
  },
  "辰野町": {
    "code": "2038200",
    "coordinates": [
      137.942646109758,
      35.9840619348455
    ]
  },
  "箕輪町": {
    "code": "2038300",
    "coordinates": [
      137.992527140902,
      35.9242527248965
    ]
  },
  "飯島町": {
    "code": "2038400",
    "coordinates": [
      137.87854347864,
      35.674496152744
    ]
  },
  "南箕輪村": {
    "code": "2038500",
    "coordinates": [
      137.917835532283,
      35.8861325421214
    ]
  },
  "中川村": {
    "code": "2038600",
    "coordinates": [
      137.976412496941,
      35.6391671373591
    ]
  },
  "宮田村": {
    "code": "2038800",
    "coordinates": [
      137.880804802871,
      35.7735477542588
    ]
  },
  "松川町": {
    "code": "2040200",
    "coordinates": [
      137.909173989787,
      35.6053139726852
    ]
  },
  "長野高森町": {
    "code": "2040300",
    "coordinates": [
      137.858762747195,
      35.5735830166086
    ]
  },
  "阿南町": {
    "code": "2040400",
    "coordinates": [
      137.763875208257,
      35.3172666000625
    ]
  },
  "阿智村": {
    "code": "2040700",
    "coordinates": [
      137.686402319704,
      35.4426457749253
    ]
  },
  "平谷村": {
    "code": "2040900",
    "coordinates": [
      137.634762360946,
      35.3333459540942
    ]
  },
  "根羽村": {
    "code": "2041000",
    "coordinates": [
      137.604076284831,
      35.2560926854607
    ]
  },
  "下條村": {
    "code": "2041100",
    "coordinates": [
      137.775154219869,
      35.3914890298019
    ]
  },
  "売木村": {
    "code": "2041200",
    "coordinates": [
      137.696050158437,
      35.2675496616562
    ]
  },
  "天龍村": {
    "code": "2041300",
    "coordinates": [
      137.821557482056,
      35.2538898811643
    ]
  },
  "泰阜村": {
    "code": "2041400",
    "coordinates": [
      137.86091638185,
      35.3632829147184
    ]
  },
  "喬木村": {
    "code": "2041500",
    "coordinates": [
      137.926851241388,
      35.480658546361
    ]
  },
  "豊丘村": {
    "code": "2041600",
    "coordinates": [
      137.950158248465,
      35.5384928356557
    ]
  },
  "大鹿村": {
    "code": "2041700",
    "coordinates": [
      138.074446456702,
      35.5581967042577
    ]
  },
  "上松町": {
    "code": "2042200",
    "coordinates": [
      137.682039894069,
      35.7644015233545
    ]
  },
  "南木曽町": {
    "code": "2042300",
    "coordinates": [
      137.617928744612,
      35.6036113720042
    ]
  },
  "木祖村": {
    "code": "2042500",
    "coordinates": [
      137.76024899493,
      35.996538413132
    ]
  },
  "王滝村": {
    "code": "2042900",
    "coordinates": [
      137.475026770028,
      35.8143663323857
    ]
  },
  "大桑村": {
    "code": "2043000",
    "coordinates": [
      137.691131702084,
      35.6908116321659
    ]
  },
  "木曽町": {
    "code": "2043200",
    "coordinates": [
      137.653453180522,
      35.899979307219
    ]
  },
  "麻績村": {
    "code": "2044600",
    "coordinates": [
      138.036716298611,
      36.4628771360903
    ]
  },
  "生坂村": {
    "code": "2044800",
    "coordinates": [
      137.943626642512,
      36.4327752003962
    ]
  },
  "山形村": {
    "code": "2045000",
    "coordinates": [
      137.862303386055,
      36.1608467556256
    ]
  },
  "朝日村": {
    "code": "2045100",
    "coordinates": [
      137.829743946902,
      36.1048601866154
    ]
  },
  "筑北村": {
    "code": "2045200",
    "coordinates": [
      138.040101695934,
      36.4212062367147
    ]
  },
  "長野池田町": {
    "code": "2048100",
    "coordinates": [
      137.897685171327,
      36.4232681728326
    ]
  },
  "松川村": {
    "code": "2048200",
    "coordinates": [
      137.826187619358,
      36.4134390481515
    ]
  },
  "白馬村": {
    "code": "2048500",
    "coordinates": [
      137.835653258063,
      36.6961478726983
    ]
  },
  "小谷村": {
    "code": "2048600",
    "coordinates": [
      137.915853075527,
      36.8258132513141
    ]
  },
  "坂城町": {
    "code": "2052100",
    "coordinates": [
      138.193864167014,
      36.4527550359892
    ]
  },
  "小布施町": {
    "code": "2054100",
    "coordinates": [
      138.316018425889,
      36.7027198460083
    ]
  },
  "長野高山村": {
    "code": "2054300",
    "coordinates": [
      138.423209009434,
      36.6623072451194
    ]
  },
  "山ノ内町": {
    "code": "2056100",
    "coordinates": [
      138.510633334331,
      36.7513180800864
    ]
  },
  "木島平村": {
    "code": "2056200",
    "coordinates": [
      138.466193218813,
      36.8404849043245
    ]
  },
  "野沢温泉村": {
    "code": "2056300",
    "coordinates": [
      138.467055713608,
      36.9315166797137
    ]
  },
  "信濃町": {
    "code": "2058300",
    "coordinates": [
      138.182452564024,
      36.8101759991604
    ]
  },
  "小川村": {
    "code": "2058800",
    "coordinates": [
      137.959615969087,
      36.6342492945146
    ]
  },
  "飯綱町": {
    "code": "2059000",
    "coordinates": [
      138.236429212003,
      36.7554757908329
    ]
  },
  "栄村": {
    "code": "2060200",
    "coordinates": [
      138.586508022946,
      36.8802683124013
    ]
  },
  "岐阜市": {
    "code": "2120100",
    "coordinates": [
      136.764693585086,
      35.4482606135503
    ]
  },
  "大垣市": {
    "code": "2120200",
    "coordinates": [
      136.520810587956,
      35.3124481887765
    ]
  },
  "高山市": {
    "code": "2120300",
    "coordinates": [
      137.285838254308,
      36.1318139333918
    ]
  },
  "多治見市": {
    "code": "2120400",
    "coordinates": [
      137.112567541534,
      35.3340108677407
    ]
  },
  "関市": {
    "code": "2120500",
    "coordinates": [
      136.874336131844,
      35.6305052971763
    ]
  },
  "中津川市": {
    "code": "2120600",
    "coordinates": [
      137.474324299463,
      35.5665596881482
    ]
  },
  "美濃市": {
    "code": "2120700",
    "coordinates": [
      136.901187747123,
      35.5789269830977
    ]
  },
  "瑞浪市": {
    "code": "2120800",
    "coordinates": [
      137.273591608301,
      35.3893588634937
    ]
  },
  "羽島市": {
    "code": "2120900",
    "coordinates": [
      136.701596656975,
      35.3074036801385
    ]
  },
  "恵那市": {
    "code": "2121000",
    "coordinates": [
      137.418089293686,
      35.3706934926162
    ]
  },
  "美濃加茂市": {
    "code": "2121100",
    "coordinates": [
      137.026567658191,
      35.4838614581587
    ]
  },
  "土岐市": {
    "code": "2121200",
    "coordinates": [
      137.207467284968,
      35.3241885678736
    ]
  },
  "各務原市": {
    "code": "2121300",
    "coordinates": [
      136.87827905988,
      35.4028303971979
    ]
  },
  "可児市": {
    "code": "2121400",
    "coordinates": [
      137.066008586497,
      35.405897549721
    ]
  },
  "岐阜山県市": {
    "code": "2121500",
    "coordinates": [
      136.743098623182,
      35.5989266152028
    ]
  },
  "瑞穂市": {
    "code": "2121600",
    "coordinates": [
      136.670215053385,
      35.3966391496449
    ]
  },
  "飛騨市": {
    "code": "2121700",
    "coordinates": [
      137.205676034194,
      36.3327266774371
    ]
  },
  "本巣市": {
    "code": "2121800",
    "coordinates": [
      136.619899400945,
      35.6555314501308
    ]
  },
  "郡上市": {
    "code": "2121900",
    "coordinates": [
      136.94560373445,
      35.8329857680947
    ]
  },
  "下呂市": {
    "code": "2122000",
    "coordinates": [
      137.238011216648,
      35.8419993565067
    ]
  },
  "海津市": {
    "code": "2122100",
    "coordinates": [
      136.624421076218,
      35.2138103414269
    ]
  },
  "岐南町": {
    "code": "2130200",
    "coordinates": [
      136.786763865909,
      35.386118255703
    ]
  },
  "笠松町": {
    "code": "2130300",
    "coordinates": [
      136.76945598242,
      35.3659551637708
    ]
  },
  "養老町": {
    "code": "2134100",
    "coordinates": [
      136.564438445914,
      35.2911206473382
    ]
  },
  "垂井町": {
    "code": "2136100",
    "coordinates": [
      136.516407829108,
      35.3859404018495
    ]
  },
  "関ケ原町": {
    "code": "2136200",
    "coordinates": [
      136.445826293851,
      35.3480179961705
    ]
  },
  "神戸町": {
    "code": "2138100",
    "coordinates": [
      136.609203698532,
      35.4157216282889
    ]
  },
  "輪之内町": {
    "code": "2138200",
    "coordinates": [
      136.634997778271,
      35.2859971258562
    ]
  },
  "安八町": {
    "code": "2138300",
    "coordinates": [
      136.662744330818,
      35.3365986905821
    ]
  },
  "揖斐川町": {
    "code": "2140100",
    "coordinates": [
      136.452223716082,
      35.604966795909
    ]
  },
  "大野町": {
    "code": "2140300",
    "coordinates": [
      136.626690465246,
      35.4681086636475
    ]
  },
  "岐阜池田町": {
    "code": "2140400",
    "coordinates": [
      136.556429595639,
      35.440356456521
    ]
  },
  "北方町": {
    "code": "2142100",
    "coordinates": [
      136.68966355131,
      35.4304424184366
    ]
  },
  "坂祝町": {
    "code": "2150100",
    "coordinates": [
      136.976461098282,
      35.4338266894678
    ]
  },
  "富加町": {
    "code": "2150200",
    "coordinates": [
      136.977369928681,
      35.4918070464286
    ]
  },
  "川辺町": {
    "code": "2150300",
    "coordinates": [
      137.081503105992,
      35.51061201924
    ]
  },
  "七宗町": {
    "code": "2150400",
    "coordinates": [
      137.11324630774,
      35.5774168444142
    ]
  },
  "八百津町": {
    "code": "2150500",
    "coordinates": [
      137.186286053407,
      35.5007884110092
    ]
  },
  "白川町": {
    "code": "2150600",
    "coordinates": [
      137.262809481043,
      35.6066812823969
    ]
  },
  "東白川村": {
    "code": "2150700",
    "coordinates": [
      137.337704779676,
      35.6428273735657
    ]
  },
  "御嵩町": {
    "code": "2152100",
    "coordinates": [
      137.154448336554,
      35.4335220431892
    ]
  },
  "白川村": {
    "code": "2160400",
    "coordinates": [
      136.879392054094,
      36.2197657177596
    ]
  },
  "静岡葵区": {
    "code": "2210100",
    "coordinates": [
      138.264212823468,
      35.2395971505255
    ]
  },
  "静岡駿河区": {
    "code": "2210200",
    "coordinates": [
      138.382388411729,
      34.9518015774264
    ]
  },
  "静岡清水区": {
    "code": "2210300",
    "coordinates": [
      138.485523209383,
      35.0970940125036
    ]
  },
  "浜松中央区": {
    "code": "2213800",
    "coordinates": [
      137.699233577535,
      34.727462745334
    ]
  },
  "浜松浜名区": {
    "code": "2213900",
    "coordinates": [
      137.663909443454,
      34.835993366677
    ]
  },
  "浜松天竜区": {
    "code": "2214000",
    "coordinates": [
      137.884905672157,
      35.059812295641
    ]
  },
  "沼津市": {
    "code": "2220300",
    "coordinates": [
      138.830535649999,
      35.0773490369098
    ]
  },
  "熱海市": {
    "code": "2220500",
    "coordinates": [
      139.063214583971,
      35.0907085683136
    ]
  },
  "三島市": {
    "code": "2220600",
    "coordinates": [
      138.948814301234,
      35.139400246373
    ]
  },
  "富士宮市": {
    "code": "2220700",
    "coordinates": [
      138.608823217875,
      35.3025507565329
    ]
  },
  "伊東市": {
    "code": "2220800",
    "coordinates": [
      139.087241682349,
      34.9356337926604
    ]
  },
  "島田市": {
    "code": "2220900",
    "coordinates": [
      138.126607938195,
      34.9122040531534
    ]
  },
  "富士市": {
    "code": "2221000",
    "coordinates": [
      138.699045231695,
      35.2010612658564
    ]
  },
  "磐田市": {
    "code": "2221100",
    "coordinates": [
      137.850418929615,
      34.7445181108281
    ]
  },
  "焼津市": {
    "code": "2221200",
    "coordinates": [
      138.30133334717,
      34.8401373235611
    ]
  },
  "掛川市": {
    "code": "2221300",
    "coordinates": [
      138.018960204741,
      34.7799356014382
    ]
  },
  "藤枝市": {
    "code": "2221400",
    "coordinates": [
      138.233783242768,
      34.9195665191189
    ]
  },
  "御殿場市": {
    "code": "2221500",
    "coordinates": [
      138.8790133586,
      35.298931717329
    ]
  },
  "袋井市": {
    "code": "2221600",
    "coordinates": [
      137.928039011852,
      34.741756600617
    ]
  },
  "下田市": {
    "code": "2221900",
    "coordinates": [
      138.921467574622,
      34.7087980744771
    ]
  },
  "裾野市": {
    "code": "2222000",
    "coordinates": [
      138.877335158358,
      35.225514771715
    ]
  },
  "湖西市": {
    "code": "2222100",
    "coordinates": [
      137.526369168276,
      34.7230457420536
    ]
  },
  "伊豆市": {
    "code": "2222200",
    "coordinates": [
      138.92588364882,
      34.922311321886
    ]
  },
  "御前崎市": {
    "code": "2222300",
    "coordinates": [
      138.148171509753,
      34.648131686973
    ]
  },
  "菊川市": {
    "code": "2222400",
    "coordinates": [
      138.100467404074,
      34.738832486618
    ]
  },
  "伊豆の国市": {
    "code": "2222500",
    "coordinates": [
      138.972946172887,
      35.0379938797368
    ]
  },
  "牧之原市": {
    "code": "2222600",
    "coordinates": [
      138.185739717521,
      34.7319186605798
    ]
  },
  "東伊豆町": {
    "code": "2230100",
    "coordinates": [
      139.02828110073,
      34.8265722081462
    ]
  },
  "河津町": {
    "code": "2230200",
    "coordinates": [
      138.952601657738,
      34.7861076222552
    ]
  },
  "南伊豆町": {
    "code": "2230400",
    "coordinates": [
      138.831362954773,
      34.6642536754327
    ]
  },
  "松崎町": {
    "code": "2230500",
    "coordinates": [
      138.820404243548,
      34.7458158316382
    ]
  },
  "西伊豆町": {
    "code": "2230600",
    "coordinates": [
      138.817283388802,
      34.8233631702458
    ]
  },
  "函南町": {
    "code": "2232500",
    "coordinates": [
      138.994010747576,
      35.1139445137426
    ]
  },
  "静岡清水町": {
    "code": "2234100",
    "coordinates": [
      138.898696953859,
      35.0974541780044
    ]
  },
  "長泉町": {
    "code": "2234200",
    "coordinates": [
      138.871001548188,
      35.1660585767355
    ]
  },
  "小山町": {
    "code": "2234400",
    "coordinates": [
      138.918683629148,
      35.3600723862491
    ]
  },
  "吉田町": {
    "code": "2242400",
    "coordinates": [
      138.257140718087,
      34.7734967512743
    ]
  },
  "川根本町": {
    "code": "2242900",
    "coordinates": [
      138.103311401276,
      35.1636431897297
    ]
  },
  "静岡森町": {
    "code": "2246100",
    "coordinates": [
      137.947560778798,
      34.8865670992671
    ]
  },
  "名古屋千種区": {
    "code": "2310100",
    "coordinates": [
      136.961714333791,
      35.1697482120011
    ]
  },
  "名古屋東区": {
    "code": "2310200",
    "coordinates": [
      136.932352287987,
      35.1831092827754
    ]
  },
  "名古屋北区": {
    "code": "2310300",
    "coordinates": [
      136.91788337019,
      35.2111744039166
    ]
  },
  "名古屋西区": {
    "code": "2310400",
    "coordinates": [
      136.88331044493,
      35.2071667628885
    ]
  },
  "名古屋中村区": {
    "code": "2310500",
    "coordinates": [
      136.860144883204,
      35.1668548728315
    ]
  },
  "名古屋中区": {
    "code": "2310600",
    "coordinates": [
      136.905297969114,
      35.1635828434679
    ]
  },
  "名古屋昭和区": {
    "code": "2310700",
    "coordinates": [
      136.941870238769,
      35.1475265850694
    ]
  },
  "名古屋瑞穂区": {
    "code": "2310800",
    "coordinates": [
      136.93750523588,
      35.1258238447079
    ]
  },
  "名古屋熱田区": {
    "code": "2310900",
    "coordinates": [
      136.9003757877,
      35.1282213722945
    ]
  },
  "名古屋中川区": {
    "code": "2311000",
    "coordinates": [
      136.844694916202,
      35.138959508272
    ]
  },
  "名古屋港区": {
    "code": "2311100",
    "coordinates": [
      136.851790568312,
      35.0932886612021
    ]
  },
  "名古屋南区": {
    "code": "2311200",
    "coordinates": [
      136.923514869366,
      35.0941993959453
    ]
  },
  "名古屋守山区": {
    "code": "2311300",
    "coordinates": [
      136.995775356542,
      35.2203369915396
    ]
  },
  "名古屋緑区": {
    "code": "2311400",
    "coordinates": [
      136.970020927516,
      35.0777942970333
    ]
  },
  "名古屋名東区": {
    "code": "2311500",
    "coordinates": [
      137.005337005091,
      35.1677821141833
    ]
  },
  "名古屋天白区": {
    "code": "2311600",
    "coordinates": [
      136.983749391724,
      35.1226661665109
    ]
  },
  "豊橋市": {
    "code": "2320100",
    "coordinates": [
      137.407171436584,
      34.7398852363038
    ]
  },
  "岡崎市": {
    "code": "2320200",
    "coordinates": [
      137.258278327658,
      34.9510681822971
    ]
  },
  "一宮市": {
    "code": "2320300",
    "coordinates": [
      136.793039106325,
      35.3087371519853
    ]
  },
  "瀬戸市": {
    "code": "2320400",
    "coordinates": [
      137.116744975279,
      35.2381517761896
    ]
  },
  "半田市": {
    "code": "2320500",
    "coordinates": [
      136.926360650939,
      34.899471339318
    ]
  },
  "春日井市": {
    "code": "2320600",
    "coordinates": [
      137.00669991735,
      35.2730277554886
    ]
  },
  "豊川市": {
    "code": "2320700",
    "coordinates": [
      137.360952753324,
      34.8473645810592
    ]
  },
  "愛知津島市": {
    "code": "2320800",
    "coordinates": [
      136.752722165737,
      35.1696063440905
    ]
  },
  "碧南市": {
    "code": "2320900",
    "coordinates": [
      136.991670217273,
      34.8782885435308
    ]
  },
  "刈谷市": {
    "code": "2321000",
    "coordinates": [
      137.017428364143,
      35.0029023895314
    ]
  },
  "豊田市": {
    "code": "2321100",
    "coordinates": [
      137.301230411135,
      35.1419344831071
    ]
  },
  "安城市": {
    "code": "2321200",
    "coordinates": [
      137.071908250334,
      34.9436098758824
    ]
  },
  "西尾市": {
    "code": "2321300",
    "coordinates": [
      137.070107759207,
      34.832191692216
    ]
  },
  "蒲郡市": {
    "code": "2321400",
    "coordinates": [
      137.227908893576,
      34.82662479458
    ]
  },
  "犬山市": {
    "code": "2321500",
    "coordinates": [
      136.980434912931,
      35.3616718484041
    ]
  },
  "常滑市": {
    "code": "2321600",
    "coordinates": [
      136.853342043308,
      34.8866757924806
    ]
  },
  "愛知江南市": {
    "code": "2321700",
    "coordinates": [
      136.870327715649,
      35.3431509563087
    ]
  },
  "小牧市": {
    "code": "2321900",
    "coordinates": [
      136.950078171355,
      35.2986392318505
    ]
  },
  "稲沢市": {
    "code": "2322000",
    "coordinates": [
      136.763495548922,
      35.2397803525463
    ]
  },
  "新城市": {
    "code": "2322100",
    "coordinates": [
      137.542715883831,
      34.9600913166154
    ]
  },
  "東海市": {
    "code": "2322200",
    "coordinates": [
      136.899906243911,
      35.026210853394
    ]
  },
  "大府市": {
    "code": "2322300",
    "coordinates": [
      136.954780101762,
      35.0189180511064
    ]
  },
  "知多市": {
    "code": "2322400",
    "coordinates": [
      136.863895904671,
      34.9699063472297
    ]
  },
  "知立市": {
    "code": "2322500",
    "coordinates": [
      137.049585369323,
      34.9991445997696
    ]
  },
  "尾張旭市": {
    "code": "2322600",
    "coordinates": [
      137.036539441205,
      35.2170108967526
    ]
  },
  "高浜市": {
    "code": "2322700",
    "coordinates": [
      136.995520664649,
      34.9323854433254
    ]
  },
  "岩倉市": {
    "code": "2322800",
    "coordinates": [
      136.868754759574,
      35.2784104167793
    ]
  },
  "豊明市": {
    "code": "2322900",
    "coordinates": [
      137.010203567679,
      35.0605481574976
    ]
  },
  "日進市": {
    "code": "2323000",
    "coordinates": [
      137.051126592933,
      35.1375443757198
    ]
  },
  "田原市": {
    "code": "2323100",
    "coordinates": [
      137.183833315523,
      34.6409017405438
    ]
  },
  "愛西市": {
    "code": "2323200",
    "coordinates": [
      136.711291425676,
      35.1718138202135
    ]
  },
  "清須市": {
    "code": "2323300",
    "coordinates": [
      136.847651314937,
      35.2132329287203
    ]
  },
  "北名古屋市": {
    "code": "2323400",
    "coordinates": [
      136.872752768487,
      35.2469514937626
    ]
  },
  "弥富市": {
    "code": "2323500",
    "coordinates": [
      136.760612641893,
      35.0819184733178
    ]
  },
  "愛知みよし市": {
    "code": "2323600",
    "coordinates": [
      137.088831276943,
      35.0942399724407
    ]
  },
  "あま市": {
    "code": "2323700",
    "coordinates": [
      136.80341521196,
      35.1884223593157
    ]
  },
  "長久手市": {
    "code": "2323800",
    "coordinates": [
      137.059124567263,
      35.1757184234655
    ]
  },
  "東郷町": {
    "code": "2330200",
    "coordinates": [
      137.053674300163,
      35.1009935468182
    ]
  },
  "豊山町": {
    "code": "2334200",
    "coordinates": [
      136.916301602286,
      35.2508526675877
    ]
  },
  "大口町": {
    "code": "2336100",
    "coordinates": [
      136.91090811733,
      35.3313470669792
    ]
  },
  "扶桑町": {
    "code": "2336200",
    "coordinates": [
      136.913406042014,
      35.3609921562555
    ]
  },
  "大治町": {
    "code": "2342400",
    "coordinates": [
      136.822351586446,
      35.1734646626061
    ]
  },
  "蟹江町": {
    "code": "2342500",
    "coordinates": [
      136.785282419345,
      35.1303071672937
    ]
  },
  "飛島村": {
    "code": "2342700",
    "coordinates": [
      136.80780241758,
      35.0679238646144
    ]
  },
  "阿久比町": {
    "code": "2344100",
    "coordinates": [
      136.910878672459,
      34.9350115443001
    ]
  },
  "東浦町": {
    "code": "2344200",
    "coordinates": [
      136.955384462671,
      34.9688438447359
    ]
  },
  "南知多町": {
    "code": "2344500",
    "coordinates": [
      136.921952116252,
      34.7277546516013
    ]
  },
  "愛知美浜町": {
    "code": "2344600",
    "coordinates": [
      136.88976941436,
      34.777788677052
    ]
  },
  "武豊町": {
    "code": "2344700",
    "coordinates": [
      136.906672978731,
      34.8435159862599
    ]
  },
  "幸田町": {
    "code": "2350100",
    "coordinates": [
      137.165886035787,
      34.86211943839
    ]
  },
  "設楽町": {
    "code": "2356100",
    "coordinates": [
      137.554785494844,
      35.1226354341067
    ]
  },
  "東栄町": {
    "code": "2356200",
    "coordinates": [
      137.690140704369,
      35.0896322789955
    ]
  },
  "豊根村": {
    "code": "2356300",
    "coordinates": [
      137.739476205799,
      35.1714388311843
    ]
  },
  "津市": {
    "code": "2420100",
    "coordinates": [
      136.366549042821,
      34.6599994507808
    ]
  },
  "四日市市": {
    "code": "2420200",
    "coordinates": [
      136.57611792658,
      34.9798148230079
    ]
  },
  "伊勢市": {
    "code": "2420300",
    "coordinates": [
      136.715837678947,
      34.4674013266771
    ]
  },
  "松阪市": {
    "code": "2420400",
    "coordinates": [
      136.356718132829,
      34.48655083187
    ]
  },
  "桑名市": {
    "code": "2420500",
    "coordinates": [
      136.65622826842,
      35.0942162135359
    ]
  },
  "鈴鹿市": {
    "code": "2420700",
    "coordinates": [
      136.522404100848,
      34.8902367382937
    ]
  },
  "名張市": {
    "code": "2420800",
    "coordinates": [
      136.118269521477,
      34.6092182523598
    ]
  },
  "尾鷲市": {
    "code": "2420900",
    "coordinates": [
      136.183075650477,
      34.0369177036284
    ]
  },
  "亀山市": {
    "code": "2421000",
    "coordinates": [
      136.393443398739,
      34.8686620577722
    ]
  },
  "鳥羽市": {
    "code": "2421100",
    "coordinates": [
      136.858386365262,
      34.4455753662306
    ]
  },
  "熊野市": {
    "code": "2421200",
    "coordinates": [
      136.026455563939,
      33.9229081384774
    ]
  },
  "いなべ市": {
    "code": "2421400",
    "coordinates": [
      136.507871454334,
      35.1606119324582
    ]
  },
  "志摩市": {
    "code": "2421500",
    "coordinates": [
      136.813356953031,
      34.3379489065719
    ]
  },
  "伊賀市": {
    "code": "2421600",
    "coordinates": [
      136.185035562728,
      34.7495597397941
    ]
  },
  "木曽岬町": {
    "code": "2430300",
    "coordinates": [
      136.740222092195,
      35.0651217328122
    ]
  },
  "東員町": {
    "code": "2432400",
    "coordinates": [
      136.589750926186,
      35.0795014166496
    ]
  },
  "菰野町": {
    "code": "2434100",
    "coordinates": [
      136.483573194793,
      35.0402653607129
    ]
  },
  "三重朝日町": {
    "code": "2434300",
    "coordinates": [
      136.661493190099,
      35.0363761927931
    ]
  },
  "川越町": {
    "code": "2434400",
    "coordinates": [
      136.680623546123,
      35.0199851025635
    ]
  },
  "多気町": {
    "code": "2444100",
    "coordinates": [
      136.501527838206,
      34.4702002168595
    ]
  },
  "三重明和町": {
    "code": "2444200",
    "coordinates": [
      136.626523060929,
      34.5504121672356
    ]
  },
  "大台町": {
    "code": "2444300",
    "coordinates": [
      136.262654793346,
      34.3160898384228
    ]
  },
  "玉城町": {
    "code": "2446100",
    "coordinates": [
      136.620655908467,
      34.4809299337305
    ]
  },
  "度会町": {
    "code": "2447000",
    "coordinates": [
      136.578654356627,
      34.3902137757577
    ]
  },
  "三重大紀町": {
    "code": "2447100",
    "coordinates": [
      136.412117020118,
      34.3157186451234
    ]
  },
  "南伊勢町": {
    "code": "2447200",
    "coordinates": [
      136.584811302783,
      34.3110380290615
    ]
  },
  "三重紀北町": {
    "code": "2454300",
    "coordinates": [
      136.237089968954,
      34.1763693808773
    ]
  },
  "三重御浜町": {
    "code": "2456100",
    "coordinates": [
      136.00936060217,
      33.8399560791368
    ]
  },
  "紀宝町": {
    "code": "2456200",
    "coordinates": [
      135.969515546941,
      33.7719832704982
    ]
  },
  "大津市": {
    "code": "2520100",
    "coordinates": [
      135.91252288522,
      35.0877031331644
    ]
  },
  "彦根市": {
    "code": "2520200",
    "coordinates": [
      136.20594492504,
      35.2651832542716
    ]
  },
  "長浜市": {
    "code": "2520300",
    "coordinates": [
      136.224678692469,
      35.5006416147406
    ]
  },
  "近江八幡市": {
    "code": "2520400",
    "coordinates": [
      136.076506898321,
      35.165107994731
    ]
  },
  "草津市": {
    "code": "2520600",
    "coordinates": [
      135.938020746279,
      35.0260439212401
    ]
  },
  "守山市": {
    "code": "2520700",
    "coordinates": [
      135.969895382738,
      35.0901535622235
    ]
  },
  "栗東市": {
    "code": "2520800",
    "coordinates": [
      136.014213191422,
      34.9990444225324
    ]
  },
  "甲賀市": {
    "code": "2520900",
    "coordinates": [
      136.179884909517,
      34.9179158490955
    ]
  },
  "野洲市": {
    "code": "2521000",
    "coordinates": [
      136.020363380125,
      35.1036636326594
    ]
  },
  "湖南市": {
    "code": "2521100",
    "coordinates": [
      136.087377455131,
      34.9998946026829
    ]
  },
  "高島市": {
    "code": "2521200",
    "coordinates": [
      135.986100767254,
      35.3719401593135
    ]
  },
  "東近江市": {
    "code": "2521300",
    "coordinates": [
      136.287300108904,
      35.1070551642075
    ]
  },
  "米原市": {
    "code": "2521400",
    "coordinates": [
      136.350861265055,
      35.3838720197277
    ]
  },
  "滋賀日野町": {
    "code": "2538300",
    "coordinates": [
      136.263676598636,
      35.0129903608949
    ]
  },
  "竜王町": {
    "code": "2538400",
    "coordinates": [
      136.113357292472,
      35.0575788158551
    ]
  },
  "愛荘町": {
    "code": "2542500",
    "coordinates": [
      136.247922298635,
      35.1692080215372
    ]
  },
  "豊郷町": {
    "code": "2544100",
    "coordinates": [
      136.231629314745,
      35.1983814484
    ]
  },
  "甲良町": {
    "code": "2544200",
    "coordinates": [
      136.265049572802,
      35.2024569788843
    ]
  },
  "多賀町": {
    "code": "2544300",
    "coordinates": [
      136.34437818105,
      35.2107827069013
    ]
  },
  "京都北区": {
    "code": "2610100",
    "coordinates": [
      135.713393054368,
      35.1025503455559
    ]
  },
  "京都上京区": {
    "code": "2610200",
    "coordinates": [
      135.752913108279,
      35.0267314241133
    ]
  },
  "京都左京区": {
    "code": "2610300",
    "coordinates": [
      135.796238027346,
      35.1656385020207
    ]
  },
  "京都中京区": {
    "code": "2610400",
    "coordinates": [
      135.746777382653,
      35.0103613290861
    ]
  },
  "京都東山区": {
    "code": "2610500",
    "coordinates": [
      135.77870851006,
      34.9910223028437
    ]
  },
  "京都下京区": {
    "code": "2610600",
    "coordinates": [
      135.750491651679,
      34.9928910529521
    ]
  },
  "京都南区": {
    "code": "2610700",
    "coordinates": [
      135.735583699023,
      34.9673159535392
    ]
  },
  "京都右京区": {
    "code": "2610800",
    "coordinates": [
      135.665131782084,
      35.1489718731026
    ]
  },
  "京都伏見区": {
    "code": "2610900",
    "coordinates": [
      135.774777733267,
      34.9369477047328
    ]
  },
  "京都山科区": {
    "code": "2611000",
    "coordinates": [
      135.816394935714,
      34.9831803759848
    ]
  },
  "京都西京区": {
    "code": "2611100",
    "coordinates": [
      135.660475983799,
      34.9729109312286
    ]
  },
  "福知山市": {
    "code": "2620100",
    "coordinates": [
      135.113307938685,
      35.3240471831402
    ]
  },
  "舞鶴市": {
    "code": "2620200",
    "coordinates": [
      135.340020212453,
      35.4700991751059
    ]
  },
  "綾部市": {
    "code": "2620300",
    "coordinates": [
      135.344638666407,
      35.3526396749737
    ]
  },
  "宇治市": {
    "code": "2620400",
    "coordinates": [
      135.820384260879,
      34.9029685984935
    ]
  },
  "宮津市": {
    "code": "2620500",
    "coordinates": [
      135.206902735139,
      35.5718616424564
    ]
  },
  "亀岡市": {
    "code": "2620600",
    "coordinates": [
      135.538994886688,
      35.0102187266857
    ]
  },
  "城陽市": {
    "code": "2620700",
    "coordinates": [
      135.79759525632,
      34.845668754309
    ]
  },
  "向日市": {
    "code": "2620800",
    "coordinates": [
      135.702679201897,
      34.9497524696926
    ]
  },
  "長岡京市": {
    "code": "2620900",
    "coordinates": [
      135.68011137991,
      34.924579637097
    ]
  },
  "八幡市": {
    "code": "2621000",
    "coordinates": [
      135.714693681761,
      34.8666218473502
    ]
  },
  "京田辺市": {
    "code": "2621100",
    "coordinates": [
      135.758597644871,
      34.8065565119903
    ]
  },
  "京丹後市": {
    "code": "2621200",
    "coordinates": [
      135.047841792027,
      35.6308944585677
    ]
  },
  "南丹市": {
    "code": "2621300",
    "coordinates": [
      135.559379888615,
      35.2274000083818
    ]
  },
  "木津川市": {
    "code": "2621400",
    "coordinates": [
      135.847787485092,
      34.7512398421475
    ]
  },
  "大山崎町": {
    "code": "2630300",
    "coordinates": [
      135.687117825211,
      34.902159415934
    ]
  },
  "久御山町": {
    "code": "2632200",
    "coordinates": [
      135.747315252337,
      34.8889550306196
    ]
  },
  "井手町": {
    "code": "2634300",
    "coordinates": [
      135.828717369691,
      34.8102238893534
    ]
  },
  "宇治田原町": {
    "code": "2634400",
    "coordinates": [
      135.887567229407,
      34.8549249471611
    ]
  },
  "笠置町": {
    "code": "2636400",
    "coordinates": [
      135.948337329331,
      34.7642982043083
    ]
  },
  "和束町": {
    "code": "2636500",
    "coordinates": [
      135.929376264353,
      34.8104554633197
    ]
  },
  "精華町": {
    "code": "2636600",
    "coordinates": [
      135.775940793234,
      34.7574936212094
    ]
  },
  "南山城村": {
    "code": "2636700",
    "coordinates": [
      136.002437752671,
      34.7661270047845
    ]
  },
  "京丹波町": {
    "code": "2640700",
    "coordinates": [
      135.391086718704,
      35.2232800735133
    ]
  },
  "伊根町": {
    "code": "2646300",
    "coordinates": [
      135.247668628712,
      35.7141576929109
    ]
  },
  "与謝野町": {
    "code": "2646500",
    "coordinates": [
      135.102137112796,
      35.5100258420412
    ]
  },
  "大阪都島区": {
    "code": "2710200",
    "coordinates": [
      135.527213498849,
      34.7119256614762
    ]
  },
  "大阪福島区": {
    "code": "2710300",
    "coordinates": [
      135.475339171677,
      34.693895128174
    ]
  },
  "大阪此花区": {
    "code": "2710400",
    "coordinates": [
      135.424020909328,
      34.6704944335373
    ]
  },
  "大阪西区": {
    "code": "2710600",
    "coordinates": [
      135.483764667844,
      34.6777606435464
    ]
  },
  "大阪港区": {
    "code": "2710700",
    "coordinates": [
      135.450598666061,
      34.6599752131684
    ]
  },
  "大阪大正区": {
    "code": "2710800",
    "coordinates": [
      135.465986394619,
      34.6440856090918
    ]
  },
  "大阪天王寺区": {
    "code": "2710900",
    "coordinates": [
      135.520354735154,
      34.6599066557486
    ]
  },
  "大阪浪速区": {
    "code": "2711100",
    "coordinates": [
      135.495356807657,
      34.6596090302312
    ]
  },
  "大阪西淀川区": {
    "code": "2711300",
    "coordinates": [
      135.4411864028,
      34.7038788339332
    ]
  },
  "大阪東淀川区": {
    "code": "2711400",
    "coordinates": [
      135.530829476784,
      34.7433057299875
    ]
  },
  "大阪東成区": {
    "code": "2711500",
    "coordinates": [
      135.546915158717,
      34.6731415470061
    ]
  },
  "大阪生野区": {
    "code": "2711600",
    "coordinates": [
      135.54439267814,
      34.6523663636249
    ]
  },
  "大阪旭区": {
    "code": "2711700",
    "coordinates": [
      135.547263051597,
      34.7255135502263
    ]
  },
  "大阪城東区": {
    "code": "2711800",
    "coordinates": [
      135.548928932622,
      34.6966114324019
    ]
  },
  "大阪阿倍野区": {
    "code": "2711900",
    "coordinates": [
      135.513493470238,
      34.6330471428805
    ]
  },
  "大阪住吉区": {
    "code": "2712000",
    "coordinates": [
      135.504680963468,
      34.6049007308552
    ]
  },
  "大阪東住吉区": {
    "code": "2712100",
    "coordinates": [
      135.53161524384,
      34.6190173020032
    ]
  },
  "大阪西成区": {
    "code": "2712200",
    "coordinates": [
      135.490035524276,
      34.6386466759942
    ]
  },
  "大阪淀川区": {
    "code": "2712300",
    "coordinates": [
      135.482155843845,
      34.7290330135644
    ]
  },
  "大阪鶴見区": {
    "code": "2712400",
    "coordinates": [
      135.575412291301,
      34.7034714736897
    ]
  },
  "大阪住之江区": {
    "code": "2712500",
    "coordinates": [
      135.44715535755,
      34.62061334941
    ]
  },
  "大阪平野区": {
    "code": "2712600",
    "coordinates": [
      135.560699811348,
      34.615041651875
    ]
  },
  "大阪北区": {
    "code": "2712700",
    "coordinates": [
      135.501906536417,
      34.7051949898603
    ]
  },
  "大阪中央区": {
    "code": "2712800",
    "coordinates": [
      135.51412499157,
      34.6801145653684
    ]
  },
  "大阪堺市堺区": {
    "code": "2714100",
    "coordinates": [
      135.469098787062,
      34.580878155001
    ]
  },
  "大阪堺市中区": {
    "code": "2714200",
    "coordinates": [
      135.50403299236,
      34.5242128135381
    ]
  },
  "大阪堺市東区": {
    "code": "2714300",
    "coordinates": [
      135.534120175428,
      34.5355551471616
    ]
  },
  "大阪堺市西区": {
    "code": "2714400",
    "coordinates": [
      135.445563485131,
      34.5494346378038
    ]
  },
  "大阪堺市南区": {
    "code": "2714500",
    "coordinates": [
      135.506513871045,
      34.4756796611993
    ]
  },
  "大阪堺市北区": {
    "code": "2714600",
    "coordinates": [
      135.512988173107,
      34.5652970408604
    ]
  },
  "大阪堺市美原区": {
    "code": "2714700",
    "coordinates": [
      135.563607103536,
      34.5361506895968
    ]
  },
  "岸和田市": {
    "code": "2720200",
    "coordinates": [
      135.415520575793,
      34.4278492973163
    ]
  },
  "豊中市": {
    "code": "2720300",
    "coordinates": [
      135.472754543993,
      34.7819124637855
    ]
  },
  "池田市": {
    "code": "2720400",
    "coordinates": [
      135.43824563959,
      34.8344322013552
    ]
  },
  "吹田市": {
    "code": "2720500",
    "coordinates": [
      135.519078466095,
      34.7855606303942
    ]
  },
  "泉大津市": {
    "code": "2720600",
    "coordinates": [
      135.404361745636,
      34.5081290771912
    ]
  },
  "高槻市": {
    "code": "2720700",
    "coordinates": [
      135.607914232651,
      34.8807959006012
    ]
  },
  "貝塚市": {
    "code": "2720800",
    "coordinates": [
      135.385095379278,
      34.4010565807046
    ]
  },
  "守口市": {
    "code": "2720900",
    "coordinates": [
      135.576368552299,
      34.7417482153224
    ]
  },
  "枚方市": {
    "code": "2721000",
    "coordinates": [
      135.681703211106,
      34.8176287712804
    ]
  },
  "茨木市": {
    "code": "2721100",
    "coordinates": [
      135.549972865631,
      34.8558885067648
    ]
  },
  "八尾市": {
    "code": "2721200",
    "coordinates": [
      135.616188278797,
      34.6202634016919
    ]
  },
  "泉佐野市": {
    "code": "2721300",
    "coordinates": [
      135.335414644326,
      34.3803199130224
    ]
  },
  "富田林市": {
    "code": "2721400",
    "coordinates": [
      135.594306456013,
      34.4888288065732
    ]
  },
  "寝屋川市": {
    "code": "2721500",
    "coordinates": [
      135.62695540927,
      34.7653969507788
    ]
  },
  "河内長野市": {
    "code": "2721600",
    "coordinates": [
      135.566909909477,
      34.405476571893
    ]
  },
  "松原市": {
    "code": "2721700",
    "coordinates": [
      135.551170585943,
      34.5802132735071
    ]
  },
  "大東市": {
    "code": "2721800",
    "coordinates": [
      135.635748967559,
      34.7128426852352
    ]
  },
  "大阪和泉市": {
    "code": "2721900",
    "coordinates": [
      135.471632691384,
      34.4313530588581
    ]
  },
  "箕面市": {
    "code": "2722000",
    "coordinates": [
      135.479255003697,
      34.8559044132275
    ]
  },
  "柏原市": {
    "code": "2722100",
    "coordinates": [
      135.646357644023,
      34.5790171762113
    ]
  },
  "羽曳野市": {
    "code": "2722200",
    "coordinates": [
      135.605997765953,
      34.5481555386537
    ]
  },
  "門真市": {
    "code": "2722300",
    "coordinates": [
      135.599342352546,
      34.7321505909407
    ]
  },
  "摂津市": {
    "code": "2722400",
    "coordinates": [
      135.569567958597,
      34.7760095632131
    ]
  },
  "高石市": {
    "code": "2722500",
    "coordinates": [
      135.427104709432,
      34.5294817482412
    ]
  },
  "藤井寺市": {
    "code": "2722600",
    "coordinates": [
      135.604018922536,
      34.5741483026939
    ]
  },
  "東大阪市": {
    "code": "2722700",
    "coordinates": [
      135.617596218945,
      34.6711242704273
    ]
  },
  "泉南市": {
    "code": "2722800",
    "coordinates": [
      135.289169895883,
      34.3542515385928
    ]
  },
  "四條畷市": {
    "code": "2722900",
    "coordinates": [
      135.667830881004,
      34.7324278968162
    ]
  },
  "交野市": {
    "code": "2723000",
    "coordinates": [
      135.686917772584,
      34.7721597152216
    ]
  },
  "大阪狭山市": {
    "code": "2723100",
    "coordinates": [
      135.548926910822,
      34.4964548836979
    ]
  },
  "阪南市": {
    "code": "2723200",
    "coordinates": [
      135.235205072039,
      34.3311535817757
    ]
  },
  "島本町": {
    "code": "2730100",
    "coordinates": [
      135.653244146965,
      34.8988735583494
    ]
  },
  "豊能町": {
    "code": "2732100",
    "coordinates": [
      135.482208251139,
      34.9138295769712
    ]
  },
  "能勢町": {
    "code": "2732200",
    "coordinates": [
      135.412954672472,
      34.9808374419472
    ]
  },
  "忠岡町": {
    "code": "2734100",
    "coordinates": [
      135.393641295598,
      34.4926208993907
    ]
  },
  "熊取町": {
    "code": "2736100",
    "coordinates": [
      135.364036567194,
      34.3890263847654
    ]
  },
  "田尻町": {
    "code": "2736200",
    "coordinates": [
      135.257683602931,
      34.4170333145244
    ]
  },
  "大阪岬町": {
    "code": "2736600",
    "coordinates": [
      135.152019541078,
      34.3031862003802
    ]
  },
  "大阪太子町": {
    "code": "2738100",
    "coordinates": [
      135.652692967016,
      34.5176475165969
    ]
  },
  "河南町": {
    "code": "2738200",
    "coordinates": [
      135.648966060806,
      34.4845513626913
    ]
  },
  "千早赤阪村": {
    "code": "2738300",
    "coordinates": [
      135.648121921621,
      34.4384367350975
    ]
  },
  "神戸東灘区": {
    "code": "2810100",
    "coordinates": [
      135.27122158625,
      34.7244553024087
    ]
  },
  "神戸灘区": {
    "code": "2810200",
    "coordinates": [
      135.228871364542,
      34.7336157241353
    ]
  },
  "神戸兵庫区": {
    "code": "2810500",
    "coordinates": [
      135.165155330913,
      34.6797748941279
    ]
  },
  "神戸長田区": {
    "code": "2810600",
    "coordinates": [
      135.144418799267,
      34.6684624107482
    ]
  },
  "神戸須磨区": {
    "code": "2810700",
    "coordinates": [
      135.106379350548,
      34.6709927547789
    ]
  },
  "神戸垂水区": {
    "code": "2810800",
    "coordinates": [
      135.059724228952,
      34.6494579580496
    ]
  },
  "神戸北区": {
    "code": "2810900",
    "coordinates": [
      135.171472470946,
      34.7962023711024
    ]
  },
  "神戸中央区": {
    "code": "2811000",
    "coordinates": [
      135.202974869534,
      34.6842751126119
    ]
  },
  "神戸西区": {
    "code": "2811100",
    "coordinates": [
      135.016808116293,
      34.7177085641282
    ]
  },
  "姫路市": {
    "code": "2820100",
    "coordinates": [
      134.665083587185,
      34.9007397857188
    ]
  },
  "尼崎市": {
    "code": "2820200",
    "coordinates": [
      135.411154950537,
      34.73406506378
    ]
  },
  "明石市": {
    "code": "2820300",
    "coordinates": [
      134.940400352504,
      34.6830239845665
    ]
  },
  "西宮市": {
    "code": "2820400",
    "coordinates": [
      135.312967682571,
      34.7841877525196
    ]
  },
  "洲本市": {
    "code": "2820500",
    "coordinates": [
      134.854731016307,
      34.3389403505051
    ]
  },
  "芦屋市": {
    "code": "2820600",
    "coordinates": [
      135.299738758031,
      34.7457633820903
    ]
  },
  "伊丹市": {
    "code": "2820700",
    "coordinates": [
      135.406786800545,
      34.78540556625
    ]
  },
  "相生市": {
    "code": "2820800",
    "coordinates": [
      134.455666782557,
      34.8406272933831
    ]
  },
  "豊岡市": {
    "code": "2820900",
    "coordinates": [
      134.826502914526,
      35.51286757274
    ]
  },
  "加古川市": {
    "code": "2821000",
    "coordinates": [
      134.851096318665,
      34.7915646086051
    ]
  },
  "赤穂市": {
    "code": "2821200",
    "coordinates": [
      134.372524745333,
      34.7889263594236
    ]
  },
  "西脇市": {
    "code": "2821300",
    "coordinates": [
      134.994873253432,
      35.0092190331199
    ]
  },
  "宝塚市": {
    "code": "2821400",
    "coordinates": [
      135.335452566246,
      34.8541226934407
    ]
  },
  "三木市": {
    "code": "2821500",
    "coordinates": [
      135.058944018247,
      34.8353015311765
    ]
  },
  "高砂市": {
    "code": "2821600",
    "coordinates": [
      134.785792156148,
      34.7752404499833
    ]
  },
  "川西市": {
    "code": "2821700",
    "coordinates": [
      135.411045234399,
      34.877472715958
    ]
  },
  "小野市": {
    "code": "2821800",
    "coordinates": [
      134.951537241937,
      34.8587303048968
    ]
  },
  "三田市": {
    "code": "2821900",
    "coordinates": [
      135.217529734742,
      34.9521091314717
    ]
  },
  "加西市": {
    "code": "2822000",
    "coordinates": [
      134.854178662537,
      34.9232223194344
    ]
  },
  "丹波篠山市": {
    "code": "2822100",
    "coordinates": [
      135.231711518566,
      35.0734757324225
    ]
  },
  "養父市": {
    "code": "2822200",
    "coordinates": [
      134.68593427198,
      35.3530559780706
    ]
  },
  "丹波市": {
    "code": "2822300",
    "coordinates": [
      135.049461353189,
      35.1746053612502
    ]
  },
  "南あわじ市": {
    "code": "2822400",
    "coordinates": [
      134.764992339877,
      34.2737892262445
    ]
  },
  "朝来市": {
    "code": "2822500",
    "coordinates": [
      134.825581778134,
      35.2609164169029
    ]
  },
  "淡路市": {
    "code": "2822600",
    "coordinates": [
      134.913376786767,
      34.4947809506502
    ]
  },
  "宍粟市": {
    "code": "2822700",
    "coordinates": [
      134.545234889347,
      35.1537184841172
    ]
  },
  "加東市": {
    "code": "2822800",
    "coordinates": [
      135.022499691518,
      34.927720179139
    ]
  },
  "たつの市": {
    "code": "2822900",
    "coordinates": [
      134.518807114675,
      34.887685586781
    ]
  },
  "猪名川町": {
    "code": "2830100",
    "coordinates": [
      135.349798352132,
      34.9461267243199
    ]
  },
  "多可町": {
    "code": "2836500",
    "coordinates": [
      134.904889111715,
      35.0836115492479
    ]
  },
  "兵庫稲美町": {
    "code": "2838100",
    "coordinates": [
      134.923491341567,
      34.7524034811499
    ]
  },
  "播磨町": {
    "code": "2838200",
    "coordinates": [
      134.864474472751,
      34.7129335324993
    ]
  },
  "市川町": {
    "code": "2844200",
    "coordinates": [
      134.781299870423,
      35.0141368538636
    ]
  },
  "福崎町": {
    "code": "2844300",
    "coordinates": [
      134.751002752876,
      34.9630052041092
    ]
  },
  "兵庫神河町": {
    "code": "2844600",
    "coordinates": [
      134.768817377913,
      35.110556821044
    ]
  },
  "兵庫太子町": {
    "code": "2846400",
    "coordinates": [
      134.591044994622,
      34.8332598018195
    ]
  },
  "上郡町": {
    "code": "2848100",
    "coordinates": [
      134.350383514498,
      34.8865483486783
    ]
  },
  "佐用町": {
    "code": "2850100",
    "coordinates": [
      134.370545965639,
      35.0202947775518
    ]
  },
  "兵庫香美町": {
    "code": "2858500",
    "coordinates": [
      134.590341775347,
      35.523375481943
    ]
  },
  "新温泉町": {
    "code": "2858600",
    "coordinates": [
      134.482728288747,
      35.5596665472835
    ]
  },
  "奈良市": {
    "code": "2920100",
    "coordinates": [
      135.89094032703,
      34.6744794976151
    ]
  },
  "大和高田市": {
    "code": "2920200",
    "coordinates": [
      135.743746294141,
      34.5100735201835
    ]
  },
  "大和郡山市": {
    "code": "2920300",
    "coordinates": [
      135.774724148076,
      34.6339196390734
    ]
  },
  "天理市": {
    "code": "2920400",
    "coordinates": [
      135.864268082339,
      34.595617564253
    ]
  },
  "橿原市": {
    "code": "2920500",
    "coordinates": [
      135.789939282899,
      34.5013969411517
    ]
  },
  "桜井市": {
    "code": "2920600",
    "coordinates": [
      135.879691122932,
      34.5214484486941
    ]
  },
  "五條市": {
    "code": "2920700",
    "coordinates": [
      135.750446259526,
      34.2568217194478
    ]
  },
  "御所市": {
    "code": "2920800",
    "coordinates": [
      135.720952495974,
      34.4331323227288
    ]
  },
  "生駒市": {
    "code": "2920900",
    "coordinates": [
      135.710370887351,
      34.7079264194826
    ]
  },
  "香芝市": {
    "code": "2921000",
    "coordinates": [
      135.690031676618,
      34.5486147457888
    ]
  },
  "葛城市": {
    "code": "2921100",
    "coordinates": [
      135.704632605585,
      34.4968284522859
    ]
  },
  "宇陀市": {
    "code": "2921200",
    "coordinates": [
      135.997859423989,
      34.520358874606
    ]
  },
  "山添村": {
    "code": "2932200",
    "coordinates": [
      136.026891553374,
      34.6641481981804
    ]
  },
  "平群町": {
    "code": "2934200",
    "coordinates": [
      135.688340404267,
      34.6330859955932
    ]
  },
  "三郷町": {
    "code": "2934300",
    "coordinates": [
      135.683922929468,
      34.6035613632054
    ]
  },
  "斑鳩町": {
    "code": "2934400",
    "coordinates": [
      135.730518499688,
      34.6139328457212
    ]
  },
  "安堵町": {
    "code": "2934500",
    "coordinates": [
      135.755375916985,
      34.6018106478405
    ]
  },
  "奈良川西町": {
    "code": "2936100",
    "coordinates": [
      135.772386756978,
      34.5878119159887
    ]
  },
  "三宅町": {
    "code": "2936200",
    "coordinates": [
      135.773916850141,
      34.5736210908404
    ]
  },
  "田原本町": {
    "code": "2936300",
    "coordinates": [
      135.793775963775,
      34.5540625797804
    ]
  },
  "曽爾村": {
    "code": "2938500",
    "coordinates": [
      136.122726017703,
      34.5183547858985
    ]
  },
  "御杖村": {
    "code": "2938600",
    "coordinates": [
      136.159436159867,
      34.4777369198246
    ]
  },
  "高取町": {
    "code": "2940100",
    "coordinates": [
      135.794798426423,
      34.4381367374816
    ]
  },
  "明日香村": {
    "code": "2940200",
    "coordinates": [
      135.828130182231,
      34.4589164163954
    ]
  },
  "上牧町": {
    "code": "2942400",
    "coordinates": [
      135.715453983346,
      34.5654910338272
    ]
  },
  "王寺町": {
    "code": "2942500",
    "coordinates": [
      135.695913111783,
      34.5846828276308
    ]
  },
  "広陵町": {
    "code": "2942600",
    "coordinates": [
      135.746775269698,
      34.5507125536782
    ]
  },
  "河合町": {
    "code": "2942700",
    "coordinates": [
      135.732147700234,
      34.5800776287462
    ]
  },
  "吉野町": {
    "code": "2944100",
    "coordinates": [
      135.893582901599,
      34.3987065426437
    ]
  },
  "大淀町": {
    "code": "2944200",
    "coordinates": [
      135.798532368789,
      34.4028897323455
    ]
  },
  "下市町": {
    "code": "2944300",
    "coordinates": [
      135.806834614488,
      34.3377643972161
    ]
  },
  "黒滝村": {
    "code": "2944400",
    "coordinates": [
      135.86392533424,
      34.3080029866353
    ]
  },
  "天川村": {
    "code": "2944600",
    "coordinates": [
      135.868415293825,
      34.2271744733863
    ]
  },
  "野迫川村": {
    "code": "2944700",
    "coordinates": [
      135.644538479872,
      34.1360953481992
    ]
  },
  "十津川村": {
    "code": "2944900",
    "coordinates": [
      135.764269858854,
      34.0106668914119
    ]
  },
  "下北山村": {
    "code": "2945000",
    "coordinates": [
      135.952323806435,
      34.0453544172854
    ]
  },
  "上北山村": {
    "code": "2945100",
    "coordinates": [
      136.020492609522,
      34.1375601510565
    ]
  },
  "奈良川上村": {
    "code": "2945200",
    "coordinates": [
      136.020432497979,
      34.2926837038368
    ]
  },
  "東吉野村": {
    "code": "2945300",
    "coordinates": [
      136.027919616941,
      34.4066331894737
    ]
  },
  "和歌山市": {
    "code": "3020100",
    "coordinates": [
      135.196807131004,
      34.2417334883173
    ]
  },
  "海南市": {
    "code": "3020200",
    "coordinates": [
      135.225421456045,
      34.1411792491064
    ]
  },
  "橋本市": {
    "code": "3020300",
    "coordinates": [
      135.610150612089,
      34.3227315957859
    ]
  },
  "有田市": {
    "code": "3020400",
    "coordinates": [
      135.142280526362,
      34.0822426337653
    ]
  },
  "御坊市": {
    "code": "3020500",
    "coordinates": [
      135.176000308455,
      33.8803942041656
    ]
  },
  "田辺市": {
    "code": "3020600",
    "coordinates": [
      135.577600786141,
      33.8262617517503
    ]
  },
  "新宮市": {
    "code": "3020700",
    "coordinates": [
      135.859190128842,
      33.766174471743
    ]
  },
  "紀の川市": {
    "code": "3020800",
    "coordinates": [
      135.394112294485,
      34.2540027700715
    ]
  },
  "岩出市": {
    "code": "3020900",
    "coordinates": [
      135.305595120324,
      34.2808986384036
    ]
  },
  "紀美野町": {
    "code": "3030400",
    "coordinates": [
      135.389267073341,
      34.1539133547209
    ]
  },
  "かつらぎ町": {
    "code": "3034100",
    "coordinates": [
      135.512402510774,
      34.2364774399516
    ]
  },
  "九度山町": {
    "code": "3034300",
    "coordinates": [
      135.580126626488,
      34.2661852151775
    ]
  },
  "高野町": {
    "code": "3034400",
    "coordinates": [
      135.609051452867,
      34.2144164614545
    ]
  },
  "湯浅町": {
    "code": "3036100",
    "coordinates": [
      135.192462817773,
      34.035542526754
    ]
  },
  "和歌山広川町": {
    "code": "3036200",
    "coordinates": [
      135.207100249088,
      33.99115062291
    ]
  },
  "有田川町": {
    "code": "3036600",
    "coordinates": [
      135.366403628135,
      34.0719208876816
    ]
  },
  "和歌山美浜町": {
    "code": "3038100",
    "coordinates": [
      135.115470945734,
      33.8956130301189
    ]
  },
  "和歌山日高町": {
    "code": "3038200",
    "coordinates": [
      135.121882537637,
      33.9328348799326
    ]
  },
  "由良町": {
    "code": "3038300",
    "coordinates": [
      135.121823711669,
      33.9759639529235
    ]
  },
  "和歌山印南町": {
    "code": "3039000",
    "coordinates": [
      135.278753247385,
      33.8563283414384
    ]
  },
  "みなべ町": {
    "code": "3039100",
    "coordinates": [
      135.349882217609,
      33.8295519001544
    ]
  },
  "日高川町": {
    "code": "3039200",
    "coordinates": [
      135.358489940446,
      33.959694264112
    ]
  },
  "白浜町": {
    "code": "3040100",
    "coordinates": [
      135.472220284488,
      33.6363553746964
    ]
  },
  "上富田町": {
    "code": "3040400",
    "coordinates": [
      135.457265307912,
      33.7063005419494
    ]
  },
  "すさみ町": {
    "code": "3040600",
    "coordinates": [
      135.570507916229,
      33.5715422391434
    ]
  },
  "那智勝浦町": {
    "code": "3042100",
    "coordinates": [
      135.875267536174,
      33.6424069053635
    ]
  },
  "太地町": {
    "code": "3042200",
    "coordinates": [
      135.941618235996,
      33.5919788096868
    ]
  },
  "古座川町": {
    "code": "3042400",
    "coordinates": [
      135.735598360687,
      33.6111556197002
    ]
  },
  "北山村": {
    "code": "3042700",
    "coordinates": [
      135.951449141789,
      33.9598738909928
    ]
  },
  "串本町": {
    "code": "3042800",
    "coordinates": [
      135.764123182465,
      33.5142790419694
    ]
  },
  "鳥取市": {
    "code": "3120100",
    "coordinates": [
      134.158158763285,
      35.4298234167731
    ]
  },
  "米子市": {
    "code": "3120200",
    "coordinates": [
      133.359338728649,
      35.4340653341065
    ]
  },
  "倉吉市": {
    "code": "3120300",
    "coordinates": [
      133.745589884957,
      35.3905466994551
    ]
  },
  "境港市": {
    "code": "3120400",
    "coordinates": [
      133.231028508644,
      35.5188160474454
    ]
  },
  "岩美町": {
    "code": "3130200",
    "coordinates": [
      134.362514956898,
      35.5446690317793
    ]
  },
  "鳥取若桜町": {
    "code": "3132500",
    "coordinates": [
      134.440424064906,
      35.3227011136849
    ]
  },
  "智頭町": {
    "code": "3132800",
    "coordinates": [
      134.262676080374,
      35.2496028435571
    ]
  },
  "八頭町": {
    "code": "3132900",
    "coordinates": [
      134.320860899132,
      35.3814488888937
    ]
  },
  "三朝町": {
    "code": "3136400",
    "coordinates": [
      133.8939325922,
      35.3527095440186
    ]
  },
  "湯梨浜町": {
    "code": "3137000",
    "coordinates": [
      133.909280246488,
      35.4726836558207
    ]
  },
  "琴浦町": {
    "code": "3137100",
    "coordinates": [
      133.640907386268,
      35.4502710546962
    ]
  },
  "北栄町": {
    "code": "3137200",
    "coordinates": [
      133.768933942728,
      35.4759438899116
    ]
  },
  "日吉津村": {
    "code": "3138400",
    "coordinates": [
      133.37944165053,
      35.4439210457
    ]
  },
  "大山町": {
    "code": "3138600",
    "coordinates": [
      133.528763730985,
      35.4527324755703
    ]
  },
  "鳥取南部町": {
    "code": "3138900",
    "coordinates": [
      133.350094383873,
      35.3205491561974
    ]
  },
  "伯耆町": {
    "code": "3139000",
    "coordinates": [
      133.441823094094,
      35.3333008293949
    ]
  },
  "日南町": {
    "code": "3140100",
    "coordinates": [
      133.26124833199,
      35.1576193867689
    ]
  },
  "鳥取日野町": {
    "code": "3140200",
    "coordinates": [
      133.414913415023,
      35.2097182917104
    ]
  },
  "江府町": {
    "code": "3140300",
    "coordinates": [
      133.521071594994,
      35.2933687446445
    ]
  },
  "松江市": {
    "code": "3220100",
    "coordinates": [
      133.068819611494,
      35.4679314655962
    ]
  },
  "浜田市": {
    "code": "3220200",
    "coordinates": [
      132.141183459597,
      34.8217912535042
    ]
  },
  "出雲市": {
    "code": "3220300",
    "coordinates": [
      132.754610514084,
      35.3454588055847
    ]
  },
  "益田市": {
    "code": "3220400",
    "coordinates": [
      131.944185516662,
      34.6242530233555
    ]
  },
  "大田市": {
    "code": "3220500",
    "coordinates": [
      132.494219682474,
      35.1356756264335
    ]
  },
  "安来市": {
    "code": "3220600",
    "coordinates": [
      133.199541065371,
      35.3339426938595
    ]
  },
  "江津市": {
    "code": "3220700",
    "coordinates": [
      132.295604825632,
      34.9749058534149
    ]
  },
  "雲南市": {
    "code": "3220900",
    "coordinates": [
      132.895460598393,
      35.2435938931598
    ]
  },
  "奥出雲町": {
    "code": "3234300",
    "coordinates": [
      133.041627160444,
      35.1578844234832
    ]
  },
  "飯南町": {
    "code": "3238600",
    "coordinates": [
      132.737117958602,
      35.0610536548604
    ]
  },
  "川本町": {
    "code": "3244100",
    "coordinates": [
      132.478184455932,
      34.9904111928927
    ]
  },
  "島根美郷町": {
    "code": "3244800",
    "coordinates": [
      132.599138387626,
      35.0295821336332
    ]
  },
  "邑南町": {
    "code": "3244900",
    "coordinates": [
      132.501697759689,
      34.8767979164723
    ]
  },
  "津和野町": {
    "code": "3250100",
    "coordinates": [
      131.808342335224,
      34.5125760063501
    ]
  },
  "吉賀町": {
    "code": "3250500",
    "coordinates": [
      131.891872874029,
      34.3944572044589
    ]
  },
  "海士町": {
    "code": "3252500",
    "coordinates": [
      133.100364833199,
      36.0846464743151
    ]
  },
  "西ノ島町": {
    "code": "3252600",
    "coordinates": [
      133.015847664981,
      36.0964203415214
    ]
  },
  "知夫村": {
    "code": "3252700",
    "coordinates": [
      133.032129046496,
      36.0141968937683
    ]
  },
  "隠岐の島町": {
    "code": "3252800",
    "coordinates": [
      133.278201865094,
      36.2520427413049
    ]
  },
  "岡山北区": {
    "code": "3310100",
    "coordinates": [
      133.88104690992,
      34.7720475523386
    ]
  },
  "岡山中区": {
    "code": "3310200",
    "coordinates": [
      133.965960386698,
      34.6607695361824
    ]
  },
  "岡山東区": {
    "code": "3310300",
    "coordinates": [
      134.050433206594,
      34.6769311653701
    ]
  },
  "岡山南区": {
    "code": "3310400",
    "coordinates": [
      133.905327099826,
      34.5839354641017
    ]
  },
  "倉敷市": {
    "code": "3320200",
    "coordinates": [
      133.746740943781,
      34.5578550187031
    ]
  },
  "津山市": {
    "code": "3320300",
    "coordinates": [
      134.02994175405,
      35.138553196798
    ]
  },
  "玉野市": {
    "code": "3320400",
    "coordinates": [
      133.935939969254,
      34.5170205183586
    ]
  },
  "笠岡市": {
    "code": "3320500",
    "coordinates": [
      133.511705217973,
      34.5013541263949
    ]
  },
  "井原市": {
    "code": "3320700",
    "coordinates": [
      133.473699402225,
      34.6503309942975
    ]
  },
  "総社市": {
    "code": "3320800",
    "coordinates": [
      133.702166378176,
      34.7026976539029
    ]
  },
  "高梁市": {
    "code": "3320900",
    "coordinates": [
      133.522534821397,
      34.8171915418311
    ]
  },
  "新見市": {
    "code": "3321000",
    "coordinates": [
      133.448995728286,
      35.0157178095954
    ]
  },
  "備前市": {
    "code": "3321100",
    "coordinates": [
      134.234018434834,
      34.7931541449838
    ]
  },
  "瀬戸内市": {
    "code": "3321200",
    "coordinates": [
      134.141604452002,
      34.6653914120829
    ]
  },
  "赤磐市": {
    "code": "3321300",
    "coordinates": [
      134.039477307605,
      34.83367280936
    ]
  },
  "真庭市": {
    "code": "3321400",
    "coordinates": [
      133.693566597053,
      35.1354892763603
    ]
  },
  "美作市": {
    "code": "3321500",
    "coordinates": [
      134.229944642166,
      35.0428364788304
    ]
  },
  "浅口市": {
    "code": "3321600",
    "coordinates": [
      133.594555187822,
      34.5360764807993
    ]
  },
  "和気町": {
    "code": "3334600",
    "coordinates": [
      134.137873335564,
      34.8386927681813
    ]
  },
  "早島町": {
    "code": "3342300",
    "coordinates": [
      133.822909673898,
      34.6085182786416
    ]
  },
  "里庄町": {
    "code": "3344500",
    "coordinates": [
      133.556776290964,
      34.5131320956113
    ]
  },
  "矢掛町": {
    "code": "3346100",
    "coordinates": [
      133.589816384924,
      34.6309968343147
    ]
  },
  "新庄村": {
    "code": "3358600",
    "coordinates": [
      133.56041159906,
      35.2032263023776
    ]
  },
  "鏡野町": {
    "code": "3360600",
    "coordinates": [
      133.908892522316,
      35.2061025562788
    ]
  },
  "勝央町": {
    "code": "3362200",
    "coordinates": [
      134.130318026014,
      35.0579576509844
    ]
  },
  "奈義町": {
    "code": "3362300",
    "coordinates": [
      134.184509245804,
      35.1362506851425
    ]
  },
  "西粟倉村": {
    "code": "3364300",
    "coordinates": [
      134.340944534538,
      35.193304353094
    ]
  },
  "久米南町": {
    "code": "3366300",
    "coordinates": [
      133.957826898367,
      34.9223953371094
    ]
  },
  "岡山美咲町": {
    "code": "3366600",
    "coordinates": [
      133.938291343264,
      34.9836467849256
    ]
  },
  "吉備中央町": {
    "code": "3368100",
    "coordinates": [
      133.750985197547,
      34.8585663217862
    ]
  },
  "広島中区": {
    "code": "3410100",
    "coordinates": [
      132.449131339946,
      34.3811074770782
    ]
  },
  "広島東区": {
    "code": "3410200",
    "coordinates": [
      132.514028978353,
      34.4285011933206
    ]
  },
  "広島南区": {
    "code": "3410300",
    "coordinates": [
      132.47359107916,
      34.3592074657286
    ]
  },
  "広島西区": {
    "code": "3410400",
    "coordinates": [
      132.416177008596,
      34.3931314369672
    ]
  },
  "広島安佐南区": {
    "code": "3410500",
    "coordinates": [
      132.410408273346,
      34.4688047344754
    ]
  },
  "広島安佐北区": {
    "code": "3410600",
    "coordinates": [
      132.526907491128,
      34.5364794717775
    ]
  },
  "広島安芸区": {
    "code": "3410700",
    "coordinates": [
      132.590628584363,
      34.398289181503
    ]
  },
  "広島佐伯区": {
    "code": "3410800",
    "coordinates": [
      132.294854018312,
      34.4588174525932
    ]
  },
  "呉市": {
    "code": "3420200",
    "coordinates": [
      132.630455897484,
      34.2237469037533
    ]
  },
  "竹原市": {
    "code": "3420300",
    "coordinates": [
      132.882082085584,
      34.3679406700024
    ]
  },
  "三原市": {
    "code": "3420400",
    "coordinates": [
      133.003843683207,
      34.4564915113856
    ]
  },
  "尾道市": {
    "code": "3420500",
    "coordinates": [
      133.162746305979,
      34.4272347310334
    ]
  },
  "福山市": {
    "code": "3420700",
    "coordinates": [
      133.335782284378,
      34.5209273927262
    ]
  },
  "広島府中市": {
    "code": "3420800",
    "coordinates": [
      133.175055588598,
      34.6364906839789
    ]
  },
  "広島三次市": {
    "code": "3420900",
    "coordinates": [
      132.866075165336,
      34.7931091607934
    ]
  },
  "庄原市": {
    "code": "3421000",
    "coordinates": [
      133.066508016682,
      34.9455868079183
    ]
  },
  "大竹市": {
    "code": "3421100",
    "coordinates": [
      132.188525348596,
      34.2606053196192
    ]
  },
  "東広島市": {
    "code": "3421200",
    "coordinates": [
      132.761069585127,
      34.4476287864328
    ]
  },
  "廿日市市": {
    "code": "3421300",
    "coordinates": [
      132.185821375527,
      34.3977680362746
    ]
  },
  "安芸高田市": {
    "code": "3421400",
    "coordinates": [
      132.678388029581,
      34.7029602414268
    ]
  },
  "江田島市": {
    "code": "3421500",
    "coordinates": [
      132.44393330993,
      34.2143824698412
    ]
  },
  "府中町": {
    "code": "3430200",
    "coordinates": [
      132.51738956464,
      34.3974899061011
    ]
  },
  "海田町": {
    "code": "3430400",
    "coordinates": [
      132.552840850729,
      34.3667579039319
    ]
  },
  "熊野町": {
    "code": "3430700",
    "coordinates": [
      132.591677157518,
      34.3458794399775
    ]
  },
  "坂町": {
    "code": "3430900",
    "coordinates": [
      132.513972053339,
      34.3243582907691
    ]
  },
  "安芸太田町": {
    "code": "3436800",
    "coordinates": [
      132.256088867729,
      34.6031358025912
    ]
  },
  "北広島町": {
    "code": "3436900",
    "coordinates": [
      132.40105175851,
      34.7070626908755
    ]
  },
  "大崎上島町": {
    "code": "3443100",
    "coordinates": [
      132.892419058548,
      34.2424665702157
    ]
  },
  "世羅町": {
    "code": "3446200",
    "coordinates": [
      133.01680620266,
      34.6141216677313
    ]
  },
  "神石高原町": {
    "code": "3454500",
    "coordinates": [
      133.261575192621,
      34.7554003506128
    ]
  },
  "下関市": {
    "code": "3520100",
    "coordinates": [
      130.990949408645,
      34.1689483888155
    ]
  },
  "宇部市": {
    "code": "3520200",
    "coordinates": [
      131.275166615398,
      34.0442404541444
    ]
  },
  "山口市": {
    "code": "3520300",
    "coordinates": [
      131.576056890272,
      34.2333822578843
    ]
  },
  "萩市": {
    "code": "3520400",
    "coordinates": [
      131.509500065255,
      34.432007683974
    ]
  },
  "防府市": {
    "code": "3520600",
    "coordinates": [
      131.571587791008,
      34.0689905816804
    ]
  },
  "下松市": {
    "code": "3520700",
    "coordinates": [
      131.884453117907,
      34.0300338457834
    ]
  },
  "岩国市": {
    "code": "3520800",
    "coordinates": [
      132.066659813194,
      34.205822163375
    ]
  },
  "光市": {
    "code": "3521000",
    "coordinates": [
      131.977769781528,
      33.9801995542051
    ]
  },
  "長門市": {
    "code": "3521100",
    "coordinates": [
      131.145866379296,
      34.3496385667134
    ]
  },
  "柳井市": {
    "code": "3521200",
    "coordinates": [
      132.137017102703,
      33.9572482520995
    ]
  },
  "美祢市": {
    "code": "3521300",
    "coordinates": [
      131.252540555162,
      34.2095054495366
    ]
  },
  "周南市": {
    "code": "3521500",
    "coordinates": [
      131.826672242963,
      34.1490084052615
    ]
  },
  "山陽小野田市": {
    "code": "3521600",
    "coordinates": [
      131.157619158684,
      34.0402915294662
    ]
  },
  "周防大島町": {
    "code": "3530500",
    "coordinates": [
      132.282538162812,
      33.9147616391458
    ]
  },
  "和木町": {
    "code": "3532100",
    "coordinates": [
      132.211554192827,
      34.1956307665438
    ]
  },
  "上関町": {
    "code": "3534100",
    "coordinates": [
      132.070501655632,
      33.8063600463048
    ]
  },
  "田布施町": {
    "code": "3534300",
    "coordinates": [
      132.03055593553,
      33.9519673321657
    ]
  },
  "平生町": {
    "code": "3534400",
    "coordinates": [
      132.087949380532,
      33.9163155731624
    ]
  },
  "阿武町": {
    "code": "3550200",
    "coordinates": [
      131.552874240725,
      34.530809013516
    ]
  },
  "徳島市": {
    "code": "3620100",
    "coordinates": [
      134.523851849836,
      34.0496250953989
    ]
  },
  "鳴門市": {
    "code": "3620200",
    "coordinates": [
      134.549109356479,
      34.1879005089428
    ]
  },
  "小松島市": {
    "code": "3620300",
    "coordinates": [
      134.595559480393,
      33.9842090501119
    ]
  },
  "阿南市": {
    "code": "3620400",
    "coordinates": [
      134.606498594069,
      33.8770218242539
    ]
  },
  "吉野川市": {
    "code": "3620500",
    "coordinates": [
      134.283530190401,
      34.0370579886648
    ]
  },
  "阿波市": {
    "code": "3620600",
    "coordinates": [
      134.288955997202,
      34.1218395511599
    ]
  },
  "美馬市": {
    "code": "3620700",
    "coordinates": [
      134.143321237422,
      34.0188989029644
    ]
  },
  "徳島三好市": {
    "code": "3620800",
    "coordinates": [
      133.851834871394,
      33.9334918213139
    ]
  },
  "勝浦町": {
    "code": "3630100",
    "coordinates": [
      134.489550570102,
      33.9285958228218
    ]
  },
  "上勝町": {
    "code": "3630200",
    "coordinates": [
      134.383339102227,
      33.9001831718046
    ]
  },
  "佐那河内村": {
    "code": "3632100",
    "coordinates": [
      134.452534351128,
      33.9825345490693
    ]
  },
  "石井町": {
    "code": "3634100",
    "coordinates": [
      134.433560967237,
      34.0779937786669
    ]
  },
  "神山町": {
    "code": "3634200",
    "coordinates": [
      134.33712552853,
      33.9769805236222
    ]
  },
  "那賀町": {
    "code": "3636800",
    "coordinates": [
      134.262934380348,
      33.800128531205
    ]
  },
  "牟岐町": {
    "code": "3638300",
    "coordinates": [
      134.417224263876,
      33.6866971161319
    ]
  },
  "美波町": {
    "code": "3638700",
    "coordinates": [
      134.511882062211,
      33.7530081951842
    ]
  },
  "海陽町": {
    "code": "3638800",
    "coordinates": [
      134.273187158093,
      33.6457517663808
    ]
  },
  "松茂町": {
    "code": "3640100",
    "coordinates": [
      134.591732987319,
      34.1356335541034
    ]
  },
  "北島町": {
    "code": "3640200",
    "coordinates": [
      134.549961385039,
      34.1274116672271
    ]
  },
  "藍住町": {
    "code": "3640300",
    "coordinates": [
      134.494471821472,
      34.1265665169597
    ]
  },
  "板野町": {
    "code": "3640400",
    "coordinates": [
      134.452363758504,
      34.1526043124639
    ]
  },
  "上板町": {
    "code": "3640500",
    "coordinates": [
      134.406085157387,
      34.1307646272301
    ]
  },
  "つるぎ町": {
    "code": "3646800",
    "coordinates": [
      134.053673997586,
      33.9624611863052
    ]
  },
  "東みよし町": {
    "code": "3648900",
    "coordinates": [
      133.917873815331,
      34.0356013502037
    ]
  },
  "高松市": {
    "code": "3720100",
    "coordinates": [
      134.0541718229,
      34.2694539554497
    ]
  },
  "丸亀市": {
    "code": "3720200",
    "coordinates": [
      133.815939904484,
      34.283133740474
    ]
  },
  "坂出市": {
    "code": "3720300",
    "coordinates": [
      133.892154305019,
      34.3234013145347
    ]
  },
  "善通寺市": {
    "code": "3720400",
    "coordinates": [
      133.777751125835,
      34.2241814277468
    ]
  },
  "観音寺市": {
    "code": "3720500",
    "coordinates": [
      133.674824429099,
      34.0793241055657
    ]
  },
  "さぬき市": {
    "code": "3720600",
    "coordinates": [
      134.213188234021,
      34.2664055943504
    ]
  },
  "東かがわ市": {
    "code": "3720700",
    "coordinates": [
      134.333136510747,
      34.2119473935487
    ]
  },
  "三豊市": {
    "code": "3720800",
    "coordinates": [
      133.724698391836,
      34.162209952458
    ]
  },
  "土庄町": {
    "code": "3732200",
    "coordinates": [
      134.19844457477,
      34.5074201472053
    ]
  },
  "小豆島町": {
    "code": "3732400",
    "coordinates": [
      134.293721155122,
      34.4905456908534
    ]
  },
  "三木町": {
    "code": "3734100",
    "coordinates": [
      134.138179713618,
      34.2333905285304
    ]
  },
  "直島町": {
    "code": "3736400",
    "coordinates": [
      133.988935676269,
      34.4675763588789
    ]
  },
  "宇多津町": {
    "code": "3738600",
    "coordinates": [
      133.826384735554,
      34.3086911335702
    ]
  },
  "綾川町": {
    "code": "3738700",
    "coordinates": [
      133.962359996629,
      34.2099750253408
    ]
  },
  "琴平町": {
    "code": "3740300",
    "coordinates": [
      133.81812475898,
      34.195452746253
    ]
  },
  "多度津町": {
    "code": "3740400",
    "coordinates": [
      133.728069201715,
      34.2703924897141
    ]
  },
  "まんのう町": {
    "code": "3740600",
    "coordinates": [
      133.912325758234,
      34.1412875693931
    ]
  },
  "松山市": {
    "code": "3820100",
    "coordinates": [
      132.791144075613,
      33.8830358721767
    ]
  },
  "今治市": {
    "code": "3820200",
    "coordinates": [
      132.975838786148,
      34.078362351288
    ]
  },
  "宇和島市": {
    "code": "3820300",
    "coordinates": [
      132.554905099588,
      33.1772709682107
    ]
  },
  "八幡浜市": {
    "code": "3820400",
    "coordinates": [
      132.431250503463,
      33.4716777734571
    ]
  },
  "新居浜市": {
    "code": "3820500",
    "coordinates": [
      133.329715120211,
      33.9005266495757
    ]
  },
  "西条市": {
    "code": "3820600",
    "coordinates": [
      133.112259172222,
      33.8613094465072
    ]
  },
  "大洲市": {
    "code": "3820700",
    "coordinates": [
      132.598272818242,
      33.5201688482476
    ]
  },
  "伊予市": {
    "code": "3821000",
    "coordinates": [
      132.689404220096,
      33.681642740727
    ]
  },
  "四国中央市": {
    "code": "3821300",
    "coordinates": [
      133.536217660948,
      33.9354946838549
    ]
  },
  "西予市": {
    "code": "3821400",
    "coordinates": [
      132.632609297993,
      33.3940804939264
    ]
  },
  "東温市": {
    "code": "3821500",
    "coordinates": [
      132.926742129538,
      33.8037071434009
    ]
  },
  "上島町": {
    "code": "3835600",
    "coordinates": [
      133.193043668091,
      34.251668308188
    ]
  },
  "久万高原町": {
    "code": "3838600",
    "coordinates": [
      132.98496664588,
      33.6356081675831
    ]
  },
  "愛媛松前町": {
    "code": "3840100",
    "coordinates": [
      132.72563132931,
      33.7917960414533
    ]
  },
  "砥部町": {
    "code": "3840200",
    "coordinates": [
      132.789183346683,
      33.6949866239695
    ]
  },
  "内子町": {
    "code": "3842200",
    "coordinates": [
      132.757054617783,
      33.5696447786273
    ]
  },
  "伊方町": {
    "code": "3844200",
    "coordinates": [
      132.213061821569,
      33.4339393343046
    ]
  },
  "松野町": {
    "code": "3848400",
    "coordinates": [
      132.71171630466,
      33.2053187950428
    ]
  },
  "愛媛鬼北町": {
    "code": "3848800",
    "coordinates": [
      132.749513659094,
      33.2998752375868
    ]
  },
  "愛南町": {
    "code": "3850600",
    "coordinates": [
      132.58127160321,
      32.9885584858321
    ]
  },
  "高知市": {
    "code": "3920100",
    "coordinates": [
      133.508194698315,
      33.5803361493244
    ]
  },
  "室戸市": {
    "code": "3920200",
    "coordinates": [
      134.148254587065,
      33.3834624215879
    ]
  },
  "安芸市": {
    "code": "3920300",
    "coordinates": [
      133.954316191059,
      33.5879522743431
    ]
  },
  "南国市": {
    "code": "3920400",
    "coordinates": [
      133.625283184826,
      33.6098291439001
    ]
  },
  "土佐市": {
    "code": "3920500",
    "coordinates": [
      133.390056428375,
      33.4752502565408
    ]
  },
  "須崎市": {
    "code": "3920600",
    "coordinates": [
      133.304003997586,
      33.4116743435343
    ]
  },
  "宿毛市": {
    "code": "3920800",
    "coordinates": [
      132.734283584982,
      32.9613691363624
    ]
  },
  "土佐清水市": {
    "code": "3920900",
    "coordinates": [
      132.895520096052,
      32.8269825858031
    ]
  },
  "四万十市": {
    "code": "3921000",
    "coordinates": [
      132.852146926082,
      33.0815986803221
    ]
  },
  "高知香南市": {
    "code": "3921100",
    "coordinates": [
      133.780163445217,
      33.583591604047
    ]
  },
  "香美市": {
    "code": "3921200",
    "coordinates": [
      133.853214489436,
      33.7081634911721
    ]
  },
  "東洋町": {
    "code": "3930100",
    "coordinates": [
      134.240728461739,
      33.5179267383112
    ]
  },
  "奈半利町": {
    "code": "3930200",
    "coordinates": [
      134.056450143743,
      33.4203578523297
    ]
  },
  "田野町": {
    "code": "3930300",
    "coordinates": [
      134.012528704493,
      33.4388250037237
    ]
  },
  "安田町": {
    "code": "3930400",
    "coordinates": [
      134.007560313398,
      33.4880984443204
    ]
  },
  "北川村": {
    "code": "3930500",
    "coordinates": [
      134.118825101272,
      33.5174161775974
    ]
  },
  "馬路村": {
    "code": "3930600",
    "coordinates": [
      134.089683519848,
      33.6227736674404
    ]
  },
  "芸西村": {
    "code": "3930700",
    "coordinates": [
      133.827173736412,
      33.5485901076669
    ]
  },
  "本山町": {
    "code": "3934100",
    "coordinates": [
      133.58162566226,
      33.7918927325178
    ]
  },
  "大豊町": {
    "code": "3934400",
    "coordinates": [
      133.720503436508,
      33.7915623616346
    ]
  },
  "土佐町": {
    "code": "3936300",
    "coordinates": [
      133.482389099768,
      33.7335656566291
    ]
  },
  "大川村": {
    "code": "3936400",
    "coordinates": [
      133.41289307513,
      33.8006598793352
    ]
  },
  "いの町": {
    "code": "3938600",
    "coordinates": [
      133.312091700281,
      33.6807805318931
    ]
  },
  "仁淀川町": {
    "code": "3938700",
    "coordinates": [
      133.130839609274,
      33.582441530674
    ]
  },
  "中土佐町": {
    "code": "3940100",
    "coordinates": [
      133.167013086743,
      33.3390639710404
    ]
  },
  "佐川町": {
    "code": "3940200",
    "coordinates": [
      133.273686546768,
      33.497391231565
    ]
  },
  "越知町": {
    "code": "3940300",
    "coordinates": [
      133.221867233031,
      33.5430734732288
    ]
  },
  "梼原町": {
    "code": "3940500",
    "coordinates": [
      132.92548119101,
      33.3956781536785
    ]
  },
  "日高村": {
    "code": "3941000",
    "coordinates": [
      133.35431009203,
      33.5389681477156
    ]
  },
  "高知津野町": {
    "code": "3941100",
    "coordinates": [
      133.085211448932,
      33.4231820119858
    ]
  },
  "四万十町": {
    "code": "3941200",
    "coordinates": [
      133.020378294533,
      33.233543457855
    ]
  },
  "大月町": {
    "code": "3942400",
    "coordinates": [
      132.708558138396,
      32.8144800527793
    ]
  },
  "三原村": {
    "code": "3942700",
    "coordinates": [
      132.851522170879,
      32.9024984549033
    ]
  },
  "黒潮町": {
    "code": "3942800",
    "coordinates": [
      133.059452433808,
      33.0841091846384
    ]
  },
  "北九州門司区": {
    "code": "4010100",
    "coordinates": [
      130.970940108923,
      33.9078308848516
    ]
  },
  "北九州若松区": {
    "code": "4010300",
    "coordinates": [
      130.751757433229,
      33.9164192844504
    ]
  },
  "北九州戸畑区": {
    "code": "4010500",
    "coordinates": [
      130.834823647979,
      33.9011147511662
    ]
  },
  "北九州小倉北区": {
    "code": "4010600",
    "coordinates": [
      130.876257675763,
      33.8790811175608
    ]
  },
  "北九州小倉南区": {
    "code": "4010700",
    "coordinates": [
      130.882342911798,
      33.7988008213819
    ]
  },
  "北九州八幡東区": {
    "code": "4010800",
    "coordinates": [
      130.808945720483,
      33.8497952511999
    ]
  },
  "北九州八幡西区": {
    "code": "4010900",
    "coordinates": [
      130.747276460334,
      33.8319679128528
    ]
  },
  "福岡東区": {
    "code": "4013100",
    "coordinates": [
      130.41848874983,
      33.656721057679
    ]
  },
  "福岡博多区": {
    "code": "4013200",
    "coordinates": [
      130.44350473586,
      33.579897964269
    ]
  },
  "福岡中央区": {
    "code": "4013300",
    "coordinates": [
      130.386241884308,
      33.5838991354905
    ]
  },
  "福岡南区": {
    "code": "4013400",
    "coordinates": [
      130.410041177808,
      33.5416097000017
    ]
  },
  "福岡西区": {
    "code": "4013500",
    "coordinates": [
      130.269281222552,
      33.5852661304066
    ]
  },
  "福岡城南区": {
    "code": "4013600",
    "coordinates": [
      130.370353774139,
      33.5488233558576
    ]
  },
  "福岡早良区": {
    "code": "4013700",
    "coordinates": [
      130.340921527094,
      33.4962287707503
    ]
  },
  "大牟田市": {
    "code": "4020200",
    "coordinates": [
      130.469108239486,
      33.0461675192234
    ]
  },
  "久留米市": {
    "code": "4020300",
    "coordinates": [
      130.567108999833,
      33.3053887354885
    ]
  },
  "直方市": {
    "code": "4020400",
    "coordinates": [
      130.745908508596,
      33.7511667528004
    ]
  },
  "飯塚市": {
    "code": "4020500",
    "coordinates": [
      130.660974031263,
      33.6135347584468
    ]
  },
  "田川市": {
    "code": "4020600",
    "coordinates": [
      130.799647553876,
      33.6315948140292
    ]
  },
  "柳川市": {
    "code": "4020700",
    "coordinates": [
      130.409748305023,
      33.1483379395513
    ]
  },
  "八女市": {
    "code": "4021000",
    "coordinates": [
      130.702000025259,
      33.1987687524168
    ]
  },
  "筑後市": {
    "code": "4021100",
    "coordinates": [
      130.491212299388,
      33.2074888538266
    ]
  },
  "大川市": {
    "code": "4021200",
    "coordinates": [
      130.381835432075,
      33.2057933711629
    ]
  },
  "行橋市": {
    "code": "4021300",
    "coordinates": [
      130.971073808616,
      33.7140426222022
    ]
  },
  "豊前市": {
    "code": "4021400",
    "coordinates": [
      131.0776383977,
      33.5686453685269
    ]
  },
  "中間市": {
    "code": "4021500",
    "coordinates": [
      130.706589292281,
      33.8204922236889
    ]
  },
  "小郡市": {
    "code": "4021600",
    "coordinates": [
      130.564419083068,
      33.4003209069958
    ]
  },
  "筑紫野市": {
    "code": "4021700",
    "coordinates": [
      130.543860847795,
      33.4851535598114
    ]
  },
  "春日市": {
    "code": "4021800",
    "coordinates": [
      130.4563967805,
      33.5240431974134
    ]
  },
  "大野城市": {
    "code": "4021900",
    "coordinates": [
      130.479240695192,
      33.5130292190245
    ]
  },
  "宗像市": {
    "code": "4022000",
    "coordinates": [
      130.544504441209,
      33.8228779128721
    ]
  },
  "太宰府市": {
    "code": "4022100",
    "coordinates": [
      130.525161562519,
      33.5201788395126
    ]
  },
  "福岡古賀市": {
    "code": "4022300",
    "coordinates": [
      130.499462225048,
      33.7213924317443
    ]
  },
  "福津市": {
    "code": "4022400",
    "coordinates": [
      130.498015838081,
      33.7798175125326
    ]
  },
  "うきは市": {
    "code": "4022500",
    "coordinates": [
      130.793235911316,
      33.3118388719501
    ]
  },
  "宮若市": {
    "code": "4022600",
    "coordinates": [
      130.61505022041,
      33.7155903704758
    ]
  },
  "嘉麻市": {
    "code": "4022700",
    "coordinates": [
      130.740267980418,
      33.536636348824
    ]
  },
  "朝倉市": {
    "code": "4022800",
    "coordinates": [
      130.741661180866,
      33.4168447309279
    ]
  },
  "みやま市": {
    "code": "4022900",
    "coordinates": [
      130.496216657805,
      33.1260258171101
    ]
  },
  "糸島市": {
    "code": "4023000",
    "coordinates": [
      130.180363417584,
      33.5333389981301
    ]
  },
  "那珂川市": {
    "code": "4023100",
    "coordinates": [
      130.422064469354,
      33.4647701816433
    ]
  },
  "宇美町": {
    "code": "4034100",
    "coordinates": [
      130.536883138335,
      33.5629150598313
    ]
  },
  "篠栗町": {
    "code": "4034200",
    "coordinates": [
      130.547160976385,
      33.6245601528354
    ]
  },
  "志免町": {
    "code": "4034300",
    "coordinates": [
      130.47629030077,
      33.58756218119
    ]
  },
  "須恵町": {
    "code": "4034400",
    "coordinates": [
      130.518692015464,
      33.5923625525241
    ]
  },
  "新宮町": {
    "code": "4034500",
    "coordinates": [
      130.457136720776,
      33.7020169086921
    ]
  },
  "久山町": {
    "code": "4034800",
    "coordinates": [
      130.516704746914,
      33.6668206998974
    ]
  },
  "粕屋町": {
    "code": "4034900",
    "coordinates": [
      130.474547855211,
      33.6158388159631
    ]
  },
  "芦屋町": {
    "code": "4038100",
    "coordinates": [
      130.662832488608,
      33.8910777544153
    ]
  },
  "水巻町": {
    "code": "4038200",
    "coordinates": [
      130.693878276566,
      33.8557351160822
    ]
  },
  "岡垣町": {
    "code": "4038300",
    "coordinates": [
      130.604731895869,
      33.8541467772222
    ]
  },
  "遠賀町": {
    "code": "4038400",
    "coordinates": [
      130.662620660033,
      33.8457744516092
    ]
  },
  "小竹町": {
    "code": "4040100",
    "coordinates": [
      130.704389037134,
      33.704945513762
    ]
  },
  "鞍手町": {
    "code": "4040200",
    "coordinates": [
      130.669695214085,
      33.7841802953768
    ]
  },
  "桂川町": {
    "code": "4042100",
    "coordinates": [
      130.679625816442,
      33.5745465701981
    ]
  },
  "筑前町": {
    "code": "4044700",
    "coordinates": [
      130.619220826819,
      33.4608775778434
    ]
  },
  "東峰村": {
    "code": "4044800",
    "coordinates": [
      130.849231182874,
      33.4370975428668
    ]
  },
  "大刀洗町": {
    "code": "4050300",
    "coordinates": [
      130.614983204564,
      33.3809488786596
    ]
  },
  "大木町": {
    "code": "4052200",
    "coordinates": [
      130.440838018467,
      33.2126226361612
    ]
  },
  "福岡広川町": {
    "code": "4054400",
    "coordinates": [
      130.572563649229,
      33.2521310936988
    ]
  },
  "香春町": {
    "code": "4060100",
    "coordinates": [
      130.854768745783,
      33.6847127688581
    ]
  },
  "添田町": {
    "code": "4060200",
    "coordinates": [
      130.879045592128,
      33.5139974122896
    ]
  },
  "糸田町": {
    "code": "4060400",
    "coordinates": [
      130.77248849834,
      33.6553689505816
    ]
  },
  "福岡川崎町": {
    "code": "4060500",
    "coordinates": [
      130.814614290955,
      33.5705273199833
    ]
  },
  "大任町": {
    "code": "4060800",
    "coordinates": [
      130.851765566255,
      33.6121780519261
    ]
  },
  "赤村": {
    "code": "4060900",
    "coordinates": [
      130.887858886849,
      33.6065932153583
    ]
  },
  "福智町": {
    "code": "4061000",
    "coordinates": [
      130.786258677737,
      33.701444220094
    ]
  },
  "苅田町": {
    "code": "4062100",
    "coordinates": [
      130.967790596566,
      33.7702136486099
    ]
  },
  "みやこ町": {
    "code": "4062500",
    "coordinates": [
      130.936019642442,
      33.6294763502526
    ]
  },
  "吉富町": {
    "code": "4064200",
    "coordinates": [
      131.170534857924,
      33.6053361626306
    ]
  },
  "上毛町": {
    "code": "4064600",
    "coordinates": [
      131.140005493829,
      33.5415129539078
    ]
  },
  "築上町": {
    "code": "4064700",
    "coordinates": [
      131.015702120609,
      33.6082261007094
    ]
  },
  "佐賀市": {
    "code": "4120100",
    "coordinates": [
      130.265103992119,
      33.3251702444873
    ]
  },
  "唐津市": {
    "code": "4120200",
    "coordinates": [
      129.99816929424,
      33.4171266024807
    ]
  },
  "鳥栖市": {
    "code": "4120300",
    "coordinates": [
      130.491655926123,
      33.3787451966062
    ]
  },
  "多久市": {
    "code": "4120400",
    "coordinates": [
      130.10531191922,
      33.2763057933465
    ]
  },
  "伊万里市": {
    "code": "4120500",
    "coordinates": [
      129.887905114092,
      33.3017411137784
    ]
  },
  "武雄市": {
    "code": "4120600",
    "coordinates": [
      129.997732263237,
      33.2015661103116
    ]
  },
  "佐賀鹿島市": {
    "code": "4120700",
    "coordinates": [
      130.095023462709,
      33.0603927491498
    ]
  },
  "小城市": {
    "code": "4120800",
    "coordinates": [
      130.196550858495,
      33.2789247013601
    ]
  },
  "嬉野市": {
    "code": "4120900",
    "coordinates": [
      130.01529462729,
      33.0949119701714
    ]
  },
  "神埼市": {
    "code": "4121000",
    "coordinates": [
      130.351535535138,
      33.3474653096469
    ]
  },
  "吉野ヶ里町": {
    "code": "4132700",
    "coordinates": [
      130.396504686394,
      33.3635763355064
    ]
  },
  "基山町": {
    "code": "4134100",
    "coordinates": [
      130.5095219935,
      33.4259744464363
    ]
  },
  "上峰町": {
    "code": "4134500",
    "coordinates": [
      130.422094813531,
      33.326593757648
    ]
  },
  "みやき町": {
    "code": "4134600",
    "coordinates": [
      130.443132446564,
      33.3280488228409
    ]
  },
  "玄海町": {
    "code": "4138700",
    "coordinates": [
      129.867658173747,
      33.4768525815291
    ]
  },
  "有田町": {
    "code": "4140100",
    "coordinates": [
      129.864900756687,
      33.1992867333419
    ]
  },
  "大町町": {
    "code": "4142300",
    "coordinates": [
      130.114953031351,
      33.2175232318632
    ]
  },
  "江北町": {
    "code": "4142400",
    "coordinates": [
      130.157326986039,
      33.2190882296404
    ]
  },
  "白石町": {
    "code": "4142500",
    "coordinates": [
      130.146160382349,
      33.164561116852
    ]
  },
  "太良町": {
    "code": "4144100",
    "coordinates": [
      130.15146617096,
      32.9949519902469
    ]
  },
  "長崎市": {
    "code": "4220100",
    "coordinates": [
      129.827146269456,
      32.7820652541594
    ]
  },
  "佐世保市": {
    "code": "4220201",
    "coordinates": [
      129.717624844612,
      33.1999350027051
    ]
  },
  "佐世保市宇久島": {
    "code": "4220202",
    "coordinates": [
      129.111425158181,
      33.2707991189791
    ]
  },
  "島原市": {
    "code": "4220300",
    "coordinates": [
      130.329246176087,
      32.794288788458
    ]
  },
  "諫早市": {
    "code": "4220400",
    "coordinates": [
      130.063606546177,
      32.869063422781
    ]
  },
  "大村市": {
    "code": "4220500",
    "coordinates": [
      129.992964044345,
      32.9397830817896
    ]
  },
  "平戸市": {
    "code": "4220700",
    "coordinates": [
      129.48687071537,
      33.3159692942235
    ]
  },
  "松浦市": {
    "code": "4220800",
    "coordinates": [
      129.735010573907,
      33.3533445391151
    ]
  },
  "長崎対馬市": {
    "code": "4220900",
    "coordinates": [
      129.32597991825,
      34.4084515600988
    ]
  },
  "壱岐市": {
    "code": "4221000",
    "coordinates": [
      129.716691163743,
      33.7855297970237
    ]
  },
  "五島市": {
    "code": "4221100",
    "coordinates": [
      128.768697732097,
      32.6989135864414
    ]
  },
  "西海市": {
    "code": "4221200",
    "coordinates": [
      129.681992854822,
      32.9926019879824
    ]
  },
  "雲仙市": {
    "code": "4221300",
    "coordinates": [
      130.228365094781,
      32.7840331584632
    ]
  },
  "南島原市": {
    "code": "4221400",
    "coordinates": [
      130.256941504539,
      32.6756312687984
    ]
  },
  "長与町": {
    "code": "4230700",
    "coordinates": [
      129.884170436784,
      32.8306012608293
    ]
  },
  "時津町": {
    "code": "4230800",
    "coordinates": [
      129.829890472173,
      32.8387290055467
    ]
  },
  "東彼杵町": {
    "code": "4232100",
    "coordinates": [
      129.957419888465,
      33.034171831195
    ]
  },
  "川棚町": {
    "code": "4232200",
    "coordinates": [
      129.87003302821,
      33.0816530832746
    ]
  },
  "波佐見町": {
    "code": "4232300",
    "coordinates": [
      129.8990953051,
      33.1324084297408
    ]
  },
  "小値賀町": {
    "code": "4238300",
    "coordinates": [
      129.07091351576,
      33.1961835627886
    ]
  },
  "佐々町": {
    "code": "4239100",
    "coordinates": [
      129.656364091652,
      33.2445939575509
    ]
  },
  "新上五島町": {
    "code": "4241100",
    "coordinates": [
      129.068546292004,
      32.952767641085
    ]
  },
  "熊本中央区": {
    "code": "4310100",
    "coordinates": [
      130.722448501238,
      32.7993272217758
    ]
  },
  "熊本東区": {
    "code": "4310200",
    "coordinates": [
      130.775704315214,
      32.8042953431582
    ]
  },
  "熊本西区": {
    "code": "4310300",
    "coordinates": [
      130.641487715727,
      32.8103249921841
    ]
  },
  "熊本南区": {
    "code": "4310400",
    "coordinates": [
      130.691207852726,
      32.7257723916028
    ]
  },
  "熊本北区": {
    "code": "4310500",
    "coordinates": [
      130.698771754756,
      32.8905723009179
    ]
  },
  "八代市": {
    "code": "4320200",
    "coordinates": [
      130.749704558151,
      32.4906280497578
    ]
  },
  "人吉市": {
    "code": "4320300",
    "coordinates": [
      130.749616968528,
      32.163679153025
    ]
  },
  "荒尾市": {
    "code": "4320400",
    "coordinates": [
      130.475615212855,
      32.9761892738943
    ]
  },
  "水俣市": {
    "code": "4320500",
    "coordinates": [
      130.462100262807,
      32.1705539730129
    ]
  },
  "玉名市": {
    "code": "4320600",
    "coordinates": [
      130.560159681805,
      32.9154982942068
    ]
  },
  "山鹿市": {
    "code": "4320800",
    "coordinates": [
      130.723084017876,
      33.0558766836109
    ]
  },
  "菊池市": {
    "code": "4321000",
    "coordinates": [
      130.855223956628,
      32.9910749469179
    ]
  },
  "宇土市": {
    "code": "4321100",
    "coordinates": [
      130.61306700784,
      32.6809277816702
    ]
  },
  "上天草市": {
    "code": "4321200",
    "coordinates": [
      130.408160772892,
      32.4955355711149
    ]
  },
  "宇城市": {
    "code": "4321300",
    "coordinates": [
      130.654840906593,
      32.626682476528
    ]
  },
  "阿蘇市": {
    "code": "4321400",
    "coordinates": [
      131.094569871292,
      32.9560825797342
    ]
  },
  "天草市": {
    "code": "4321500",
    "coordinates": [
      130.150113153714,
      32.3851351780467
    ]
  },
  "合志市": {
    "code": "4321600",
    "coordinates": [
      130.768664903555,
      32.893048991553
    ]
  },
  "熊本美里町": {
    "code": "4334800",
    "coordinates": [
      130.871528812774,
      32.613582805572
    ]
  },
  "玉東町": {
    "code": "4336400",
    "coordinates": [
      130.62909416603,
      32.9017353232889
    ]
  },
  "南関町": {
    "code": "4336700",
    "coordinates": [
      130.550105621487,
      33.035151953178
    ]
  },
  "長洲町": {
    "code": "4336800",
    "coordinates": [
      130.468236065621,
      32.9285953959519
    ]
  },
  "和水町": {
    "code": "4336900",
    "coordinates": [
      130.614121031124,
      33.0411789005546
    ]
  },
  "大津町": {
    "code": "4340300",
    "coordinates": [
      130.905141323025,
      32.8957794895656
    ]
  },
  "菊陽町": {
    "code": "4340400",
    "coordinates": [
      130.823702165115,
      32.8592177914048
    ]
  },
  "南小国町": {
    "code": "4342300",
    "coordinates": [
      131.090488902885,
      33.0680180065961
    ]
  },
  "熊本小国町": {
    "code": "4342400",
    "coordinates": [
      131.07115749222,
      33.1425747571921
    ]
  },
  "産山村": {
    "code": "4342500",
    "coordinates": [
      131.205249654484,
      33.0107108538673
    ]
  },
  "熊本高森町": {
    "code": "4342800",
    "coordinates": [
      131.20297235602,
      32.8404872887739
    ]
  },
  "西原村": {
    "code": "4343200",
    "coordinates": [
      130.931418054813,
      32.8222975734422
    ]
  },
  "南阿蘇村": {
    "code": "4343300",
    "coordinates": [
      131.037306914777,
      32.8440955463522
    ]
  },
  "御船町": {
    "code": "4344100",
    "coordinates": [
      130.857525263606,
      32.7306342920553
    ]
  },
  "嘉島町": {
    "code": "4344200",
    "coordinates": [
      130.755571273293,
      32.7460054382181
    ]
  },
  "益城町": {
    "code": "4344300",
    "coordinates": [
      130.833982710585,
      32.7874927061168
    ]
  },
  "甲佐町": {
    "code": "4344400",
    "coordinates": [
      130.817039376945,
      32.6719762900557
    ]
  },
  "山都町": {
    "code": "4344700",
    "coordinates": [
      131.049207828943,
      32.6991104050776
    ]
  },
  "氷川町": {
    "code": "4346800",
    "coordinates": [
      130.67594392073,
      32.5750685527272
    ]
  },
  "芦北町": {
    "code": "4348200",
    "coordinates": [
      130.541308892763,
      32.2915846487828
    ]
  },
  "津奈木町": {
    "code": "4348400",
    "coordinates": [
      130.460407731133,
      32.2414512643006
    ]
  },
  "錦町": {
    "code": "4350100",
    "coordinates": [
      130.849140726287,
      32.1824305734141
    ]
  },
  "多良木町": {
    "code": "4350500",
    "coordinates": [
      130.992480717773,
      32.2434604608345
    ]
  },
  "湯前町": {
    "code": "4350600",
    "coordinates": [
      131.011553565033,
      32.2715185697971
    ]
  },
  "水上村": {
    "code": "4350700",
    "coordinates": [
      131.017027540151,
      32.365205911653
    ]
  },
  "相良村": {
    "code": "4351000",
    "coordinates": [
      130.829472391177,
      32.3027927165758
    ]
  },
  "五木村": {
    "code": "4351100",
    "coordinates": [
      130.840066619608,
      32.4201512120775
    ]
  },
  "山江村": {
    "code": "4351200",
    "coordinates": [
      130.756755766068,
      32.3148848663209
    ]
  },
  "球磨村": {
    "code": "4351300",
    "coordinates": [
      130.650944158819,
      32.2608836309722
    ]
  },
  "あさぎり町": {
    "code": "4351400",
    "coordinates": [
      130.918961750727,
      32.2087569438734
    ]
  },
  "苓北町": {
    "code": "4353100",
    "coordinates": [
      130.07180584345,
      32.4858603401291
    ]
  },
  "大分市": {
    "code": "4420100",
    "coordinates": [
      131.640841316872,
      33.1798209562505
    ]
  },
  "別府市": {
    "code": "4420200",
    "coordinates": [
      131.452790948923,
      33.2857334608449
    ]
  },
  "中津市": {
    "code": "4420300",
    "coordinates": [
      131.126542757026,
      33.4647192044366
    ]
  },
  "日田市": {
    "code": "4420400",
    "coordinates": [
      130.950618763584,
      33.2445244610731
    ]
  },
  "佐伯市": {
    "code": "4420500",
    "coordinates": [
      131.776044606108,
      32.8859910904099
    ]
  },
  "臼杵市": {
    "code": "4420600",
    "coordinates": [
      131.744568463416,
      33.0722406478691
    ]
  },
  "津久見市": {
    "code": "4420700",
    "coordinates": [
      131.867023262411,
      33.0702885776374
    ]
  },
  "竹田市": {
    "code": "4420800",
    "coordinates": [
      131.327231756873,
      32.9977481992446
    ]
  },
  "豊後高田市": {
    "code": "4420900",
    "coordinates": [
      131.506921614408,
      33.575714708195
    ]
  },
  "杵築市": {
    "code": "4421000",
    "coordinates": [
      131.536726835998,
      33.4472817817601
    ]
  },
  "宇佐市": {
    "code": "4421100",
    "coordinates": [
      131.336616714209,
      33.4499851818491
    ]
  },
  "豊後大野市": {
    "code": "4421200",
    "coordinates": [
      131.512089500829,
      32.9724959793255
    ]
  },
  "由布市": {
    "code": "4421300",
    "coordinates": [
      131.384155667926,
      33.208554634338
    ]
  },
  "国東市": {
    "code": "4421400",
    "coordinates": [
      131.650624261255,
      33.5641921465456
    ]
  },
  "姫島村": {
    "code": "4432200",
    "coordinates": [
      131.663694094742,
      33.7280190969977
    ]
  },
  "日出町": {
    "code": "4434100",
    "coordinates": [
      131.52746228204,
      33.3763268647685
    ]
  },
  "九重町": {
    "code": "4446100",
    "coordinates": [
      131.212621890485,
      33.1924434060622
    ]
  },
  "玖珠町": {
    "code": "4446200",
    "coordinates": [
      131.161434130271,
      33.3044212934431
    ]
  },
  "宮崎市": {
    "code": "4520100",
    "coordinates": [
      131.358596161437,
      31.892196501973
    ]
  },
  "都城市": {
    "code": "4520200",
    "coordinates": [
      131.079034372571,
      31.8092085563359
    ]
  },
  "延岡市": {
    "code": "4520300",
    "coordinates": [
      131.64313904961,
      32.6723910913412
    ]
  },
  "日南市": {
    "code": "4520400",
    "coordinates": [
      131.331089661432,
      31.6499989161588
    ]
  },
  "小林市": {
    "code": "4520500",
    "coordinates": [
      131.040914064446,
      32.0334493027907
    ]
  },
  "日向市": {
    "code": "4520600",
    "coordinates": [
      131.522825513683,
      32.3771423498769
    ]
  },
  "串間市": {
    "code": "4520700",
    "coordinates": [
      131.264831278407,
      31.4857025569634
    ]
  },
  "西都市": {
    "code": "4520800",
    "coordinates": [
      131.315416713794,
      32.172884214893
    ]
  },
  "えびの市": {
    "code": "4520900",
    "coordinates": [
      130.841254930372,
      32.0473828885971
    ]
  },
  "三股町": {
    "code": "4534100",
    "coordinates": [
      131.177024437418,
      31.7310576600935
    ]
  },
  "高原町": {
    "code": "4536100",
    "coordinates": [
      130.997683274001,
      31.9227541009366
    ]
  },
  "国富町": {
    "code": "4538200",
    "coordinates": [
      131.287659532467,
      32.0389859388802
    ]
  },
  "綾町": {
    "code": "4538300",
    "coordinates": [
      131.208542240474,
      32.0316606898163
    ]
  },
  "高鍋町": {
    "code": "4540100",
    "coordinates": [
      131.502552905625,
      32.1314509656205
    ]
  },
  "新富町": {
    "code": "4540200",
    "coordinates": [
      131.466677506528,
      32.0817677857067
    ]
  },
  "西米良村": {
    "code": "4540300",
    "coordinates": [
      131.159150870428,
      32.226137996969
    ]
  },
  "木城町": {
    "code": "4540400",
    "coordinates": [
      131.397908546402,
      32.244557142802
    ]
  },
  "川南町": {
    "code": "4540500",
    "coordinates": [
      131.503775334098,
      32.2093400814692
    ]
  },
  "宮崎都農町": {
    "code": "4540600",
    "coordinates": [
      131.5075500719,
      32.2784687062056
    ]
  },
  "門川町": {
    "code": "4542100",
    "coordinates": [
      131.59210318877,
      32.4914207352498
    ]
  },
  "諸塚村": {
    "code": "4542900",
    "coordinates": [
      131.306123463167,
      32.5582492279315
    ]
  },
  "椎葉村": {
    "code": "4543000",
    "coordinates": [
      131.142971937868,
      32.4616721096309
    ]
  },
  "宮崎美郷町": {
    "code": "4543100",
    "coordinates": [
      131.371155385803,
      32.4400425758333
    ]
  },
  "高千穂町": {
    "code": "4544100",
    "coordinates": [
      131.314573252756,
      32.7468404985048
    ]
  },
  "日之影町": {
    "code": "4544200",
    "coordinates": [
      131.413602429046,
      32.6916471197157
    ]
  },
  "五ヶ瀬町": {
    "code": "4544300",
    "coordinates": [
      131.189852510931,
      32.6437303880566
    ]
  },
  "鹿児島市": {
    "code": "4620100",
    "coordinates": [
      130.528312324332,
      31.5791797017448
    ]
  },
  "鹿屋市": {
    "code": "4620300",
    "coordinates": [
      130.867339814021,
      31.4266441433885
    ]
  },
  "枕崎市": {
    "code": "4620400",
    "coordinates": [
      130.310909257273,
      31.2902381537145
    ]
  },
  "阿久根市": {
    "code": "4620600",
    "coordinates": [
      130.233086513005,
      32.0163976251882
    ]
  },
  "鹿児島出水市": {
    "code": "4620800",
    "coordinates": [
      130.363944145206,
      32.0634139916019
    ]
  },
  "指宿市": {
    "code": "4621000",
    "coordinates": [
      130.583105041191,
      31.2302595163021
    ]
  },
  "西之表市": {
    "code": "4621300",
    "coordinates": [
      131.015781008997,
      30.7050113400944
    ]
  },
  "垂水市": {
    "code": "4621400",
    "coordinates": [
      130.763077423501,
      31.5118415135685
    ]
  },
  "薩摩川内市": {
    "code": "4621501",
    "coordinates": [
      130.35828141591,
      31.8381764996247
    ]
  },
  "薩摩川内市甑島": {
    "code": "4621502",
    "coordinates": [
      129.786634664733,
      31.755201316317
    ]
  },
  "日置市": {
    "code": "4621600",
    "coordinates": [
      130.382074370312,
      31.5992944025402
    ]
  },
  "曽於市": {
    "code": "4621700",
    "coordinates": [
      130.974694034478,
      31.6561542580702
    ]
  },
  "霧島市": {
    "code": "4621800",
    "coordinates": [
      130.781629523029,
      31.8055847852227
    ]
  },
  "いちき串木野市": {
    "code": "4621900",
    "coordinates": [
      130.288915653374,
      31.7328189967863
    ]
  },
  "南さつま市": {
    "code": "4622000",
    "coordinates": [
      130.269605540361,
      31.3879308552289
    ]
  },
  "志布志市": {
    "code": "4622100",
    "coordinates": [
      131.091642237219,
      31.531423009402
    ]
  },
  "奄美市": {
    "code": "4622200",
    "coordinates": [
      129.502202467111,
      28.3454235014526
    ]
  },
  "南九州市": {
    "code": "4622300",
    "coordinates": [
      130.436756720105,
      31.3403792350968
    ]
  },
  "伊佐市": {
    "code": "4622400",
    "coordinates": [
      130.601027783985,
      32.058353580369
    ]
  },
  "姶良市": {
    "code": "4622500",
    "coordinates": [
      130.596071743766,
      31.7810977198953
    ]
  },
  "三島村": {
    "code": "4630300",
    "coordinates": [
      130.135125529524,
      30.8129439053345
    ]
  },
  "鹿児島十島村": {
    "code": "4630400",
    "coordinates": [
      129.712994312664,
      29.6888031620734
    ]
  },
  "さつま町": {
    "code": "4639200",
    "coordinates": [
      130.475787616544,
      31.9375925231261
    ]
  },
  "長島町": {
    "code": "4640400",
    "coordinates": [
      130.166451306017,
      32.1862008697405
    ]
  },
  "湧水町": {
    "code": "4645200",
    "coordinates": [
      130.728614607526,
      31.9688457922099
    ]
  },
  "大崎町": {
    "code": "4646800",
    "coordinates": [
      130.97477882932,
      31.464262752247
    ]
  },
  "東串良町": {
    "code": "4648200",
    "coordinates": [
      130.988644625194,
      31.38294258501
    ]
  },
  "錦江町": {
    "code": "4649000",
    "coordinates": [
      130.85824720515,
      31.216876853
    ]
  },
  "南大隅町": {
    "code": "4649100",
    "coordinates": [
      130.773747974177,
      31.1222052003321
    ]
  },
  "肝付町": {
    "code": "4649200",
    "coordinates": [
      130.991371209803,
      31.2650416421098
    ]
  },
  "中種子町": {
    "code": "4650100",
    "coordinates": [
      130.959593637642,
      30.5376999753151
    ]
  },
  "南種子町": {
    "code": "4650200",
    "coordinates": [
      130.909144969,
      30.4124577381636
    ]
  },
  "屋久島町": {
    "code": "4650500",
    "coordinates": [
      130.501382304569,
      30.346878132117
    ]
  },
  "大和村": {
    "code": "4652300",
    "coordinates": [
      129.358602077316,
      28.3300421759705
    ]
  },
  "宇検村": {
    "code": "4652400",
    "coordinates": [
      129.27370858823,
      28.2718993583729
    ]
  },
  "瀬戸内町": {
    "code": "4652500",
    "coordinates": [
      129.28615934941,
      28.1572207818513
    ]
  },
  "龍郷町": {
    "code": "4652700",
    "coordinates": [
      129.580368287956,
      28.4100971354883
    ]
  },
  "喜界町": {
    "code": "4652900",
    "coordinates": [
      129.972414445959,
      28.3229331511985
    ]
  },
  "徳之島町": {
    "code": "4653000",
    "coordinates": [
      128.973535072225,
      27.7946402758684
    ]
  },
  "天城町": {
    "code": "4653100",
    "coordinates": [
      128.922637475573,
      27.8021390664616
    ]
  },
  "伊仙町": {
    "code": "4653200",
    "coordinates": [
      128.941744049979,
      27.7038798558697
    ]
  },
  "和泊町": {
    "code": "4653300",
    "coordinates": [
      128.639171136589,
      27.39989796731
    ]
  },
  "知名町": {
    "code": "4653400",
    "coordinates": [
      128.565751687281,
      27.365080016373
    ]
  },
  "与論町": {
    "code": "4653500",
    "coordinates": [
      128.430910040508,
      27.0428244417388
    ]
  },
  "那覇市": {
    "code": "4720100",
    "coordinates": [
      127.686312692114,
      26.2109292852297
    ]
  },
  "宜野湾市": {
    "code": "4720500",
    "coordinates": [
      127.755921265419,
      26.2745334440184
    ]
  },
  "石垣市": {
    "code": "4720700",
    "coordinates": [
      124.188763379481,
      24.4656012809041
    ]
  },
  "浦添市": {
    "code": "4720800",
    "coordinates": [
      127.715031792763,
      26.2510750146327
    ]
  },
  "名護市": {
    "code": "4720900",
    "coordinates": [
      128.024647170965,
      26.5850977887792
    ]
  },
  "糸満市": {
    "code": "4721000",
    "coordinates": [
      127.686879386016,
      26.1180157559732
    ]
  },
  "沖縄市": {
    "code": "4721100",
    "coordinates": [
      127.807532097989,
      26.3531341659909
    ]
  },
  "豊見城市": {
    "code": "4721200",
    "coordinates": [
      127.679370004964,
      26.1735583280304
    ]
  },
  "うるま市": {
    "code": "4721300",
    "coordinates": [
      127.873698142362,
      26.3678301148425
    ]
  },
  "宮古島市": {
    "code": "4721400",
    "coordinates": [
      125.301280099258,
      24.7857659267486
    ]
  },
  "南城市": {
    "code": "4721500",
    "coordinates": [
      127.778747768414,
      26.163722785849
    ]
  },
  "国頭村": {
    "code": "4730100",
    "coordinates": [
      128.254735589751,
      26.7616723769616
    ]
  },
  "大宜味村": {
    "code": "4730200",
    "coordinates": [
      128.138667148979,
      26.6715302003
    ]
  },
  "東村": {
    "code": "4730300",
    "coordinates": [
      128.189695456663,
      26.6485510461993
    ]
  },
  "今帰仁村": {
    "code": "4730600",
    "coordinates": [
      127.967028801818,
      26.68006323744
    ]
  },
  "本部町": {
    "code": "4730800",
    "coordinates": [
      127.908990376044,
      26.6585461991776
    ]
  },
  "恩納村": {
    "code": "4731100",
    "coordinates": [
      127.85274133029,
      26.4759488549278
    ]
  },
  "宜野座村": {
    "code": "4731300",
    "coordinates": [
      127.966484217617,
      26.4948075615559
    ]
  },
  "金武町": {
    "code": "4731400",
    "coordinates": [
      127.901852879782,
      26.4651474727923
    ]
  },
  "伊江村": {
    "code": "4731500",
    "coordinates": [
      127.789474510655,
      26.7217611221873
    ]
  },
  "読谷村": {
    "code": "4732400",
    "coordinates": [
      127.746328969633,
      26.4000869817654
    ]
  },
  "嘉手納町": {
    "code": "4732500",
    "coordinates": [
      127.767372024199,
      26.364409230556
    ]
  },
  "北谷町": {
    "code": "4732600",
    "coordinates": [
      127.76444818056,
      26.3213527134945
    ]
  },
  "北中城村": {
    "code": "4732700",
    "coordinates": [
      127.797186046299,
      26.3033596290774
    ]
  },
  "中城村": {
    "code": "4732800",
    "coordinates": [
      127.787702695866,
      26.2634929209763
    ]
  },
  "西原町": {
    "code": "4732900",
    "coordinates": [
      127.759238693771,
      26.2295124032377
    ]
  },
  "与那原町": {
    "code": "4734800",
    "coordinates": [
      127.758125066887,
      26.2021695160937
    ]
  },
  "南風原町": {
    "code": "4735000",
    "coordinates": [
      127.728650048907,
      26.1941967157988
    ]
  },
  "渡嘉敷村": {
    "code": "4735300",
    "coordinates": [
      127.373497425605,
      26.1963636033865
    ]
  },
  "座間味村": {
    "code": "4735400",
    "coordinates": [
      127.287119449053,
      26.2094574742675
    ]
  },
  "粟国村": {
    "code": "4735500",
    "coordinates": [
      127.229322633379,
      26.587660861689
    ]
  },
  "渡名喜村": {
    "code": "4735600",
    "coordinates": [
      127.143958044351,
      26.3660344367409
    ]
  },
  "南大東村": {
    "code": "4735700",
    "coordinates": [
      131.242744914302,
      25.8431960031568
    ]
  },
  "北大東村": {
    "code": "4735800",
    "coordinates": [
      131.296678649555,
      25.8153725167012
    ]
  },
  "伊平屋村": {
    "code": "4735900",
    "coordinates": [
      127.970831498156,
      27.0473652726897
    ]
  },
  "伊是名村": {
    "code": "4736000",
    "coordinates": [
      127.936533244716,
      26.9330424525469
    ]
  },
  "久米島町": {
    "code": "4736100",
    "coordinates": [
      126.83288263237,
      26.4106427459504
    ]
  },
  "八重瀬町": {
    "code": "4736200",
    "coordinates": [
      127.726532701338,
      26.1384611280885
    ]
  },
  "多良間村": {
    "code": "4737500",
    "coordinates": [
      124.69874512633,
      24.6666108256917
    ]
  },
  "竹富町": {
    "code": "4738100",
    "coordinates": [
      123.82817843395,
      24.3219449294693
    ]
  },
  "与那国町": {
    "code": "4738200",
    "coordinates": [
      122.988996964954,
      24.4553666704242
    ]
  }
}