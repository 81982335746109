import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import { Modal } from "bootstrap"
import i18next from "../utils/i18next"
import { defaultTzTime, deviceTzTime } from "../utils/dayjs"
import morphdom from "morphdom"

function mergeHTMLCollection(collection1, collection2) {
  const array1 = Array.from(collection1.children);
  const array2 = Array.from(collection2.children);
  const change = array1.length === array2
  const uniqueElements = {};
  array1.forEach(element => {
    const id = element.id;
    if (!uniqueElements[id]) {
      uniqueElements[id] = element;
    }
  });
  array2.forEach(element => {
    const id = element.id;
    if (!uniqueElements[id]) {
      uniqueElements[id] = element;
    }
  });
  const uniqueMergedArray = Object.values(uniqueElements);
  return { result: uniqueMergedArray, isChange: change }
}

function replaceEventList(event) {
  if (window.lastEventList && event.detail.newFrame.querySelector("#events")) {
    event.detail.render = (currentElement, newElement) => {
      const newEventList = newElement.querySelector("#event-list")
      const lastEventList = window.lastEventList
      const mergeHTML = mergeHTMLCollection(newEventList, lastEventList)
      const mergeEventList = mergeHTML.result
      const newEventListElement = document.createElement("div")
      newEventListElement.id = "event-list"
      newEventListElement.dataset.reportsTarget = "list"
      mergeEventList.forEach(item => {
        newEventListElement.appendChild(item.cloneNode(true));
      });
      newElement.querySelector("#event-list").outerHTML = newEventListElement.outerHTML
      newElement.querySelector("#pager").outerHTML = window.lastPager.outerHTML
      morphdom(currentElement, newElement, { childrenOnly: true })
      document.getElementById("events").scrollTop = window.lastEventScroll
      if (mergeHTML.isChange) {
        document.getElementsByClassName("active")[0].click()
      }
      window.lastEventList = null
      window.lastEventScroll = null
    }
  }
}
export default class extends Controller {
  static targets = ["list", "latest", "listButton", "useDeviceTimezone"]
  static values = {
    active: Boolean,
    latest: Boolean
  }


  connect() {
    window.i18next = i18next
    if (localStorage.getItem('latestSwitch') === null) {
      localStorage.setItem('latestSwitch', 'true')
    }
    if (localStorage.getItem('latestSwitch') === 'true') {
      this.latestValue = true
      this.latestTarget.checked = true
    } else {
      this.latestValue = false
      this.latestTarget.checked = false
    }

    if (localStorage.getItem('deviceTimezone') === null) {
      localStorage.setItem('deviceTimezone', 'false')
    }
    if (localStorage.getItem('deviceTimezone') === 'true') {
      this.useDeviceTimezoneValue = true
      this.useDeviceTimezoneTarget.checked = true
    } else {
      this.useDeviceTimezoneValue = false
      this.useDeviceTimezoneTarget.checked = false
    }
  }

  toggle(event) {
    event.preventDefault()
    if (this.activeValue === true) {
      this.listTarget.style.display = "none"
      this.listButtonTarget.innerText = i18next.t("sidebar.show_list_button")
    } else {
      this.listTarget.style.display = "block"
      this.listButtonTarget.innerText = i18next.t("sidebar.hide_list_button")
    }
    this.activeValue = !this.activeValue
  }

  latestSwitch(event) {
    const checked = event.target.checked
    localStorage.setItem('latestSwitch', checked)
  }

  useDeviceTimezoneSwitch(event) {
    const checked = event.target.checked
    localStorage.setItem('deviceTimezone', checked)
    var timeElements = document.querySelectorAll("time")
    timeElements.forEach((element) => {
      const datetime = element.getAttribute("datetime")
      const format = element.getAttribute("data-format")
      if (checked) {
        element.innerHTML = deviceTzTime(datetime, format)
      } else {
        element.innerHTML = defaultTzTime(datetime, format)
      }
    })
  }

  saveReprotList(event) {
    window.lastEventList = document.getElementById("event-list")
    window.lastEventScroll = document.getElementById("events").scrollTop
    window.lastPager = document.getElementById("pager")
  }

  back() {
    addEventListener("turbo:before-frame-render", (event) => replaceEventList(event))
  }
}
