import "ol/ol.css";
import GeoJSON from 'ol/format/GeoJSON';
import Map from 'ol/Map';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import View from 'ol/View';
import { Fill, Stroke, Style, Icon, Text } from 'ol/style';
import { fromLonLat } from 'ol/proj';
window.fromLonLat = fromLonLat;
window.Style = Style;
window.Fill = Fill;
window.Stroke = Stroke;
window.Icon = Icon;
window.VectorLayer = VectorLayer;
window.VectorSource = VectorSource;
window.GeoJSON = GeoJSON;
window.TextStyle = Text;

window.mapThemeColor = "#F5F5F5"

switch (localStorage.getItem('theme')) {
    case "light":
        window.mapThemeColor = "#F5F5F5"
        break;
    case "dark":
        window.mapThemeColor = "#3d3d3d"
        break;
    default:
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
            window.mapThemeColor = "#3d3d3d"
        } else {
            window.mapThemeColor = "#F5F5F5"
        }
        break;
}

var defaultStyle = new Style({
    fill: new Fill({
        color: window.mapThemeColor,
    }),
    stroke: new Stroke({
        color: "#aaa",
        width: 1,
    }),
});


const JapanMapVectorSource = new VectorSource({
    url: "//assets.idols.dev/data/gadm36_JPN_2.json",
    format: new GeoJSON(),
});

const JapanEEWMapVectorSource = new VectorSource({
    url: "//assets.idols.dev/data/eew.json",
    format: new GeoJSON(),
});

const JMAEQVectorSource = new VectorSource({
    url: "//assets.idols.dev/data/jma_eq_area.json",
    format: new GeoJSON(),
});

const JapanPrefVectorSource = new VectorSource({
    url: "//assets.idols.dev/data/prefectures.json",
    format: new GeoJSON(),
})

const WorldVectorSource = new VectorSource({
    url: "//assets.idols.dev/data/world.json",
    format: new GeoJSON(),
})

// -----

window.JapanDetailedMapLayer = new VectorLayer({
    source: JapanMapVectorSource,
    style: defaultStyle,
});

window.JapanDetailedMapLayer.set("name", "japan_detailed_map");

window.JapanSimpleMapLayer = new VectorLayer({
    source: JMAEQVectorSource,
    style: defaultStyle,
});

window.JapanSimpleMapLayer.set("name", "japan_simple_map");

window.JapanEEWMapLayer = new VectorLayer({
    source: JapanEEWMapVectorSource,
    style: defaultStyle,
});

window.JapanEEWMapLayer.set("name", "japan_eew_map");

window.JapanPrefMapLayer = new VectorLayer({
    source: JapanPrefVectorSource,
    style: defaultStyle,
});

window.JapanPrefMapLayer.set("name", "japan_pref_map");

window.WorldMapLayer = new VectorLayer({
    source: WorldVectorSource,
    style: defaultStyle,
});

window.WorldMapLayer.set("name", "world_map");

const map = new Map({
    target: "map",
    layers: [
        new VectorLayer({
            source: WorldVectorSource,
            style: defaultStyle,
        }),
    ],
    view: new View({
        center: fromLonLat([140.412029876442, 37.3914691547508]), // initial value
        zoom: 8,
    }),
    controls: [],
});

window.map = map;

map.addLayer(WorldMapLayer)
map.addLayer(JapanEEWMapLayer)


export default map;

