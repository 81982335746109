import { Notyf } from "notyf"

const notyf = new Notyf(
  {
    duration: 5000,
    position: { x: 'center', y: 'top' },
    types: [
      {
        type: 'info',
        background: 'blue',
        icon: false,
      }
    ]
  }
)



export default notyf