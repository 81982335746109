export const areaData = {
  "石狩地方北部": {
    "code": "100",
    "coordinates": [
      141.49525560707,
      43.4151931842515
    ]
  },
  "石狩地方中部": {
    "code": "101",
    "coordinates": [
      141.291800149006,
      43.010872239411
    ]
  },
  "石狩地方南部": {
    "code": "102",
    "coordinates": [
      141.496898327669,
      42.8401853638524
    ]
  },
  "渡島地方北部": {
    "code": "105",
    "coordinates": [
      140.227924212031,
      42.2715261571357
    ]
  },
  "渡島地方東部": {
    "code": "106",
    "coordinates": [
      140.730104639953,
      41.9171303977866
    ]
  },
  "渡島地方西部": {
    "code": "107",
    "coordinates": [
      140.250720331631,
      41.5890677088811
    ]
  },
  "檜山地方": {
    "code": "110",
    "coordinates": [
      140.117224870458,
      42.1200892176946
    ]
  },
  "後志地方北部": {
    "code": "115",
    "coordinates": [
      140.766313140274,
      43.1570832606841
    ]
  },
  "後志地方東部": {
    "code": "116",
    "coordinates": [
      140.848074079286,
      42.8279967803352
    ]
  },
  "後志地方西部": {
    "code": "117",
    "coordinates": [
      140.381809567057,
      42.8212795958583
    ]
  },
  "北海道奥尻島": {
    "code": "119",
    "coordinates": [
      139.473175204362,
      42.1553384492168
    ]
  },
  "空知地方北部": {
    "code": "120",
    "coordinates": [
      142.003884974433,
      43.8088241231416
    ]
  },
  "空知地方中部": {
    "code": "121",
    "coordinates": [
      141.982748253998,
      43.510911886567
    ]
  },
  "空知地方南部": {
    "code": "122",
    "coordinates": [
      141.912806597305,
      43.1340267466936
    ]
  },
  "上川地方北部": {
    "code": "125",
    "coordinates": [
      142.392451316326,
      44.3385114570841
    ]
  },
  "上川地方中部": {
    "code": "126",
    "coordinates": [
      142.644551805745,
      43.735583365414
    ]
  },
  "上川地方南部": {
    "code": "127",
    "coordinates": [
      142.504343797753,
      43.2002642741056
    ]
  },
  "留萌地方中北部": {
    "code": "130",
    "coordinates": [
      141.878134955653,
      44.4989700095592
    ]
  },
  "留萌地方南部": {
    "code": "131",
    "coordinates": [
      141.733774434506,
      43.9417545962386
    ]
  },
  "宗谷地方北部": {
    "code": "135",
    "coordinates": [
      141.918210986196,
      45.1894445178067
    ]
  },
  "宗谷地方南部": {
    "code": "136",
    "coordinates": [
      142.43855171702,
      44.8665568352299
    ]
  },
  "北海道利尻礼文": {
    "code": "139",
    "coordinates": [
      141.166627699514,
      45.2387861660525
    ]
  },
  "網走地方": {
    "code": "140",
    "coordinates": [
      144.382103894481,
      43.8152965753998
    ]
  },
  "北見地方": {
    "code": "141",
    "coordinates": [
      143.673887997429,
      43.8226200374306
    ]
  },
  "紋別地方": {
    "code": "142",
    "coordinates": [
      143.183999955972,
      44.1637059557543
    ]
  },
  "胆振地方西部": {
    "code": "145",
    "coordinates": [
      140.894124905183,
      42.613359798762
    ]
  },
  "胆振地方中東部": {
    "code": "146",
    "coordinates": [
      141.697396642325,
      42.6805610965227
    ]
  },
  "日高地方西部": {
    "code": "150",
    "coordinates": [
      142.406748749235,
      42.7170038822428
    ]
  },
  "日高地方中部": {
    "code": "151",
    "coordinates": [
      142.58710610633,
      42.4684979347206
    ]
  },
  "日高地方東部": {
    "code": "152",
    "coordinates": [
      142.999868946709,
      42.2081311544082
    ]
  },
  "十勝地方北部": {
    "code": "155",
    "coordinates": [
      143.2988585723,
      43.3665628834425
    ]
  },
  "十勝地方中部": {
    "code": "156",
    "coordinates": [
      143.296318802107,
      42.9092933852102
    ]
  },
  "十勝地方南部": {
    "code": "157",
    "coordinates": [
      143.153859068292,
      42.4845011298408
    ]
  },
  "釧路地方北部": {
    "code": "160",
    "coordinates": [
      144.399883448184,
      43.5472480898255
    ]
  },
  "釧路地方中南部": {
    "code": "161",
    "coordinates": [
      144.390527256347,
      43.180280482733
    ]
  },
  "根室地方北部": {
    "code": "165",
    "coordinates": [
      144.960978939025,
      43.7457962290942
    ]
  },
  "根室地方中部": {
    "code": "166",
    "coordinates": [
      145.017145856032,
      43.3921494419389
    ]
  },
  "根室地方南部": {
    "code": "167",
    "coordinates": [
      145.585475423304,
      43.3154699204573
    ]
  },
  "青森県津軽北部": {
    "code": "200",
    "coordinates": [
      140.602759340444,
      40.9012879819584
    ]
  },
  "青森県津軽南部": {
    "code": "201",
    "coordinates": [
      140.355676664483,
      40.5837111506307
    ]
  },
  "青森県三八上北": {
    "code": "202",
    "coordinates": [
      141.217603896412,
      40.6162098596363
    ]
  },
  "青森県下北": {
    "code": "203",
    "coordinates": [
      141.077901041411,
      41.3095280542848
    ]
  },
  "岩手県沿岸北部": {
    "code": "210",
    "coordinates": [
      141.720190761799,
      39.8488708107972
    ]
  },
  "岩手県沿岸南部": {
    "code": "211",
    "coordinates": [
      141.702753491058,
      39.2071220069916
    ]
  },
  "岩手県内陸北部": {
    "code": "212",
    "coordinates": [
      141.183482398172,
      39.931554542287
    ]
  },
  "岩手県内陸南部": {
    "code": "213",
    "coordinates": [
      141.160891785618,
      39.2215346022004
    ]
  },
  "宮城県北部": {
    "code": "220",
    "coordinates": [
      141.022771333641,
      38.7081798510326
    ]
  },
  "宮城県南部": {
    "code": "221",
    "coordinates": [
      140.661089277841,
      38.0409381021126
    ]
  },
  "宮城県中部": {
    "code": "222",
    "coordinates": [
      141.004064365124,
      38.3899877375334
    ]
  },
  "秋田県沿岸北部": {
    "code": "230",
    "coordinates": [
      140.096455999147,
      40.1200513403522
    ]
  },
  "秋田県沿岸南部": {
    "code": "231",
    "coordinates": [
      140.171307132117,
      39.4444677774327
    ]
  },
  "秋田県内陸北部": {
    "code": "232",
    "coordinates": [
      140.578371768009,
      40.169818598738
    ]
  },
  "秋田県内陸南部": {
    "code": "233",
    "coordinates": [
      140.555936110668,
      39.4107479536497
    ]
  },
  "山形県庄内": {
    "code": "240",
    "coordinates": [
      139.885451803039,
      38.7268035929364
    ]
  },
  "山形県最上": {
    "code": "241",
    "coordinates": [
      140.295120305627,
      38.7924891508531
    ]
  },
  "山形県村山": {
    "code": "242",
    "coordinates": [
      140.279056046712,
      38.3859722184277
    ]
  },
  "山形県置賜": {
    "code": "243",
    "coordinates": [
      139.976229298984,
      37.9990598165106
    ]
  },
  "福島県中通り": {
    "code": "250",
    "coordinates": [
      140.412029876442,
      37.3914691547508
    ]
  },
  "福島県浜通り": {
    "code": "251",
    "coordinates": [
      140.838009636233,
      37.3509163241567
    ]
  },
  "福島県会津": {
    "code": "252",
    "coordinates": [
      139.696186888682,
      37.3884896876914
    ]
  },
  "茨城県北部": {
    "code": "300",
    "coordinates": [
      140.4501572932,
      36.5650203041214
    ]
  },
  "茨城県南部": {
    "code": "301",
    "coordinates": [
      140.201946458283,
      36.0932846746818
    ]
  },
  "栃木県北部": {
    "code": "310",
    "coordinates": [
      139.796845705343,
      36.8824768612142
    ]
  },
  "栃木県南部": {
    "code": "311",
    "coordinates": [
      139.834443306022,
      36.512030461272
    ]
  },
  "群馬県北部": {
    "code": "320",
    "coordinates": [
      138.928782969845,
      36.6945826617991
    ]
  },
  "群馬県南部": {
    "code": "321",
    "coordinates": [
      139.031683822158,
      36.3350541464469
    ]
  },
  "埼玉県北部": {
    "code": "330",
    "coordinates": [
      139.362415936756,
      36.120612876943
    ]
  },
  "埼玉県南部": {
    "code": "331",
    "coordinates": [
      139.545329395159,
      35.9073583025148
    ]
  },
  "埼玉県秩父": {
    "code": "332",
    "coordinates": [
      138.960967734764,
      35.9859328237761
    ]
  },
  "千葉県北東部": {
    "code": "340",
    "coordinates": [
      140.471172440167,
      35.651395253314
    ]
  },
  "千葉県北西部": {
    "code": "341",
    "coordinates": [
      140.134108404433,
      35.6937245250665
    ]
  },
  "千葉県南部": {
    "code": "342",
    "coordinates": [
      140.046035650559,
      35.203428130897
    ]
  },
  "東京都２３区": {
    "code": "350",
    "coordinates": [
      139.735181991436,
      35.6910145729301
    ]
  },
  "東京都多摩東部": {
    "code": "351",
    "coordinates": [
      139.404014712941,
      35.676657079624
    ]
  },
  "東京都多摩西部": {
    "code": "352",
    "coordinates": [
      139.133267741272,
      35.7841008782466
    ]
  },
  "神津島": {
    "code": "354",
    "coordinates": [
      139.14756016141,
      34.2141240116488
    ]
  },
  "伊豆大島": {
    "code": "355",
    "coordinates": [
      139.398860680118,
      34.737504801755
    ]
  },
  "新島": {
    "code": "356",
    "coordinates": [
      139.261953719004,
      34.3908705243122
    ]
  },
  "三宅島": {
    "code": "357",
    "coordinates": [
      139.543209588378,
      34.0281970490155
    ]
  },
  "八丈島": {
    "code": "358",
    "coordinates": [
      139.791238765374,
      33.0582036184704
    ]
  },
  "小笠原": {
    "code": "359",
    "coordinates": [
      142.005446132563,
      26.4684789419563
    ]
  },
  "神奈川県東部": {
    "code": "360",
    "coordinates": [
      139.541830262692,
      35.4158798516042
    ]
  },
  "神奈川県西部": {
    "code": "361",
    "coordinates": [
      139.170065099832,
      35.4187572372285
    ]
  },
  "新潟県上越": {
    "code": "370",
    "coordinates": [
      138.153987349162,
      37.028577309026
    ]
  },
  "新潟県中越": {
    "code": "371",
    "coordinates": [
      138.885591560404,
      37.2523218878926
    ]
  },
  "新潟県下越": {
    "code": "372",
    "coordinates": [
      139.396426040876,
      37.9351085765371
    ]
  },
  "新潟県佐渡": {
    "code": "375",
    "coordinates": [
      138.389834987216,
      38.0443952706501
    ]
  },
  "富山県東部": {
    "code": "380",
    "coordinates": [
      137.437080599991,
      36.6540191718457
    ]
  },
  "富山県西部": {
    "code": "381",
    "coordinates": [
      136.942745221795,
      36.6116154042673
    ]
  },
  "石川県能登": {
    "code": "390",
    "coordinates": [
      136.948180718148,
      37.2064867460047
    ]
  },
  "石川県加賀": {
    "code": "391",
    "coordinates": [
      136.609662359906,
      36.3778009761394
    ]
  },
  "福井県嶺北": {
    "code": "400",
    "coordinates": [
      136.361479635618,
      35.9656984036161
    ]
  },
  "福井県嶺南": {
    "code": "401",
    "coordinates": [
      135.839517060097,
      35.5232068049401
    ]
  },
  "山梨県中・西部": {
    "code": "411",
    "coordinates": [
      138.493498877733,
      35.6343864344763
    ]
  },
  "山梨県東部・富士五湖": {
    "code": "412",
    "coordinates": [
      138.883889021463,
      35.5700482506783
    ]
  },
  "長野県北部": {
    "code": "420",
    "coordinates": [
      138.150078155994,
      36.6881921251618
    ]
  },
  "長野県中部": {
    "code": "421",
    "coordinates": [
      138.169651115476,
      36.1891456600816
    ]
  },
  "長野県南部": {
    "code": "422",
    "coordinates": [
      137.82736831621,
      35.654790923255
    ]
  },
  "岐阜県飛騨": {
    "code": "430",
    "coordinates": [
      137.226141814707,
      36.1187019703343
    ]
  },
  "岐阜県美濃東部": {
    "code": "431",
    "coordinates": [
      137.316761509708,
      35.4816794817731
    ]
  },
  "岐阜県美濃中西部": {
    "code": "432",
    "coordinates": [
      136.719663959301,
      35.6069261648036
    ]
  },
  "静岡県伊豆": {
    "code": "440",
    "coordinates": [
      138.938865846631,
      34.8745640375043
    ]
  },
  "静岡県東部": {
    "code": "441",
    "coordinates": [
      138.771488626138,
      35.2402420829625
    ]
  },
  "静岡県中部": {
    "code": "442",
    "coordinates": [
      138.238197712947,
      35.1039139770262
    ]
  },
  "静岡県西部": {
    "code": "443",
    "coordinates": [
      137.853989125771,
      34.8836462400842
    ]
  },
  "愛知県東部": {
    "code": "450",
    "coordinates": [
      137.485860953082,
      34.9312735856154
    ]
  },
  "愛知県西部": {
    "code": "451",
    "coordinates": [
      137.073328534043,
      35.0894580120775
    ]
  },
  "三重県北部": {
    "code": "460",
    "coordinates": [
      136.526626961888,
      35.0047600275823
    ]
  },
  "三重県中部": {
    "code": "461",
    "coordinates": [
      136.313318480575,
      34.6191734898224
    ]
  },
  "三重県南部": {
    "code": "462",
    "coordinates": [
      136.371820296006,
      34.213724255505
    ]
  },
  "滋賀県北部": {
    "code": "500",
    "coordinates": [
      136.165278079797,
      35.3900869659917
    ]
  },
  "滋賀県南部": {
    "code": "501",
    "coordinates": [
      136.104847445449,
      35.0456639623281
    ]
  },
  "京都府北部": {
    "code": "510",
    "coordinates": [
      135.184751464577,
      35.46787882351
    ]
  },
  "京都府南部": {
    "code": "511",
    "coordinates": [
      135.65562115477,
      35.0789732223378
    ]
  },
  "大阪府北部": {
    "code": "520",
    "coordinates": [
      135.548853115561,
      34.783462719498
    ]
  },
  "大阪府南部": {
    "code": "521",
    "coordinates": [
      135.455666991985,
      34.4415819650787
    ]
  },
  "兵庫県北部": {
    "code": "530",
    "coordinates": [
      134.71872293536,
      35.4408410901881
    ]
  },
  "兵庫県南東部": {
    "code": "531",
    "coordinates": [
      135.099406804436,
      34.9317329074819
    ]
  },
  "兵庫県南西部": {
    "code": "532",
    "coordinates": [
      134.553780088888,
      34.9963324958315
    ]
  },
  "兵庫県淡路島": {
    "code": "535",
    "coordinates": [
      134.838411125225,
      34.3621637869522
    ]
  },
  "奈良県": {
    "code": "540",
    "coordinates": [
      135.868735482575,
      34.3192656265881
    ]
  },
  "和歌山県北部": {
    "code": "550",
    "coordinates": [
      135.3608210494,
      34.1028777946241
    ]
  },
  "和歌山県南部": {
    "code": "551",
    "coordinates": [
      135.656947155252,
      33.726110390324
    ]
  },
  "鳥取県東部": {
    "code": "560",
    "coordinates": [
      134.24926000843,
      35.3918635375182
    ]
  },
  "鳥取県中部": {
    "code": "562",
    "coordinates": [
      133.789229650776,
      35.4044079729083
    ]
  },
  "鳥取県西部": {
    "code": "563",
    "coordinates": [
      133.386845026069,
      35.2994577316796
    ]
  },
  "島根県東部": {
    "code": "570",
    "coordinates": [
      132.951146669834,
      35.2992242948002
    ]
  },
  "島根県西部": {
    "code": "571",
    "coordinates": [
      132.192509559817,
      34.7928361651109
    ]
  },
  "島根県隠岐": {
    "code": "575",
    "coordinates": [
      133.208945191763,
      36.2013585892796
    ]
  },
  "岡山県北部": {
    "code": "580",
    "coordinates": [
      133.823930457215,
      35.0926200808239
    ]
  },
  "岡山県南部": {
    "code": "581",
    "coordinates": [
      133.802434935664,
      34.7162121935879
    ]
  },
  "広島県北部": {
    "code": "590",
    "coordinates": [
      132.765038747369,
      34.7991919297154
    ]
  },
  "広島県南東部": {
    "code": "591",
    "coordinates": [
      133.169466482037,
      34.5591852337011
    ]
  },
  "広島県南西部": {
    "code": "592",
    "coordinates": [
      132.518452780871,
      34.3973239449629
    ]
  },
  "徳島県北部": {
    "code": "600",
    "coordinates": [
      134.155924755563,
      34.0127123218254
    ]
  },
  "徳島県南部": {
    "code": "601",
    "coordinates": [
      134.365503868782,
      33.786975641433
    ]
  },
  "香川県東部": {
    "code": "610",
    "coordinates": [
      134.167233844802,
      34.3007139401047
    ]
  },
  "香川県西部": {
    "code": "611",
    "coordinates": [
      133.817468763363,
      34.1906564629378
    ]
  },
  "愛媛県東予": {
    "code": "620",
    "coordinates": [
      133.220443392779,
      33.9500060023073
    ]
  },
  "愛媛県中予": {
    "code": "621",
    "coordinates": [
      132.869283566924,
      33.7395076842639
    ]
  },
  "愛媛県南予": {
    "code": "622",
    "coordinates": [
      132.610302944072,
      33.3472717034619
    ]
  },
  "高知県東部": {
    "code": "630",
    "coordinates": [
      134.065073389848,
      33.5201666208914
    ]
  },
  "高知県中部": {
    "code": "631",
    "coordinates": [
      133.501258893035,
      33.6512155360333
    ]
  },
  "高知県西部": {
    "code": "632",
    "coordinates": [
      132.935115295384,
      33.133110128076
    ]
  },
  "山口県北部": {
    "code": "700",
    "coordinates": [
      131.359776226827,
      34.3572476995743
    ]
  },
  "山口県西部": {
    "code": "702",
    "coordinates": [
      131.082290946589,
      34.1223591161337
    ]
  },
  "山口県東部": {
    "code": "703",
    "coordinates": [
      132.089922630929,
      34.1095644130935
    ]
  },
  "山口県中部": {
    "code": "704",
    "coordinates": [
      131.673551454039,
      34.1800151405053
    ]
  },
  "福岡県福岡": {
    "code": "710",
    "coordinates": [
      130.403935574232,
      33.5952497614343
    ]
  },
  "福岡県北九州": {
    "code": "711",
    "coordinates": [
      130.908182834785,
      33.741810156755
    ]
  },
  "福岡県筑豊": {
    "code": "712",
    "coordinates": [
      130.73968769485,
      33.6260119930602
    ]
  },
  "福岡県筑後": {
    "code": "713",
    "coordinates": [
      130.636383954678,
      33.2675430810854
    ]
  },
  "佐賀県北部": {
    "code": "720",
    "coordinates": [
      129.948976438451,
      33.3679017829721
    ]
  },
  "佐賀県南部": {
    "code": "721",
    "coordinates": [
      130.202283894043,
      33.2461579827258
    ]
  },
  "長崎県北部": {
    "code": "730",
    "coordinates": [
      129.696488221909,
      33.2292689757298
    ]
  },
  "長崎県南西部": {
    "code": "731",
    "coordinates": [
      129.881495729514,
      32.8706410511579
    ]
  },
  "長崎県島原半島": {
    "code": "732",
    "coordinates": [
      130.257068659466,
      32.745846722548
    ]
  },
  "長崎県対馬": {
    "code": "735",
    "coordinates": [
      129.32597991825,
      34.4084515600988
    ]
  },
  "長崎県壱岐": {
    "code": "736",
    "coordinates": [
      129.716691163743,
      33.7855297970237
    ]
  },
  "長崎県五島": {
    "code": "737",
    "coordinates": [
      128.886870310454,
      32.818834297997
    ]
  },
  "熊本県阿蘇": {
    "code": "740",
    "coordinates": [
      131.108361031715,
      32.9619259755635
    ]
  },
  "熊本県熊本": {
    "code": "741",
    "coordinates": [
      130.784193436199,
      32.7626764454036
    ]
  },
  "熊本県球磨": {
    "code": "742",
    "coordinates": [
      130.847453124349,
      32.2823561048014
    ]
  },
  "熊本県天草・芦北": {
    "code": "743",
    "coordinates": [
      130.287882170458,
      32.3538263913408
    ]
  },
  "大分県北部": {
    "code": "750",
    "coordinates": [
      131.359900066464,
      33.4988415016572
    ]
  },
  "大分県中部": {
    "code": "751",
    "coordinates": [
      131.583987201979,
      33.2228447448591
    ]
  },
  "大分県南部": {
    "code": "752",
    "coordinates": [
      131.670221607535,
      32.9206720136167
    ]
  },
  "大分県西部": {
    "code": "753",
    "coordinates": [
      131.133437868809,
      33.1772178549274
    ]
  },
  "宮崎県北部平野部": {
    "code": "760",
    "coordinates": [
      131.521245562521,
      32.4260541648634
    ]
  },
  "宮崎県北部山沿い": {
    "code": "761",
    "coordinates": [
      131.26564109005,
      32.5122652343594
    ]
  },
  "宮崎県南部平野部": {
    "code": "762",
    "coordinates": [
      131.319831819666,
      31.7648845865247
    ]
  },
  "宮崎県南部山沿い": {
    "code": "763",
    "coordinates": [
      131.029034121253,
      31.924251908024
    ]
  },
  "鹿児島県薩摩": {
    "code": "770",
    "coordinates": [
      130.484942719855,
      31.7496641914499
    ]
  },
  "鹿児島県大隅": {
    "code": "771",
    "coordinates": [
      130.924936450357,
      31.4208972338992
    ]
  },
  "鹿児島県十島村": {
    "code": "774",
    "coordinates": [
      129.712994312664,
      29.6888031620734
    ]
  },
  "鹿児島県甑島": {
    "code": "775",
    "coordinates": [
      129.786634664733,
      31.755201316317
    ]
  },
  "鹿児島県種子島": {
    "code": "776",
    "coordinates": [
      130.918494282116,
      30.5980929866606
    ]
  },
  "鹿児島県屋久島": {
    "code": "777",
    "coordinates": [
      130.501382304569,
      30.346878132117
    ]
  },
  "鹿児島県奄美北部": {
    "code": "778",
    "coordinates": [
      129.439179108493,
      28.2881614692973
    ]
  },
  "鹿児島県奄美南部": {
    "code": "779",
    "coordinates": [
      128.829035604575,
      27.6311862243228
    ]
  },
  "沖縄県本島北部": {
    "code": "800",
    "coordinates": [
      128.058172038374,
      26.6554705574845
    ]
  },
  "沖縄県本島中南部": {
    "code": "801",
    "coordinates": [
      127.732601148849,
      26.2609127490052
    ]
  },
  "沖縄県久米島": {
    "code": "802",
    "coordinates": [
      126.83288263237,
      26.4106427459504
    ]
  },
  "沖縄県大東島": {
    "code": "803",
    "coordinates": [
      131.258912306859,
      25.834855523386
    ]
  },
  "沖縄県宮古島": {
    "code": "804",
    "coordinates": [
      125.242722844898,
      24.7741858594093
    ]
  },
  "沖縄県石垣島": {
    "code": "805",
    "coordinates": [
      124.188763379481,
      24.4656012809041
    ]
  },
  "沖縄県与那国島": {
    "code": "806",
    "coordinates": [
      122.988996964954,
      24.4553666704242
    ]
  },
  "沖縄県西表島": {
    "code": "807",
    "coordinates": [
      123.82817843395,
      24.3219449294693
    ]
  }
}