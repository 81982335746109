import * as Turbo from '@hotwired/turbo'

const adapter = Turbo.navigator.delegate.adapter;
const progressBar = adapter.progressBar;
const session = Turbo.session;

let progressBarTimeout = null;
document.addEventListener('turbo:before-fetch-request', (event) => {
  const target = event.target;
  if (!(target instanceof HTMLElement)) {
    return;
  }

  if ('true' === target.getAttribute('data-turbo-progress-bar')) {
    if (!progressBarTimeout) {
      progressBar.setValue(0);
    }

    progressBarTimeout = window.setTimeout(() => progressBar.show(), session.progressBarDelay);
  }
});

document.addEventListener('turbo:frame-render', () => {
  if (progressBarTimeout) {
    window.clearTimeout(progressBarTimeout);
    progressBar.hide();
    progressBarTimeout = null;
  }
});

document.addEventListener('turbo:before-stream-render', () => {
  if (progressBarTimeout) {
    window.clearTimeout(progressBarTimeout);
    progressBar.setValue(1);
    progressBar.hide();
    progressBarTimeout = null;
  }
});
